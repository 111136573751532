import { useState } from "react";
import OTPInput from "react-otp-input";

interface OPTInputFieldProps {
  otp: string;
  setOtp: any;
  error: string;
}

export const OTPInputField = ({ otp, setOtp, error }: OPTInputFieldProps) => {
  return (
    <OTPInput
      value={otp}
      onChange={setOtp}
      numInputs={6}
      renderSeparator={<span className="px-4" />}
      renderInput={(props: any) => <input {...props} type="text" className="otp-input" />}
      inputStyle={{
        // border: "1px solid #0494B4",
        backgroundColor: error ? "#F9EAEE" : "#E5F4F7",
        borderRadius: "2px",
        color: error ? "#B23352" : "#006D88",
        fontSize: "28px",
        lineHeight: "34px",
        fontWeight: "400",
        outlineColor: "#0494B4",
        outlineWidth: "4px",
      }}
      containerStyle={{
        justifyContent: "space-between"
      }}
      inputType="number"
    />
  );
};
