import { CircularProgress } from "@mui/material";
import React from "react";
import { useState } from "react";

const buttonType: any = {
  primary:
    "bg-[#0494B4] text-[#ffffff] hover:bg-[#A2F0EC] hover:text-[#006D88] disabled:bg-[#83CADB]",
  secondry:
    "bg-[#F2F4F5] text-[#163545] hover:bg-[#A2F0EC] hover:text-[#006D88] disabled:opacity-[0.5]",
  text: "text-[#163545] hover:text-[#006D88] disabled:opacity-[0.5]",
  error:
    "bg-[#C41E3A] text-[#ffffff] hover:bg-[#C41E3A] hover:text-[#ffffff] disabled:bg-[#C41E3A]",
};

interface ButtonProps {
  onClick: any;
  label: string;
  icon?: any;
  type?: string;
  iconFirst?: boolean;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
}

function TrestleLoaderButton({
  onClick,
  label,
  type = "primary",
  disabled = false,
  className = "",
  icon = "",
  iconFirst = false,
  loading = false,
}: ButtonProps) {
  return (
    <button
      disabled={disabled}
      className={`${className} text-sm h-[60px] w-full min-w-[200px] text-center rounded-[1px] font-bold flex justify-center items-center
       disabled:pointer-events-none ${buttonType[type]} !disabled:cursor-not-allowed`}
      onClick={onClick}
    >
      <div className={`flex w-fit mx-auto`}>
        <div className={`${loading ? "hidden" : "flex"}`}>
          {iconFirst && icon && <div className="h-5 mr-[15px]">{icon}</div>}
          {label || ""}{" "}
          {!iconFirst && icon && (
            <div className={`${className ? "" : "h-5"} ml-[10px] my-auto`}>
              {icon}
            </div>
          )}
        </div>
        <div className={`${loading ? "flex" : "hidden"}`}>
          <CircularProgress color="inherit" />
        </div>
      </div>
    </button>
  );
}

export default TrestleLoaderButton;
