import mixpanel from "mixpanel-browser";

const MIX_PANEL_TOKEN: string =
  process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || "";

mixpanel.init(MIX_PANEL_TOKEN, {
  //debug: true, // only for Dev
  ignore_dnt: true,
});

export const handleMixPanel = (eventName: any, data: any) => {
  sessionStorage.setItem("lastMixpanelEvent", eventName);
  mixpanel.track(eventName, data);
};

export const handleMixPanelSetUser = (userDetails: any) => {
  const { id = "", firstName = "", lastName = "", email = "" } = userDetails;
  let pyaload: any = {
    id,
    name: `${firstName} ${lastName}`,
    email,
    $email: email,
  };
  if (email && id) {
    mixpanel.identify(id);
    mixpanel.people.set(pyaload);
  }
};
