import { createSlice } from "@reduxjs/toolkit";

export const apiKeySlice = createSlice({
  name: "userDetailsData",
  initialState: {
    currentApiProduct: "",
    currentApiKey: {},
  },
  reducers: {
    setCurrentApiProduct: (state, action) => {
      state.currentApiProduct = action.payload;
    },
    setCurrentApiKey: (state, action) => {
      state.currentApiKey = action.payload;
    },
  },
});

export const { setCurrentApiProduct, setCurrentApiKey } = apiKeySlice.actions;

export default apiKeySlice.reducer;
