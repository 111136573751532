import { SyntheticEvent } from "react";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { SideArrow } from "../../../assets/svgs";

export default function NewAccordions({
  summary,
  details,
  index,
  expanded,
  setExpanded,
  disabled,
  className = "",
  accordionStyles = {},
  accordionSummaryStyles = {},
  accordionDetailsStyles = {},
}: any) {
  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const panel = `panel${index}`;

  return (
    <MuiAccordion
      key={`key-accord`}
      expanded={expanded === panel}
      onChange={disabled !== null ? () => {} : handleChange(panel)}
      disableGutters
      sx={{
        border: "none",
        boxShadow: "none",
        padding: "0px 50px 3px 50px",

        cursor:
          expanded === panel
            ? "default"
            : disabled !== index && disabled !== null
            ? "not-allowed"
            : "pointer",
        ...accordionStyles,
      }}
      className="before:!bg-inherit"
    >
      <MuiAccordionSummary
        aria-controls={`${panel}d-content`}
        id={`${panel}d-header`}
        sx={{
          bgcolor: "#ffffff",
          border: "none",
          lineHeight: "40px",
          borderBottom: expanded !== panel ? "1px solid #55555533" : "",
          ...accordionSummaryStyles,
        }}
        className={`!pb-20px ${
          expanded === panel
            ? "!cursor-default"
            : disabled !== index && disabled !== null
            ? "!cursor-not-allowed"
            : "!cursor-pointer"
        } ${className}`}
      >
        <span
          className={`mr-2 mb-auto mt-[15px] !cursor-pointer ${
            expanded === panel && "rotate-90"
          }`}
          onClick={() => {
            if (expanded) {
              disabled !== index && setExpanded("close");
            }
          }}
        >
          {SideArrow}
        </span>
        {summary}
      </MuiAccordionSummary>

      <MuiAccordionDetails
        sx={{ border: "none", padding: "0px", ...accordionDetailsStyles }}
      >
        {details}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
}
