import { useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Avatar from "@mui/material/Avatar";
import TrestleLogo from "../../../assets/trestle_logo.svg";
import {
  ComponentContainer,
  ModeButton,
  ProfileMenuText,
} from "../CommonComponents";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { setTokens, setUserData, setUserMode } from "../../../app/authReducer";
import LoaderComponent from "../LoaderComponent";
import { DownArrow } from "../../../assets/svgs";
import { getUser } from "../../../http/authentication-service";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { getUserName } from "../../../utils/helpers";
import ComponentDrawer from "../ComponentDrawer/componentDrawer";
import UserDetails from "../../UserDetails/UserDetails";
import { Outlet, useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../constants/RouteConstants";
import {
  handleMixPanel,
  handleMixPanelSetUser,
} from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import { getTeamInvitation } from "../../../http/teams-service";
import InvitationPopup from "../../Teams/InvitationPopUp/invitationPopup";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import { Auth } from "aws-amplify";
import PaymentWalletComponent from "../PaymentWallet";
import { persistor } from "../../../app/store";
import TrestleAppDrawerNew from "../TrestleAppDrawerNew/TrestleAppDrawerNew";
import FooterComponent from "../Footer";

declare global {
  interface Window {
    SetMode: any;
  }
}

function TrestleAppBar(props: any) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [mode, setMode] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [anchorElUser, setAnchorElUser] = useState<boolean>(false);
  const [anchorElPayment, setAnchorElPayment] = useState<Element | null>(null);
  const loading = useAppSelector((state: any) => state.loaderReducer?.open);

  const isAdmin = useAppSelector((state: any) => state.authReducer.isAdmin);
  const [userName, setUserName] = useState<string>("");

  const [invitationPopup, setInvitationPopup] = useState<
    "INVITATION" | "ACCEPT_INVITE" | ""
  >("");

  const [inviteDetails, setInviteDetails] = useState<any>({
    company_name: "",
    team_name: "",
    id: "",
  });

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  const currentUserMode: string = useAppSelector(
    (state: any) => state.authReducer.userMode
  );

  window.SetMode = setMode;

  const UrlParamPaymentSuccess = new URLSearchParams(
    window.location.search
  ).get("paymentSuccess");

  useEffect(() => {
    if (currentUserMode === "ADMIN") {
      setMode("ADMIN");
      setModeSession("ADMIN");
    } else {
      setMode("USER");
      setModeSession("USER");
    }
  }, [currentUserMode]);

  useEffect(() => {
    if (UrlParamPaymentSuccess === "true") {
      localStorage.setItem("paymentSuccess", "true");
      handleMixPanel(MixPanelEvents.WALLET_PAYMENT_SUCCESS_CHECK, {
        userEmail: userData?.email,
      });
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_SUCCESS,
          msg: "Your payment has gone through, and you’re good to go.",
          heading: "Payment success!",
          autoCloseTime: 10000,
        })
      );
    }
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    handleClosePaymentMenu();
    if (anchorElUser) {
      setAnchorElUser(false);
    } else {
      setAnchorElUser(true);
    }
  };

  const handleOpenPaymentMenu = (event: React.MouseEvent<HTMLElement>) => {
    handleCloseUserMenu();
    if (anchorElPayment) {
      setAnchorElPayment(null);
    } else {
      setAnchorElPayment(event.currentTarget);
    }
  };

  const handleClosePaymentMenu = () => {
    setAnchorElPayment(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(false);
  };

  const logout = async () => {
    try {
      const response = await Auth.signOut({ global: true });
      persistor.purge();
      localStorage.clear();
      sessionStorage.clear();
      dispatch(setTokens({ idToken: "", refreshToken: "" }));
      window.location.reload();
    } catch (err: any) {
      console.log(err);
    }
  };

  const init = async () => {
    setIsLoading(true);
    const response = await getUser();
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK && data.data.entityStatus === "ACTIVE") {
      dispatch(setUserData(data.data));
      setUserName(getUserName(data.data));
      handleMixPanelSetUser(data.data);
      const admin: any = data.data.roles.filter(
        (ele: any) => ele.roleName === "ROLE_ADMIN"
      );
      setIsLoading(false);
    } else {
      const { error = "", details = "" } = data || {};
      if (
        details ===
        "The verification process is currently underway. Please try logging in again later."
      ) {
        logout();
        dispatch(
          setToast({
            open: true,
            severity: "error",
            msg: "There was an error provisioning the account. Contact support@trestleiq.com for assistance.",
          })
        );
      }
    }
  };

  useEffect(() => {
    init();
    const m: any = sessionStorage.getItem("mode");
    if (m) {
      setModeSession(m);
      setMode(m);
    }
  }, []);

  const setModeSession = (modeVal: string) => {
    const m: any = sessionStorage.getItem("mode");

    sessionStorage.setItem("mode", m === modeVal ? m : modeVal);
  };

  // useEffect(() => {
  //   console.log("called");
  //   if (userData.id) {
  //     const admin: any = userData.roles.filter(
  //       (ele: any) => ele.roleName === "ROLE_ADMIN"
  //     );
  //     if (admin.length) {
  //       dispatch(setUserMode("ADMIN"));
  //       setIsAdmin(true);
  //     } else {
  //       dispatch(setUserMode("USER"));
  //       setIsAdmin(false);
  //     }
  //     setUserName(getUserName(userData));
  //   }
  // }, [userData]);

  useEffect(() => {
    if (window.location.pathname === RouteConstants.ROUTE_HOME) {
      let d = sessionStorage.getItem("INVITE-UPDATE") || "";
      if (d === "ACCEPTED") {
        sessionStorage.removeItem("INVITE-UPDATE");
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_SUCCESS,
            msg: "Invitation has been accepted successfully",
          })
        );
      } else {
        if (userData?.id) GetTeamInvitation();
      }
    }
  }, [window.location.pathname, userData]);

  const GetTeamInvitation = async () => {
    //  const response = await getTeamInvitation(userData.id);

    //   const { status = 401, data = {} } = response || {};
    //  if (status === HTTP_STATUS.HTTP_OK) {
    const invitation = userData?.invitationResponse || {};
    if (invitation) {
      if (invitation.id) {
        setInvitationPopup("INVITATION");
        setInviteDetails({ ...invitation, userId: userData.id });
      }
    }
    // } else {
    //   console.error(response);
    //   dispatch(
    //     setToast({
    //       open: true,
    //       severity: AppConstants.TOAST_ERROR,
    //       msg: data.message,
    //     })
    //   );
    // }
  };

  return (
    <>
      <LoaderComponent loader={isLoading} />

      <>
        <div>
          <AppBar
            position="static"
            sx={{
              background: "#163545",
              fontFamily: "inherit",
              position: "relative",
              zIndex: "111111",
              boxShadow: "none",
            }}
            className="app-bar"
          >
            <div style={{ padding: "0 0 0 35px" }}>
              <Toolbar disableGutters>
                <div className="max-w-[315px] w-full">
                  <img
                    src={TrestleLogo}
                    alt="trestle logo"
                    className="h-[24px] cursor-pointer"
                    onClick={() => {
                      navigate("/");
                    }}
                  />
                </div>
                <Box
                  sx={{ flexGrow: 1, display: { xs: "flex", md: "flex" } }}
                  className="app-bar"
                >
                  {isAdmin && (
                    <>
                      <ModeButton
                        key={"admin-mode"}
                        onClick={() => {
                          setMode("ADMIN");
                          dispatch(setUserMode("ADMIN"));
                          setModeSession("ADMIN");
                          handleMixPanel(MixPanelEvents.SWITCH_USER_MODE, {
                            mode: "ADMIN",
                            userEmail: userData?.email,
                            userId: userData?.id,
                          });
                          // navigate(RouteConstants.ROUTE_ADMIN_ALL_USERS);
                        }}
                        className={`mr-[30px] ${
                          mode === "ADMIN" ? "pointer-events-none" : ""
                        }`}
                        selected={mode === "ADMIN"}
                      >
                        {"Admin Mode"}
                      </ModeButton>

                      <ModeButton
                        key={"user-mode"}
                        onClick={() => {
                          setMode("USER");
                          dispatch(setUserMode("USER"));
                          setModeSession("USER");
                          handleMixPanel(MixPanelEvents.SWITCH_USER_MODE, {
                            mode: "USER",
                            userEmail: userData?.email,
                            userId: userData?.id,
                          });
                          // navigate(RouteConstants.ROUTE_YOUR_API_KEY);
                        }}
                        selected={mode === "USER"}
                        className={`${
                          mode === "USER" ? "pointer-events-none" : ""
                        }`}
                      >
                        {"User Mode"}
                      </ModeButton>
                    </>
                  )}
                </Box>
                {mode === "USER" && !loading && (
                  <PaymentWalletComponent
                    anchorElPayment={anchorElPayment}
                    handleOpenPaymentMenu={handleOpenPaymentMenu}
                    setAnchorElPayment={setAnchorElPayment}
                    setIsLoading={setIsLoading}
                  />
                )}
                <Box
                  sx={{
                    flexGrow: 0,
                    background: anchorElUser ? "#EAEBEC" : "",
                    color: anchorElUser ? "#163545" : "",
                  }}
                  className="px-[35px]"
                >
                  <div
                    className="flex cursor-pointer app-bar"
                    onClick={handleOpenUserMenu}
                  >
                    <Avatar
                      alt={userName}
                      src="/static/images/avatar/2.jpg"
                      className="!h-6 !w-6 my-auto"
                    />
                    <ProfileMenuText w={"60px"} className="app-bar">
                      {userName}
                    </ProfileMenuText>
                    <div
                      className="my-auto"
                      style={{
                        transform: anchorElUser
                          ? "rotate(3.142rad)"
                          : "rotate(0)",
                      }}
                    >
                      {DownArrow}
                    </div>
                  </div>
                  <div id="menu-appbar">
                    <ComponentDrawer
                      open={anchorElUser}
                      close={handleCloseUserMenu}
                      userName=""
                      anchor={"top"}
                      width="900px"
                      children={
                        <UserDetails
                          setLoading={setIsLoading}
                          userData={userData}
                          isAdmin={false}
                          closeDrawer={handleCloseUserMenu}
                        />
                      }
                      closeOnBackdropClick={true}
                    />
                  </div>
                </Box>
              </Toolbar>
            </div>
          </AppBar>
        </div>
        <div>
          <TrestleAppDrawerNew isAdmin={isAdmin && mode === "ADMIN"}>
            <ComponentContainer id="comp-render">
              <div>
                <Outlet />
              </div>
              <FooterComponent />
            </ComponentContainer>

            {/* <ComponentContainer>
                {mode === "ADMIN" ? <AdminRoutes /> : <UserRoute />}
              </ComponentContainer> */}
          </TrestleAppDrawerNew>
        </div>
      </>

      <InvitationPopup
        open={invitationPopup}
        setInvitationPopup={setInvitationPopup}
        inviteDetails={inviteDetails}
        setLoading={setIsLoading}
      />
    </>
  );
}
export default TrestleAppBar;
