import { useNavigate } from "react-router-dom";
import { BrandingPage } from "../../Shared/BrandingPage";
import { BrandingPageChildContainer } from "../../Shared/CommonComponents";
import TrestleButton from "../../Shared/TrestleButton";
import { BackwardArrow, ForwardArrow } from "../../../assets/svgs";
import { ConstantTexts } from "../../../constants/ConstantTexts";
import { RouteConstants } from "../../../constants/RouteConstants";

export const AlreadyAnAccount = () => {
  const navigate = useNavigate();
  return (
    <>
      <BrandingPage>
        <BrandingPageChildContainer className="!text-center">
          <div className="mb-[40px] mx-auto text-[28px] font-normal text-[#163545] max-w-[550px]">
            {ConstantTexts.ALREADY_ACCOUNT_TEXT}
          </div>

          <div className="text-center w-[400px] mx-auto">
            <div>
              <TrestleButton
                onClick={() => {
                  navigate(RouteConstants.ROUTE_LOGIN);
                }}
                label="Sign In"
                type="primary"
                icon={ForwardArrow}
              />
            </div>

            <div className="mt-5">
              <TrestleButton
                onClick={() => {
                  navigate(RouteConstants.ROUTE_SIGNUP);
                }}
                label="Back to Sign Up"
                type="secondry"
                icon={BackwardArrow}
                iconFirst={true}
              />
            </div>
          </div>
        </BrandingPageChildContainer>
      </BrandingPage>
    </>
  );
};
