import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { MainHeaderText } from "../../Shared/CommonComponents";
import UnverifiedUsersTab from "./UnverifiedUsersTab"; // Now used for Unverified Emails
import LoaderComponent from "../../Shared/LoaderComponent";
import AllUsersTab from "./AllUsersTab"; // Now used for All Users
import styled from "@emotion/styled";

const PricingPage = () => {
  const [selectedTab, setSelectedTab] = useState<
    "ALL_USERS" | "UNVERIFIED_EMAILS"
  >("ALL_USERS");

  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      {selectedTab && (
        <Box>
          <LoaderComponent loader={loading} />
          <Box sx={{ background: "#F5F5F5" }}>
            <MainHeaderText
              style={{
                textAlign: "left",
                fontSize: "28px",
                padding: "50px 50px 20px 50px",
              }}
            >
              Manage Users
            </MainHeaderText>
            <Box className="flex px-[50px] justify-start">
              <TabComponent
                style={{ fontSize: "16px" }}
                selected={selectedTab === "ALL_USERS"}
                onClick={() => setSelectedTab("ALL_USERS")}
              >
                Active Users
              </TabComponent>
              <TabComponent
                style={{ fontSize: "16px" }}
                selected={selectedTab === "UNVERIFIED_EMAILS"}
                onClick={() => setSelectedTab("UNVERIFIED_EMAILS")}
              >
                Unverified Users
              </TabComponent>
            </Box>
          </Box>
          <Box>
            {selectedTab === "ALL_USERS" && <AllUsersTab />}
            {selectedTab === "UNVERIFIED_EMAILS" && <UnverifiedUsersTab />}
          </Box>
        </Box>
      )}
    </>
  );
};

export default PricingPage;

export const TabComponent = styled.div<{ selected: boolean }>`
  font-size: 24px;
  font-weight: ${(props) => (props.selected ? "500" : "400")};
  line-height: 29px;
  border-bottom: ${(props) => (props.selected ? "4px solid #0494B4" : "none")};
  cursor: pointer;
  margin-right: 30px;
  color: ${(props) => (props.selected ? "#163545" : "#566D76")};
  padding-bottom: 21px;
`;
