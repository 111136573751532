import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import { InputBase } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { InputErrortext, InputLabel } from "./InputTextField";
import Alerttriangle from "../../assets/Alert-Triangle.svg";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, selectedItem: string[], theme: Theme) {
  return {
    fontFamily: "DM Sans !important",
    fontWeight:
      selectedItem?.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
    textWrap: "wrap",
  };
}

interface DropDownProps {
  value: any;
  error: any;
  onChange: any;
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  options: string[];
  className?: string;
  multiSelect?: boolean;
  truncateOption?: boolean;
  style?: React.CSSProperties;
}

export default function DropDown({
  value,
  error,
  onChange,
  name,
  label,
  required = false,
  disabled = false,
  placeholder = "",
  options,
  className = "",
  multiSelect = false,
  truncateOption = false,
}: DropDownProps) {
  const theme = useTheme();
  const [selectedItem, setSelectedItem] = React.useState<string[]>(value);

  const handleChange = (event: SelectChangeEvent<typeof selectedItem>) => {
    const {
      target: { value },
    } = event;
    let str = typeof value === "string" ? value.split(",") : value;
    setSelectedItem(str);
    onChange(value);
  };

  React.useEffect(() => {
    let str = typeof value === "string" ? value.split(",") : value;
    setSelectedItem(str);
  }, [value]);

  return (
    <div className="w-full">
      <div className="flex">
        {label && (
          <InputLabel className="flex-1 w-fit min-h-[16px]">
            {label || ""}
            {required && <span style={{ color: "#b23352" }}> *</span>}
          </InputLabel>
        )}
        {error && (
          <InputErrortext className="flex ml-auto">
            <img src={Alerttriangle} alt="required" className="mr-[10px]" />
            {error}
          </InputErrortext>
        )}
      </div>
      <FormControl sx={{ m: 1, width: 300 }} className="!w-full !m-0">
        <Select
          className={`w-full h-[60px] px-3 ${
            error ? "bg-[#F4E1E5]" : "bg-[#F3F5F6]"
          } ${className} font-DMSans`}
          id="dropdown-component"
          multiple={multiSelect}
          value={selectedItem}
          onChange={handleChange}
          input={<InputBase />}
          MenuProps={MenuProps}
          placeholder={placeholder}
          disabled={disabled}
        >
          {options?.length ? (
            options.map((opt) => (
              <MenuItem
                key={opt}
                value={opt}
                sx={{
                  fontFamily: "DM Sans !important",
                  fontWeight:
                    selectedItem?.indexOf(opt) === -1
                      ? theme.typography.fontWeightRegular
                      : theme.typography.fontWeightMedium,
                  textWrap: "wrap",
                }}
                className={`${
                  opt === "All Products" ? "!hidden" : ""
                } menu-hover`}
              >
                <span
                  className={`${
                    truncateOption
                      ? "max-w-[250px] !whitespace-nowrap !text-ellipsis !overflow-hidden font-DMSans"
                      : "font-DMSans"
                  }`}
                >
                  {opt}
                </span>
              </MenuItem>
            ))
          ) : (
            <MenuItem
              key={"no item"}
              value={"There is no options"}
              sx={{
                fontFamily: "DM Sans !important",
                fontWeight:
                  selectedItem?.indexOf("There is no options") === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
                textWrap: "wrap",
              }}
              className="pointer-events-none"
              disabled={true}
            >
              {"There is no options"}
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </div>
  );
}
