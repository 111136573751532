import React, { useEffect, useState } from "react";
import { UpdateUserStatus, getAllUsers } from "../../../http/admin-service";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import TrestleTable, {
  TableHeaderProps,
} from "../../Shared/TrestleTable/TrestleTable";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import InputTextField from "../../Shared/InputTextField";
import moment from "moment";
import LoaderComponent from "../../Shared/LoaderComponent";
import DropDown from "../../Shared/DropDown";
import NoDataFound from "../../Shared/NoDataFound";
import { find } from "lodash";
import UserDrawer from "../UserDrawer/UserDrawer";
import TrestleButton from "../../Shared/TrestleButton";
import { getUTCDate } from "../../../utils/helpers";
import { Box, Tooltip, Typography } from "@mui/material";
import { InfoIcon } from "../../../assets/svgs";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import { useAppSelector } from "../../../app/hooks";
import AddNewUserPopUp from "../AddNewUser/addNewUserPopUp";
import DialogPopup from "../../Shared/DialogPopup";
import { Helmet } from "react-helmet";
import DateRangeTrestle from "../../Shared/DateRangePicker";
import { VerifyUser } from "../../../http/admin-service";

export interface IUsers {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  company: string;
  createdAt: Date;
}

const UnverifiedUsersTab = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<IUsers[]>([]);
  const [usersBack, setUsersBack] = useState<IUsers[]>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [noOfRecords, setNoOfRecords] = useState(20);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [searchData, setSearchData] = useState<any>({
    searchString: "",
    searchKey: "Name",
  });
  const [isDisableUserOpen, setIsDisableUserOpen] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<any>([]);
  const [isVerifyUserOpen, setIsVerifyUserOpen] = useState<boolean>(false);
  const [userToVerify, setUserToVerify] = useState<any>(null);
  const dropDownValues = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Company", key: "company" },
  ];

  const dispatch = useDispatch();
  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  const [dateRage, setDateRage] = useState<any>({
    startTime: new Date(),
    endTime: new Date(),
  });

  const [plotFilterData, setPlotFilterData] = useState<any>({
    products: ["All Products"],
    apikeys: [],
    timeline: "Daily",
    timeframe: "Last 7 days",
    plantype: "ALL",
  });

  const timeFrameOptions: any = [
    "Today",
    "Yesterday",
    "Last 7 days",
    "Last 30 days",
    "Last 3 months",
    "Last 6 months",
    "Last 1 year",
    "Custom range",
  ];

  const numberDays: any = {
    Today: 0,
    Yesterday: 2,
    "Last 7 days": 7,
    "Last 30 days": 30,
    "Last 3 months": 90,
    "Last 6 months": 180,
    "Last 1 year": 365,
  };

  const dateFormat = (date: any) => {
    return moment(date).format("YYYY-MM-DD");
  };

  const onInputValueChange = (ele: any) => {
    const { value, name } = ele.target;
    setSearchData({ ...searchData, [name]: value });
  };

  useEffect(() => {
    window.SetMode("ADMIN");
    handleMixPanel(MixPanelEvents.VISIT_ADMIN_ALL_USERS, {
      userId: userData?.id,
      userEmail: userData?.email,
    });
  }, []);

  const LastNthDays = (timeframeO: string) => {
    if (timeframeO === "Custom range") {
      const { startTime, endTime } = dateRage;
      return {
        startTime: dateFormat(startTime),
        endTime: dateFormat(endTime),
      };
    }

    let lastDays: number = numberDays[timeframeO];

    if (lastDays !== null) {
      let result = [];
      for (let i = 0; i < lastDays; i++) {
        let d = new Date();
        d.setDate(d.getDate() - i);
        result.push(d);
      }

      return {
        startTime: dateFormat(result[result.length - 1]),
        endTime:
          timeframeO === "Yesterday"
            ? dateFormat(result[result.length - 1])
            : dateFormat(result[0]),
      };
    } else {
      const { startTime, endTime } = dateRage;
      return {
        startTime: dateFormat(startTime),
        endTime: dateFormat(endTime),
      };
    }
  };

  useEffect(() => {
    let reqData: any = {
      pageNumber: pageNumber,
      noOfRecords: noOfRecords,
      emailVerified: false,
    };

    if (searchData.searchKey !== "" && searchData.searchString !== "") {
      let searchKeyObj: any = find(dropDownValues, {
        label: searchData.searchKey,
      });

      reqData[searchKeyObj.key] = searchData.searchString;

      const callApiTimeout = setTimeout(() => {
        getAllUsersApiCall(reqData);
      }, 1000);

      return () => clearTimeout(callApiTimeout);
    } else {
      getAllUsersApiCall(reqData);
    }
  }, [pageNumber, noOfRecords, searchData]);

  const getAllUsersApiCall = async (requestData: any) => {
    setLoading(true);

    const { timeframe } = plotFilterData;
    const startEndData = LastNthDays(timeframe);

    let reqData: any = {
      pageNumber: pageNumber,
      noOfRecords: noOfRecords,
      emailVerified: false,
      startTime: startEndData.startTime,
      endTime: startEndData.endTime,
    };

    if (searchData.searchKey !== "" && searchData.searchString !== "") {
      let searchKeyObj = find(dropDownValues, { label: searchData.searchKey });
      if (searchKeyObj && searchKeyObj.key) {
        reqData[searchKeyObj.key] = searchData.searchString;
      }
    }

    const response = await getAllUsers(reqData);
    setLoading(false);

    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      setTotalRecords(data.data.totalRecords);
      setUsers(formatUserList(data.data.users));
    } else {
      const { details } = data;
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: details,
        })
      );
    }
  };

  const formatUserList = (users: any[]) => {
    let temp: any = users.map((user: any) => {
      const { company = null, name: teamName = "-" } = user?.team || {};
      const { name = "-" } = company || {};
      return { ...user, team: teamName, company: name, teamData: user?.team };
    });

    return temp;
  };

  const verifyUser = async () => {
    setLoading(true);
    const response = await VerifyUser(userToVerify.email);
    setLoading(false);

    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_SUCCESS,
          msg: "User verified successfully. Changes will take couple minutes to reflect",
          autoCloseTime: 5000,
        })
      );
      setIsVerifyUserOpen(false);
      //getAllUsersApiCall({ pageNumber, noOfRecords, emailVerified: false });
    } else {
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: "Error verifying user",
          autoCloseTime: 3000,
        })
      );
      setIsVerifyUserOpen(false);
    }
  };

  useEffect(() => {
    setUsersBack(users);
  }, [users]);

  const headCells: readonly TableHeaderProps[] = [
    {
      id: "id",
      numeric: false,
      disablePadding: false,
      label: "User Id",
      width: "5%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Name",
      width: "10%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return ele.firstName + " " + ele.lastName;
      },
      sortable: false,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
      width: "15%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "company",
      numeric: false,
      disablePadding: false,
      label: "Company",
      width: "10%",
      isCustomElements: false,
      customElements: (ele: any) => {
        const { company = null } = ele?.team || {};
        const { name = "-" } = company || {};
        return `${name}`;
      },
      sortable: true,
    },
    {
      id: "phone",
      numeric: false,
      disablePadding: false,
      label: "Phone Number",
      width: "10%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return ele.phone || "-";
      },
      sortable: false,
    },
    {
      id: "entityStatus",
      numeric: false,
      disablePadding: false,
      label: "Status",
      width: "10%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return "Unverified";
      },
      sortable: true,
    },
    {
      id: "createdAt",
      numeric: false,
      disablePadding: false,
      label: (
        <div className="flex">
          <div>Created At</div>
          <Tooltip title={"Date is in UTC"} arrow sx={{ width: "100px" }}>
            <span className="ml-[5px]">{InfoIcon}</span>
          </Tooltip>
        </div>
      ),
      width: "12%",
      isCustomElements: true,
      customElements: (ele: any) => {
        return (
          <span>
            {moment(getUTCDate(ele.createdAt || "")).format("MM/DD/YYYY")}
          </span>
        );
      },
      sortable: true,
    },
    {
      id: "verifyUser",
      numeric: false,
      disablePadding: false,
      label: "Verify User",
      width: "8%",
      isCustomElements: true,
      customElements: (ele: any) => (
        <div className="w-full flex justify-center">
          <TrestleButton
            onClick={() => {
              setUserToVerify(ele);
              setIsVerifyUserOpen(true);
            }}
            label="Verify"
            type="primary"
            className="!h-auto !min-h-[40px] !w-full !max-w-[75px]"
          />
        </div>
      ),
      sortable: false,
    },
  ];

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Trestle Developer Portal</title>
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_DEV_PORTAL_URL}admin/users`}
        />
      </Helmet>
      <LoaderComponent loader={loading} />
      <div
        className="pr-[50px] min-h-full bg-white"
        style={{ paddingBottom: "20px" }}
      >
        <div className="px-[50px] h-full">
          <div
            className="flex items-center justify-between flex-wrap"
            style={{ marginBottom: "20px", paddingTop: "20px" }}
          >
            <div className="flex items-center">
              <div className="max-w-[180px] w-full mr-2">
                <DropDown
                  name={"timeframe"}
                  label={"Timeframe"}
                  value={plotFilterData.timeframe}
                  error={""}
                  placeholder={"Search"}
                  onChange={(val: any) => {
                    setPlotFilterData({ ...plotFilterData, timeframe: val });
                  }}
                  required={false}
                  options={timeFrameOptions}
                  className="!h-[50px] text-[#163545]"
                />
              </div>
              {plotFilterData.timeframe === "Custom range" && (
                <div className="max-w-[280px] w-full mr-2">
                  <DateRangeTrestle
                    value={dateRage}
                    setValue={(val: any) => {
                      setDateRage({
                        startTime: val.startDate,
                        endTime: val.endDate,
                      });
                    }}
                  />
                </div>
              )}
              <TrestleButton
                onClick={() => {
                  const { timeframe } = plotFilterData;
                  const startEndData = LastNthDays(timeframe);

                  let reqData = {
                    pageNumber: pageNumber,
                    noOfRecords: noOfRecords,
                    emailVerified: false,
                    startTime: startEndData.startTime,
                    endTime: startEndData.endTime,
                  };

                  getAllUsersApiCall(reqData);
                }}
                label="Get Users"
                type="primary"
                className="!h-[50px] !leading-[50px] !p-0 !min-w-[150px] !max-w-[150px] mt-auto"
              />
            </div>

            <div className="flex items-center">
              <div
                className="max-w-[180px] w-full mr-2"
                style={{ marginTop: "30px" }}
              >
                <DropDown
                  name={"searchKey"}
                  label={""}
                  value={searchData["searchKey"]}
                  error={""}
                  placeholder={"Search"}
                  onChange={(val: any) => {
                    setSearchData({
                      ...searchData,
                      searchKey: val,
                      searchString: "",
                    });
                  }}
                  required={false}
                  options={dropDownValues.map((key) => key.label)}
                  className="!h-[50px] text-[#163545]"
                />
              </div>

              <div
                className="max-w-[400px] w-full  min-w-[300px]"
                style={{ marginTop: "30px" }}
              >
                <InputTextField
                  name={"searchString"}
                  label={""}
                  placeholder="Search"
                  value={searchData}
                  error={{}}
                  type={"text"}
                  onChange={onInputValueChange}
                  required={false}
                  maxLength={AppConstants.MAX_NAME_LENGTH}
                  height="50px"
                  searchable={true}
                />
              </div>
            </div>
          </div>

          {users.length > 0 ? (
            <TrestleTable
              isPaginated={true}
              headCells={headCells}
              rows={usersBack}
              setPageNumber={setPageNumber}
              totalNoOfRecords={totalRecords}
              setNoOfRecords={setNoOfRecords}
            />
          ) : (
            !loading && <NoDataFound className="py-52" />
          )}
        </div>
      </div>
      <DialogPopup
        open={isVerifyUserOpen}
        onClose={() => setIsVerifyUserOpen(false)}
        className="!p-0 w-[700px]"
        component={
          <>
            <div className="w-full py-8 px-10 bg-[#F3F5F6] text-[#163545] mb-6">
              <div className="text-xl font-bold flex">Verify User</div>
            </div>
            <Typography className="flex mt-[20px] py-8 px-10">
              Are you sure you want to verify this user?
            </Typography>
            <Box className="flex gap-[10px] mt-[20px] py-8 px-10">
              <TrestleButton
                onClick={verifyUser}
                label="Confirm"
                type="primary"
                className="!h-[25px]!min-w-[fit-content]"
              />
              <TrestleButton
                onClick={() => setIsVerifyUserOpen(false)}
                label="Cancel"
                type="secondry"
                className="!h-[25px]!min-w-[fit-content]"
              />
            </Box>
          </>
        }
      />
    </>
  );
};

export default UnverifiedUsersTab;
