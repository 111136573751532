import * as React from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { ClosePopup } from "../../assets/svgs";
import TrestleButton from "./TrestleButton";

interface WaringPopupProps {
  open: boolean;
  close: any;
  title: string;
  text: string;
  buttonLable: string;
  cancelLabel?: string;
  onAction: any;
  htmlElement?: any;
  buttonIcon?: any;
  disabledBtn?: boolean;
}

export default function WaringPopup({
  open,
  close,
  title,
  text,
  buttonLable,
  onAction,
  htmlElement = null,
  cancelLabel = "Cancel",
  buttonIcon = null,
  disabledBtn = false,
}: WaringPopupProps) {
  return (
    <Dialog
      onClose={close}
      open={open}
      className="overflow-hidden w-full !p-[40px]"
    >
      <DialogContent className="!p-[40px] w-[580px]">
        <div>
          <div className="font-bold text-lg">{title}</div>
          <div className="font-normal text-base mb-[75px] mt-[10px]">
            {htmlElement ? htmlElement : text}
          </div>
          <div className="flex">
            <TrestleButton
              onClick={close}
              label={cancelLabel}
              type="secondry"
              className="max-w-[180px] !h-[40px] !py-0 !pt-0 mr-5 ml-auto"
            />
            <TrestleButton
              onClick={onAction}
              label={buttonLable}
              type="primary"
              className="max-w-[180px] !h-[40px] !py-0 !pt-0"
              icon={buttonIcon}
              disabled={disabledBtn}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
