import React from "react";
import styled from "@emotion/styled";

export const SelfServeTopDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  align-items: flex-start;
  padding: 30px;
  justify-content: center;
  background: #eaebec;
  gap: 10px;
`;

export const SelfServeBottomDiv = styled.div`
  display: flex;
  width: 400px;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  justify-content: center;
  background: #ffffff;
  gap: 30px;
`;

export const ActionButtonContainer = styled.div<{ u?: string }>`
  display: flex;
  gap: 10px;
  align-items: center;
  color: #0494b4;
  cursor: pointer;
  text-decoration: ${(props: any) => (props.u ? props.u : "none")};
  font-weight: 700;
  font-size: 14px;
`;

export const WalletAmountDisplay = styled.div`
  display: flex;
  width: 100%;
  height: 49px;
  padding: 16px;
  justify-content: space-between;
  background: #f5f5f5;
`;
