import BaseAPIClient from "./api";
import { AppConstants } from "../constants/AppConstants";
import axios from "axios";

const globalHeaders: any = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const API_BASE_URL: string =
  process.env.REACT_APP_API_URL || "http://localhost";
const API = BaseAPIClient?.axiosInstance;

export const getProductsAndAPIkeys = async (email?: string) => {
  const headers = { ...globalHeaders };
  let uri: string;
  if (email) {
    uri = `${API_BASE_URL}${
      AppConstants.GET_API_AND_PRODUCT_LIST
    }?email=${email?.replace("+", "%2b")}`;
  } else {
    uri = `${API_BASE_URL}${AppConstants.GET_API_AND_PRODUCT_LIST}`;
  }
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const revokeAPIKey = async (subscriptionId: string | number) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.REVOKE_API_KEY}`;
  const response = await API.post(
    uri,
    { subscriptionId },
    {
      headers,
    }
  );
  return response;
};

export const createAPIKeyTrial = async (email: string) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.CREATE_API_KEY.replace(
    "{{email}}",
    email?.replace("+", "%2b")
  )}`;

  const response = await API.post(
    uri,
    {},
    {
      headers,
    }
  );
  return response;
};

export const getUsageData = async (payload: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.GET_USAGE_DATA}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

// version 2
export const getUsageDataV2 = async (payload: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.GET_USAGE_DATA_V2}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const getUserSubscriptionFromAdmin = async (email: string) => {
  const headers = { ...globalHeaders };
  let formateEmail: string = email ? email.replace("+", "%2b") : "";
  let uri: string = `${API_BASE_URL}${AppConstants.GET_SUBSCRIPTION_FOR_USER.replace(
    "{{email}}",
    formateEmail
  )}`;
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const updateSubscription = async (payload: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.UPDATE_SUBSCRIPTIOIN}`;
  const response = await API.put(uri, payload, {
    headers,
  });
  return response;
};

export const callExportCsvAPI = async (payload: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.EXPORT_CSV}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

// version 2
export const callExportCsvAPIV2 = async (payload: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.EXPORT_CSV_V2}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const GetAdminUsage = async (payload: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.GET_ADMIN_USAGE_DATA}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const GetAdminUsagePerCustomer = async (payload: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.GET_USER_USAGE_DATA_AS_PER_CUSTOMR}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const GetAdminUsagePerAPIey = async (payload: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.GET_USAGE_DATA_AS_PER_APIKEY}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const getAPIkeyProductOfUser = async (userId: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.GET_API_PRODUCTS_OF_USER.replace(
    "{{userId}}",
    userId
  )}`;
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const getAllProducts = async () => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.GET_ALL_PRODUCTS}`;
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const callExportCsvAPIChartOne = async (payload: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.EXPORT_CSV_ADMIN_USAGE}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const callExportCsvAPIChartTwo = async (payload: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.EXPORT_CSV_USER_USAGE_DATA_AS_PER_CUSTOMR}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const callExportMasterReportCSV = async (payload: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.EXPORT_MASTER_REPORT_CSV}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const callExportMasterReportCSVV2 = async (payload: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.EXPORT_MASTER_REPORT_CSV_V2}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const getCustomAttributesDropdownValues = async () => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.GET_CUSTOM_ATTRIBUTES_DROPDOWN_VALUE}`;
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const uploadCsvFile = async (payload: any) => {
  let headers = { ...globalHeaders };
  headers["Content-Type"] = "multipart/form-data";

  let uri: string = `${API_BASE_URL}${AppConstants.UPLOAD_CSV}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const callCustomerUsageReport = async (payload: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.CUSTOMER_USAGE_REPORT}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

// version 2
export const callCustomerUsageReportV2 = async (payload: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.CUSTOMER_USAGE_REPORT_V2}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const getSelfServeUsage = async (payload: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.GET_SELF_SERVE_USAGE}`;
  const response = await API.post(uri, payload, {
    headers,
  });
  return response;
};

export const changeSubscriptionName = async (id: any, name: string) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.UPDATE_SUBSCRIPTIOIN_NAME.replace(
    "{{id}}",
    `${id}`
  ).replace("{{name}}", name)}`;

  const response = await API.put(
    uri,
    {},
    {
      headers,
    }
  );
  return response;
};
