import { useState, useEffect, useRef } from "react";
import LoaderComponent from "../../../Shared/LoaderComponent";
import {
  getSubscriptions,
  requestProductAccess,
  getRevokedApiKeys,
} from "../../../../http/authentication-service";
import { HTTP_STATUS } from "../../../../http/constants/http.status";
import { setToast } from "../../../../app/toastReducer";
import { AppConstants } from "../../../../constants/AppConstants";
import { useDispatch } from "react-redux";
import NoDataFound from "../../../Shared/NoDataFound";
import { useAppSelector } from "../../../../app/hooks";
import RevokeAPIKey from "../../../RevokeApiKey/RevokeApiKey";
import moment from "moment";
import APIAccordionItem from "./APIAccordionItem";
import {
  createAPIKeyTrial,
  getUserSubscriptionFromAdmin,
  revokeAPIKey,
  updateSubscription,
  getCustomAttributesDropdownValues,
  changeSubscriptionName,
} from "../../../../http/subscription-service";
import TrestleButton from "../../TrestleButton";
import { ConstantTexts } from "../../../../constants/ConstantTexts";

interface APIKeyListProps {
  userData: any;
  setInitialFormdate?: any;
  isAdmin?: boolean;
  usersDetails?: any;
}

function APIKeyList({
  userData = {},
  setInitialFormdate = () => {},
  isAdmin = false,
  usersDetails={},
}: APIKeyListProps) {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<string | false>("");
  const [ApiKeyList, setApiKeyList] = useState<any[]>([]);
  const [revokedApiKeyList, setRevokedApiKeyList] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [APIKeyListBack, setApiKeyListBack] = useState<any[]>([]);
  const [editIndex, setEditIndex] = useState<any>(null);
  const [loaderText, setLoaderText] = useState<string>("");
  const [customAttributesDropdownValues, setCustomAttributesDropdownValues] =
    useState<any>(null);

  useEffect(() => {
    if (!isLoading) {
      setLoaderText("");
    }
  }, [isLoading]);

  useEffect(() => {
    init();
    setExpanded(`panel0`);
    setInitialFormdate();
  }, []);

  const init = async (msg: string = "") => {
    const { email = "" } = userData || {};
    await fetchApiKeyList(email);
    await fetchRevokedApiKeyList(email, msg);
  };

  useEffect(() => {
    setApiKeyListBack(ApiKeyList);
  }, [ApiKeyList]);

  const fetchCustomAttributesDropdownValues = async () => {
    setIsLoading(true);
    const response = await getCustomAttributesDropdownValues();
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      setCustomAttributesDropdownValues(data.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const fetchApiKeyList = async (email = "") => {
    setIsLoading(true);
    const response = await getUserSubscriptionFromAdmin(email);
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      setApiKeyList(data.data);
      setApiKeyListBack([]);
      // setIsLoading(false);
      await fetchCustomAttributesDropdownValues();
    } else {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const fetchRevokedApiKeyList = async (email: string, msg: string = "") => {
    setIsLoading(true);
    const response = await getRevokedApiKeys(email);
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      setRevokedApiKeyList(data.data);
      setIsLoading(false);
      if (msg) {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_SUCCESS,
            msg: msg,
          })
        );
      }
    } else {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const expiryDate = (date_string: string, creationDate: string) => {
    let expiration = moment(date_string).format("YYYY-MM-DD");
    let current_date = moment(creationDate).format("YYYY-MM-DD");
    let days = moment(expiration).diff(current_date, "days");
    return days;
  };

  const RevokeAPIKeyCall = async (subscriptionId: string | number) => {
    setIsLoading(true);
    setLoaderText(ConstantTexts.LONG_TIME_LOADING_TEXT);

    const response = await revokeAPIKey(subscriptionId);
    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      setExpanded("");
      init(AppConstants.APIKEY_REVOKE_SUCCESS);
      // dispatch(
      //   setToast({
      //     open: true,
      //     severity: AppConstants.TOAST_SUCCESS,
      //     msg: AppConstants.APIKEY_REVOKE_SUCCESS,
      //   })
      // );
    } else {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const CreateTrialSubscription = async () => {
    const response = await createAPIKeyTrial(userData?.email || "");
    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_CREATED || status === HTTP_STATUS.HTTP_OK) {
      init(AppConstants.APIKEY_CREATE_SUCCESS);
      // dispatch(
      //   setToast({
      //     open: true,
      //     severity: AppConstants.TOAST_SUCCESS,
      //     msg: AppConstants.APIKEY_CREATE_SUCCESS,
      //   })
      // );
    } else {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const updateSubscriptionName = async (
    id: any,
    name: string,
    callback: any = null
  ) => {
    setIsLoading(true);
    const response = await changeSubscriptionName(id, name);
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      if (callback) {
        callback();
      } else {
        init(AppConstants.SUBSCRIPTION_UPDATE_SUCCESS);
      }
    } else {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const UpdateSubscriptionCall = async (payload: any, callback: any) => {
    setIsLoading(true);
    setLoaderText(ConstantTexts.LONG_TIME_LOADING_TEXT);

    const { email = "" } = userData || {};
    payload.email = email;

    const response = await updateSubscription(payload);
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_CREATED || status === HTTP_STATUS.HTTP_OK) {
      init(AppConstants.SUBSCRIPTION_UPDATE_SUCCESS);
      // dispatch(
      //   setToast({
      //     open: true,
      //     severity: AppConstants.TOAST_SUCCESS,
      //     msg: AppConstants.SUBSCRIPTION_UPDATE_SUCCESS,
      //   })
      // );
      callback();
    } else {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: data.details,
        })
      );
    }
  };

  const detailRef: any = useRef();

  const onFocusView = () => {
    const scrollHeight = window.pageYOffset - window.innerHeight;
    const h = window.scrollY - detailRef.current.clientHeight;
    // const h = window.scrollY - detailRef.current.offsetTop;
    window.scrollTo({
      top: scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <LoaderComponent loader={isLoading} msg={loaderText} />
      <div className="">
        <div className="w-full flex py-[10px] gap-[20px]">
          {(usersDetails?.entityStatus == "DEACTIVATED" && isAdmin) ?(
            <div className="w-[100%] p-[12px] bg-red-600 h-[50px]">
            This User Is Deactivated and won't be able to log in.
          </div>
          ):(
            <></>)}
          <TrestleButton
            onClick={() => {
              setIsLoading(true);
              CreateTrialSubscription();
            }}
            label="Create New API Key  +"
            type="primary"
            className="!h-[50px] !leading-[40px] !py-[20px] w-[180px] mb-5 !w-fit ml-auto"
            disabled={editIndex !== null}
          />
        </div>
        <div ref={detailRef}>
          {customAttributesDropdownValues &&
            APIKeyListBack.map((ele: any, index: number) => {
              return (
                <APIAccordionItem
                  key={`key-acco-${ele}`}
                  expanded={expanded}
                  setExpanded={(val: any) => {
                    setExpanded(val);
                    onFocusView();
                  }}
                  index={index}
                  APIKeyData={ele}
                  RevokeAPIKey={RevokeAPIKeyCall}
                  setEditIndex={setEditIndex}
                  editIndex={editIndex}
                  UpdateSubscriptionCall={UpdateSubscriptionCall}
                  UpdateSubscriptionName={updateSubscriptionName}
                  isLoading={isLoading}
                  customAttributesDropdownValues={
                    customAttributesDropdownValues || {}
                  }
                  userData={usersDetails}
                />
              );
            })}
        </div>

        {!isLoading && !ApiKeyList.length && !revokedApiKeyList.length && (
          <NoDataFound
            text={"There is no APIKEY"}
            className="mt-52"
            showIcon={true}
          />
        )}

        <div>
          {revokedApiKeyList.length ? (
            <RevokeAPIKey listData={revokedApiKeyList} />
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}

export default APIKeyList;
