var CryptoJS = require("crypto-js");

const encryption_key = process.env.REACT_APP_ENCRYPTION_KEY || "";

// Function to encrypt with AES CFB
export const EncryptPasswordAES = (password: string) => {
  if (!encryption_key) return password;

  // Generate random salt and IV
  const salt = CryptoJS.lib.WordArray.random(16);
  const iv = CryptoJS.lib.WordArray.random(16);

  // Derive key using PBKDF2
  const key = CryptoJS.PBKDF2(encryption_key, salt, {
    keySize: 256 / 32,
    iterations: 65536,
    hasher: CryptoJS.algo.SHA256,
  });

  // Encrypt
  const encrypted = CryptoJS.AES.encrypt(password, key, {
    iv: iv,
    mode: CryptoJS.mode.CFB,
    padding: CryptoJS.pad.NoPadding,
  });

  // Combine salt, iv, and encrypted data
  const combinedData = salt.concat(iv).concat(encrypted.ciphertext);

  // Encode to Base64
  const encodedString = CryptoJS.enc.Base64.stringify(combinedData);

  return encodedString;
};