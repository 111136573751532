import * as React from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setToast } from "../../app/toastReducer";
import { AppConstants } from "../../constants/AppConstants";
import { CheckIcon } from "../../assets/svgs";
import { AlertTitle } from "@mui/material";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function ToastMsg() {
  const dispatch = useAppDispatch();
  const toastData: any = useAppSelector((state: any) => state.toastReducer);

  const {
    open = false,
    severity = AppConstants.TOAST_INFO,
    msg = "",
    heading = "",
    autoCloseTime = "",
    tostIcon = "",
  } = toastData || {};

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(setToast({ ...toastData, open: false }));
  };

  return (
    <Stack spacing={2} sx={{ width: "100%" }}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={open}
        autoHideDuration={
          autoCloseTime ? autoCloseTime : AppConstants.TOAST_AUTOHIDE_TIME
        }
        onClose={handleClose}
        sx={{
          backgroundColor: AppConstants.TOAST_BACKGROUND_COLOR[severity],
          zIndex: "9999999999",
        }}
      >
        <Alert
          onClose={handleClose}
          // icon={<div className="flex items-center">{CheckIcon}</div>}
          iconMapping={{
            success: <div className="flex items-center">{CheckIcon}</div>,
          }}
          severity={severity}
          sx={{
            width: "100%",
            "& .MuiAlert-icon": {
              alignItems: "center",
              marginTop: "4px",
            },
          }}
          style={{
            backgroundColor: AppConstants.TOAST_BACKGROUND_COLOR[severity],
          }}
        >
          {msg ? (
            <AlertTitle
              sx={{
                fontFamily: "DM Sans",
                fontSize: "16px",
                fontWeight: "700",
                lineHeight: "19.2px",
                letterSpacing: "-0.02em",
                textAlign: "left",
              }}
            >
              {heading}
            </AlertTitle>
          ) : (
            <></>
          )}
          {msg ? msg : ""}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
