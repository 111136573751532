import { Box } from "@mui/material";
import React from "react";
import { Text16pxBold } from "../../Shared/CommonComponents";
import { BatchUploadUpgradeIcon } from "../../../assets/svgs";
import { ActionButtonContainer } from "../../Shared/PaymentWallet/WalletComponets/Styles";

interface INoBatchDataComponent {
  onClick?: () => void;
}

const NoBatchDataComponent = ({
  onClick = () => {},
}: INoBatchDataComponent) => {
  return (
    <Box className="flex items-center p-[30px] justify-center flex-col gap-[40px] text-center">
      <Text16pxBold className="w-[270px] cursor-default">
        There are no records to display yet.{" "}
        <span
          className="!underline !text-[#0494B4] !cursor-pointer font-bold"
          onClick={onClick}
        >
          Upload a CSV file
        </span>{" "}
        to show results.
      </Text16pxBold>
      <Box>{BatchUploadUpgradeIcon}</Box>
    </Box>
  );
};

export default NoBatchDataComponent;
