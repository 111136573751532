import { NoDataIcon } from "../../assets/svgs";

interface NoDataFoundProps {
  text?: string;
  summary?: string;
  className?: string;
  showIcon?: boolean;
}

function NoDataFound({
  text = "No Data Found",
  summary = "",
  className = "",
  showIcon = false,
}: NoDataFoundProps) {
  return (
    <div className={`w-full text-center ${className}`}>
      {showIcon && <div className="w-fit mx-auto">{NoDataIcon}</div>}
      <div className="text-[22px] font-medium mb-3">{text}</div>
      {summary && <div className="text-base font-normal">{summary}</div>}
    </div>
  );
}

export default NoDataFound;
