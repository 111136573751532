import { Box } from "@mui/material";
import React from "react";

const FooterComponent = () => {
  return (
    <Box
      style={{
        backgroundColor: "#F5F5F5",
        // marginTop: "64px",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        padding: "32px 50px",
        gap: "50px",
      }}
    >
      <Box
        component={"img"}
        src="../../trestle_icon.svg"
        alt="Logo"
        style={{
          width: "24px",
          height: "24px",
        }}
      />

      <Box
        component={"a"}
        href="https://trestleiq.com/terms-of-service/"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#0494B4",
          fontFamily: "DM Sans",
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "16.8px",
          textAlign: "center",
          letterSpacing: "-0.01em",
        }}
      >
        Terms of Service
      </Box>

      <Box
        component={"a"}
        href="https://trestleiq.com/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color: "#0494B4",
          fontFamily: "DM Sans",
          fontSize: "14px",
          fontWeight: 700,
          lineHeight: "16.8px",
          textAlign: "center",
          letterSpacing: "-0.01em",
        }}
      >
        Privacy Policy
      </Box>
    </Box>
  );
};

export default FooterComponent;
