import styled from "@emotion/styled";
import React from "react";

function Terms({ onClose }: any) {
  return (
    <div className="">
      <div className="mt-5">
        <div>
          <div className="uicore-content">
            {/* <h2 className="has-text-align-left wp-block-heading text-[40px] leading-[52px] font-medium">
              Terms of Service
            </h2> */}
            <Paragraph className="text-[18px] leading-[32px]">
              Last updated:&nbsp; September 21, 2022
            </Paragraph>
            <Paragraph>
              Welcome to Trestle Solutions, Inc. (“<em>Trestle</em>,” “
              <em>we</em>,” or “<em>us</em>”), and thank you for signing up for
              our global identity verification and fraud prevention products and
              services.&nbsp; By creating an account, clicking to accept this
              Agreement (defined below), signing an Order with us (whether
              through an online registration page or directly with us) that
              expressly incorporates this Agreement by reference or otherwise
              accessing or using our products and services, you agree to these
              terms and conditions.
            </Paragraph>
            <Paragraph>
              If you accept this Agreement on behalf of a company or other legal
              entity, then “<em>Customer</em>” or “<em>you</em>” means that
              entity, and you represent and warrant that: (i) you are authorized
              to bind this entity to this Agreement; (ii) you have read and
              understand this Agreement; and (iii) you agree to the terms and
              conditions of this Agreement on their behalf.
            </Paragraph>
            <Paragraph>
              We may modify this Agreement as described in Section 11.2 below,
              so you should make sure to check this page from time to time.
            </Paragraph>
            <ol>
              <li>Definitions.</li>
            </ol>
            <Paragraph>
              “Affiliate” means any entity that directly or indirectly controls,
              is controlled by, or is under common control with the subject
              entity. “Control,” for purposes of this definition, means direct
              or indirect ownership or control of more than 50% of the voting
              interests of the subject entity.
            </Paragraph>
            <Paragraph>
              ”Agreement” means these Terms of Service and any Orders entered
              pursuant to these Terms of Service, including all referenced
              documents, and all associated amendments and addenda.
            </Paragraph>
            <Paragraph>
              “Customer Data” means electronic data and information submitted by
              or for Customer to the Services.
            </Paragraph>
            <Paragraph>
              “DPA” means a Data Processing Agreement which may be made a part
              of this Agreement.
            </Paragraph>
            <Paragraph>
              “Trestle Data”&nbsp; means information that Trestle makes
              available to Customer through the Services or pursuant to an
              Order, consisting of information from publicly available sources,
              third-party data providers, and Metatdata.
            </Paragraph>
            <Paragraph>
              “Metadata” are data that Trestle derives from its analysis of its
              customers’ data that are submitted to the Services.&nbsp; Examples
              of Metadata include:&nbsp; the number of times a data element has
              been queried in a period of time (velocity) or the last time a
              data element has been seen (recency).&nbsp; Metadata is not
              Customer Data.
            </Paragraph>
            <Paragraph>
              “Order” means a document or online order entered into between
              Customer and Trestle, or any of their Affiliates, that specifies
              the Services to be provided by Trestle.&nbsp; By entering into an
              Order, an Affiliate of Customer agrees to be bound by the terms of
              this Agreement as if it were an original party.
            </Paragraph>
            <Paragraph>
              “Purchased Services” means the Services and Trestle Data that
              Customer or its Affiliate purchases under an Order.&nbsp;
              Purchased Services do not include Services provided pursuant to a
              free trial.
            </Paragraph>
            <Paragraph>
              “Services” are Trestle’s suite of global identity verification
              products and services provided to Customer under an Order, or
              pursuant to a free trial, that Trestle makes available online.
              “Services” exclude Trestle Data.
            </Paragraph>
            <Paragraph>
              “User” means an individual who is authorized by Customer to access
              or use the Services, and who has been provided a user id and
              password, or other account credential.&nbsp; For example, Users
              may include employees, contractors and agents of Customer.
            </Paragraph>
            <ol id="2">
              <li>Our Commitments to You</li>
            </ol>
            <Paragraph>
              2.1 &nbsp; Services and Trestle Data.&nbsp; Trestle will make the
              Services and Trestle Data available to you pursuant to this
              Agreement.&nbsp; The Services are comprised of digital identity
              verification and fraud prevention products and services, some or
              all of which you may elect to receive.&nbsp; You will access
              Trestle Data through the Services, and we will provide the
              application programming interface(s) and web portal(s) to enable
              your access.&nbsp; Trestle provides certain Services and Trestle
              Data by employing machine learning techniques that identify
              patterns in data by using algorithmic models.&nbsp; These models
              leverage Trestle Data, Customer Data, and data provided by
              Trestle’s other customers.&nbsp; When you submit Customer Data to
              the Services, Trestle may analyze Customer Data in conjunction
              with Trestle Data and other customers’ data, to create Metadata to
              provide results to your query.
            </Paragraph>
            <Paragraph>
              2.2 &nbsp; Security of Customer Data.&nbsp; Trestle will maintain
              a security program with reasonable and appropriate administrative,
              physical, and technical safeguards designed to protect your
              Customer Data against unauthorized access or disclosure. For more
              information on Trestle’s security initiatives, please see our
              Security Overview and FAQ, available{" "}
              <a href="https://trestleiq.com/security-and-privacy/">here</a>.
            </Paragraph>
            <Paragraph>
              2.3 &nbsp; Customer Data Deletion.&nbsp; Upon termination of this
              Agreement, you may request deletion of your Customer Data.&nbsp;
              Subject to the “Use of Customer Data” section below, Trestle will
              delete Customer Data within 90 days.
            </Paragraph>
            <Paragraph>2.4 &nbsp; Privacy and Data Protection.&nbsp;</Paragraph>
            <Paragraph>
              &nbsp;In connection with our provision of the Purchased Services,
              Trestle will collect and process Customer Data pursuant to our
              Privacy Policy. Our{" "}
              <a href="https://trestleiq.com/Paragraphrivacy-policy/">
                Privacy Policy
              </a>{" "}
              is subject to change as described therein. By accessing, using,
              and providing information to or through the Purchased Services,
              you acknowledge that you have reviewed and accepted our Privacy
              Policy, and you consent to all actions taken by us with respect to
              your information in compliance with the then-current version of
              our Privacy Policy. With respect to Trestle Data that we make
              available to Customer, we will (a) ensure that we have posted a
              privacy policy that complies with applicable law and, as
              applicable, adequately discloses the sale and disclosure of
              information to categories of third parties, (b) obtain consent and
              offer opt-outs that may be required under applicable law, (c) not
              disclose or make available to Customer personal data about any
              individuals who are 17 years old or younger.
            </Paragraph>
            <Paragraph>
              2.5 &nbsp; Support.&nbsp; We provide support for the Purchased
              Services in accordance with our{" "}
              <a href="https://trestleiq.com/service-tiers/" title="">
                service tiers
              </a>
              .&nbsp; In addition, we will make the Purchased Services available
              24 hours a day, 7 days a week, in accordance with our{" "}
              <a href="https://trestleiq.com/service-level-agreement/">
                service level agreement
              </a>
              .
            </Paragraph>
            <Paragraph>
              2.6 &nbsp; Compliance with Laws.&nbsp; Subject to your use of the
              Services in accordance with these Terms of Service and the
              applicable Order(s), we will provide the Services in accordance
              with laws applicable to our provision of the Services to our
              customers generally—meaning without regard to your particular use
              of the Services.
            </Paragraph>
            <Paragraph>
              2.7 &nbsp; Data Evaluations. If you register on our web site or
              enter into an Order for a free data evaluation, we will make the
              applicable Services available to you on a trial basis free of
              charge until the earlier of (a) the end of the free evaluation
              period for which you registered or the period indicated in the
              Order, (b) the id date of the Purchased Services ordered by you
              following the evaluation, and (c) termination by Trestle, in its
              sole discretion.&nbsp; Additional trial terms and conditions may
              appear on an Order or the trial registration web page, and are
              made a part of this Agreement by reference.
            </Paragraph>
            <Paragraph>
              NOTWITHSTANDING THE “REPRESENTATIONS AND WARRANTIES” SECTION AND
              “INDEMNIFICATION BY TRESTLE” SECTION, DURING A FREE TRIAL THE
              SERVICES ARE PROVIDED “AS-IS”, WITHOUT ANY WARRANTY OF ANY KIND.
              WE SHALL HAVE NO INDEMNIFICATION OBLIGATIONS, NOR LIABILITY OF ANY
              TYPE, WITH RESPECT TO THE SERVICES FOR THE FREE TRIAL PERIOD
              UNLESS THE EXCLUSION OF LIABILITY IS NOT ENFORCEABLE UNDER
              APPLICABLE LAW, IN WHICH CASE OUR LIABILITY SHALL NOT EXCEED
              $5,000.00.&nbsp; NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THE
              “LIMITATION OF LIABILITY” SECTION, YOU ARE FULLY LIABLE FOR ANY
              DAMAGES ARISING OUT OF YOUR OR YOUR AFFILIATES’ USE OF THE
              SERVICES AND TRESTLE DATA DURING ANY FREE TRIAL PERIOD, YOUR
              BREACH OF THIS AGREEMENT, AND YOUR INDEMNIFICATION OBLIGATIONS
              HEREUNDER.
            </Paragraph>
            <ol id="3">
              <li>Your Responsibilities.</li>
            </ol>
            <Paragraph>
              3.1 &nbsp; Generally.&nbsp; You will be responsible for your
              compliance with this Agreement and any Orders, and for the
              compliance of all Affiliates and Users.&nbsp; You will use
              commercially reasonable efforts to prevent unauthorized access to
              or use of the Services and Trestle Data, and will notify us
              without undue delay of any such unauthorized access or use.&nbsp;
              You will use the Services and Trestle Data only in accordance with
              this Agreement and any Orders.&nbsp; You are solely responsible
              for ensuring that your use of the Services and Trestle Data,
              including your provision of Customer Data to Trestle as
              contemplated hereunder, does not violate the laws of the
              applicable jurisdictions in which you do business.&nbsp; Any use
              of the Services in breach of this Section by Customer, its
              Affiliates, or any Users, may result in Trestle’s immediate
              suspension of the Services; however, Trestle will use commercially
              reasonable efforts under the circumstances to provide you with
              notice and an opportunity to remedy a breach prior to suspension.
            </Paragraph>
            <Paragraph>
              3.2 &nbsp; Your Privacy Policy. You will not collect, nor provide
              to Trestle, any Customer Data that is not collected or stored in
              accordance with applicable law and your privacy policy (or the
              privacy policy of your customers, if applicable).&nbsp; You will
              ensure that your privacy policy is readily accessible to anyone
              from whom you collect data and, if required by applicable law,
              provide individuals with the ability to exercise rights applicable
              to their personal information, such as opting-out of disclosure of
              their personal information by you. You are solely responsible for
              obtaining any consents required in connection with your provision
              of Customer Data to Trestle for the purposes described in this
              Agreement.
            </Paragraph>
            <Paragraph>3.3 &nbsp; Restrictions.</Paragraph>
            <Paragraph>
              3.3.1 General Restrictions.&nbsp; Customer will not: (a) make any
              Services or Trestle Data available to anyone other than Customer,
              its Affiliates, and their respective Users; (b) use the Services
              or Trestle Data for the benefit of anyone other than Customer or
              its Affiliates; (c) falsify or alter any unique identifier
              assigned to Customer, or otherwise obscure or alter the source of
              queries to the Services; (d) permit direct or indirect access to
              or use of any Services or Trestle Data in a way that circumvents
              any usage limits; (e) reproduce, modify, distribute, disassemble,
              reverse engineer or create derivative works based on any portion
              of the Services; (f) violate or attempt to violate the security of
              the Services, or introduce any malicious code into the Services;
              (g) use the Services or Trestle Data to build a competitive
              product or service; or (h) submit Customer Data to the Services
              that contain any information deemed “sensitive” under applicable
              law (such as government identification numbers, financial account
              information, or information related to children).
            </Paragraph>
            <Paragraph>
              3.3.2 Data Use Restrictions.&nbsp; Customer will use the Trestle
              Data for &nbsp;identity verification, lead verification, identity
              management, and caller identification,&nbsp; routing, and
              prioritization use cases, and will not:&nbsp; (a) use the Trestle
              Data for marketing purposes except to respond to an inbound
              request; (b) use the Trestle Data in violation of any applicable
              law, rule, or regulation or in violation of any third-party right;
              (c) store the Trestle Data for purposes other than Customer’s own
              internal business purposes; (d) use the Trestle Data in
              conjunction with illicit activities; (e) store or cache the
              Trestle Data to avoid making additional queries to the Services;
              (f) merge stored Trestle Data with other data unless it is coded
              or tagged to indicate Trestle as its source; or (g) use the
              Trestle Data for file download in a fixed page format (i.e.,
              create a printable, downloadable directory of personal
              information).
            </Paragraph>
            <Paragraph>
              You acknowledge that the Service is not provided by a “consumer
              reporting agency” as that term is defined in the Fair Credit
              Reporting Act (“FCRA”) and the Trestle Data do not constitute
              “consumer reports” as defined in the FCRA.&nbsp; Accordingly, the
              Trestle Data may not be used as a factor in determining
              eligibility for credit, insurance, employment or any other purpose
              in which a consumer report may be used under the FCRA (or any
              similar consumer credit law in the United States of America or
              otherwise).
            </Paragraph>
            <Paragraph>
              3.4 &nbsp; &nbsp; Orders and Accounts.&nbsp; In order to access
              the Services and Trestle Data, you must enter into an Order or
              create a user account.&nbsp; You agree to pay us for the Services
              that you order through your account or as stated on an
              Order.&nbsp; Your Users may also be required to create
              accounts.&nbsp; You agree that the account information you provide
              will be accurate and complete at the time you provide it and you
              will maintain and update such information to keep it accurate and
              complete.&nbsp; We may contact you and require confirmation of
              your account information before you use or continue to use the
              Services.&nbsp; We reserve the right to terminate any licenses
              granted hereunder and to refuse to provide you access to the
              Services if, in our sole discretion, we determine that any of your
              account information is, or appears to be, inaccurate, incomplete,
              or fraudulent.
            </Paragraph>
            <Paragraph>
              3.4.1 Per Query and Per Seat. If you sign up for a Purchased
              Service sold on a per query basis, you will be charged either for
              the monthly minimum number of queries to which you agreed or the
              actual number of queries performed, whichever is greater.&nbsp; If
              you sign up for a Purchased Service sold on a per seat basis, you
              will instead be charged for the number of seat licenses issued to
              your account during the applicable billing period, prorated for
              partial periods.&nbsp; NOTE: Trestle may limit or suspend accounts
              of customers who have purchased seat licenses whose accounts are
              used to submit queries to the Services with a velocity that is
              inconsistent, in Trestle’s sole discretion, with the typical query
              velocity of a single, human user.
            </Paragraph>
            <ol id="4">
              <li>Proprietary Rights.</li>
            </ol>
            <Paragraph>4.1 &nbsp; Customer Data</Paragraph>
            <Paragraph>
              4.1.1 Ownership of Customer Data.&nbsp; As between Trestle and
              you, you own and retain all right, title, and interest in and to
              your Customer Data.&nbsp; You grant to us a limited, royalty-free,
              non-exclusive, worldwide right and license to store, access, use,
              copy, and create derivative works from the Customer Data, but only
              as set forth in this Agreement.
            </Paragraph>
            <Paragraph>
              4.1.2 Use of Customer Data.&nbsp; You agree that we may analyze
              and store Customer Data for as long as reasonably necessary (but
              not longer than three years) to provide identity verification and
              fraud detection services through the Services.&nbsp; We store
              Customer Data in a securely encrypted format to enable us to bill
              for the Services, to provide customer service to you, and to apply
              machine learning techniques that help us identify patterns in the
              data.&nbsp; When we apply machine learning to Customer Data, these
              data (a) will be pseudonymized through cryptographic hashing, (b)
              will be aggregated with data from other customers of the Services,
              and (c) will not be made available to any other customer.&nbsp;
              For clarity, no Metadata provided through the Services includes
              any identifiers that indicate that you are a source of Metadata.
            </Paragraph>
            <Paragraph>4.2 &nbsp; Trestle Data and the Services</Paragraph>
            <Paragraph>
              4.2.1 As between Trestle and you, Trestle owns all rights, title
              and interest in and to the Services, the Trestle Data, and
              Metadata, and any enhancements or modifications thereto.&nbsp; No
              rights are granted to you hereunder other than as expressly set
              forth herein.&nbsp; Subject to your compliance with all of the
              terms and conditions of this Agreement, and in consideration of
              your promises reflected herein and your payment of related fees
              and taxes, Trestle grants to you a limited, royalty-free,
              non-exclusive, worldwide right and license to access and use the
              Services and the Trestle Data, as set forth in this Agreement and
              any Orders, for the duration of this Agreement.
            </Paragraph>
            <Paragraph>
              4.2.2 Feedback. You grant Trestle a worldwide, perpetual,
              irrevocable, royalty-free license to use and incorporate into our
              services any suggestion, enhancement request, or other feedback
              provided by you or Users relating to the Services.
            </Paragraph>
            <ol id="5">
              <li>Fees and Payment</li>
            </ol>
            <Paragraph>
              5.1 &nbsp; Fees.&nbsp; Unless otherwise agreed in a signed
              writing, you will pay all fees specified in any Order.&nbsp;
              Except as otherwise specified herein or in an Order, payment
              obligations are non-cancelable and fees paid are non-refundable,
              and minimum monthly or annual commitments that you purchase cannot
              be decreased during the relevant term, or rolled into any future
              time period.
            </Paragraph>
            <Paragraph>
              5.2 &nbsp; Payment.&nbsp; Payment terms will be specified in an
              Order, but fees are generally invoiced in advance on all annual or
              monthly committed amounts.&nbsp; If you provide credit card
              information to us, you authorize us to charge the credit card for
              all Purchased Services listed in the Order for the initial term
              and any renewal terms.&nbsp; To the extent the terms regarding
              fees, payment, contract renewal, and contract term of an Order
              conflict with the terms of this Terms of Service, the Order will
              control.
            </Paragraph>
            <Paragraph>
              5.3 &nbsp; Unauthorized access.&nbsp; In the event of unauthorized
              access of the Services or Trestle Data through your account, you
              should immediately notify us.&nbsp; You will remain responsible
              for all fees incurred through your account until the time you
              notify us of the unauthorized access.&nbsp; You shall not be
              responsible for unauthorized access caused by our gross negligence
              or willful misconduct.&nbsp; Our records will presumptively
              determine your usage of the Services and fees due hereunder, and
              we reserve the right to issue refunds or credits at our sole
              discretion.&nbsp; If we issue a refund or credit, we are under no
              obligation to issue the same or similar refund in the future.
            </Paragraph>
            <Paragraph>
              5.3 &nbsp; Taxes.&nbsp; Our stated fees do not include any taxes,
              duties or other governmental assessments, including, for example,
              value-added, sales, use or withholding taxes, that are assessable
              by any jurisdiction whatsoever (collectively, “Taxes”).&nbsp; You
              are responsible for paying all Taxes associated with your
              purchases. If we have a legal obligation to pay or collect Taxes
              for which you are responsible, we will invoice you and you will
              pay that amount unless you provide us with a valid tax exemption
              certificate.&nbsp; Notwithstanding the foregoing, we are solely
              responsible for taxes assessable against us based on our income
              and employees.
            </Paragraph>
            <Paragraph>
              5.4 &nbsp; Errors.&nbsp; If a price stated on an Order is
              determined by us, in our sole discretion, to be in error, we are
              not under any obligation to offer you access to the Services at
              that price.&nbsp; We will notify you of the error and give you the
              opportunity to cancel your order and obtain a refund if payment
              has already been made.&nbsp; If you believe someone else has used
              your account or you are being charged for a product you do not
              have, please contact your account representative or
              support@trestleiq.com.
            </Paragraph>
            <Paragraph>
              5.5 &nbsp; Overdue Charges.&nbsp; We may charge late interest on
              any unpaid amount due at the rate of one and one half percent
              (1.5%) of the outstanding balance per month, or at the maximum
              rate permitted by law, whichever is lower, from the date such
              payment was due until the date it is paid.&nbsp; You will be
              responsible for all costs we incur in connection with the
              collection of unpaid amounts, including court costs, attorneys’
              fees, collection agency fees and any other associated costs.
            </Paragraph>
            <Paragraph>
              5.6 &nbsp; Suspension and Payment Disputes.&nbsp; If you owe any
              fees under this Agreement that are more than 45 days overdue, we
              may, without limiting our other rights and remedies, accelerate
              your unpaid fee obligations so that all obligations under your
              Agreement immediately become due and payable.&nbsp; We may also
              suspend Services until such amounts are paid in full; however, we
              will give you at least 10 days’ prior notice before suspending the
              Services, unless you pay by credit card or direct debit and your
              payment has been declined.&nbsp; We will not exercise our late
              payment rights, however, if you are disputing the charges
              reasonably and in good faith and are cooperating diligently to
              resolve the dispute.
            </Paragraph>
            <Paragraph>
              5.7 &nbsp; Purchases through Third Parties.&nbsp; If you have
              purchased the Services through a third party, this Section 5 may
              not apply to you. We are not liable to you for any claims related
              to purchases made through third parties, so please contact the
              third party directly.
            </Paragraph>
            <ol id="6">
              <li>Term and Termination.</li>
            </ol>
            <Paragraph>
              6.1 &nbsp; Term Commencement.&nbsp; This Agreement commences on
              the date you first accept it (by using or gaining access to the
              Services), and continues so long as Trestle is providing the
              Services to you.&nbsp; Each Order will identify the initial
              subscription period for the applicable Services.
            </Paragraph>
            <Paragraph>
              6.2 &nbsp; Term and Renewal.&nbsp; Unless otherwise provided in
              your Order, you agree that you have purchased the Purchased
              Services and access to Trestle Data on an annual (12-month)
              commitment.&nbsp; Unless otherwise provided in your Order, your
              Order(s) will renew annually thereafter for 12-month periods
              unless one of us provides the other with written notice of
              termination at least 30 days prior to the end of the annual
              term.&nbsp; After the initial term, if Trestle’s pricing
              increases, Trestle will give you at least 60 days prior notice of
              the planned increase, and increases will take effect at renewal.
            </Paragraph>
            <Paragraph>
              6.3 &nbsp; Termination for Cause.&nbsp; Either one of us may
              terminate this Agreement for cause if the other party (i)
              materially breaches this Agreement, and has not cured the breach
              within 30 days of receiving written notice from the other party,
              or (ii)&nbsp; becomes the subject of a petition in bankruptcy or
              any other proceeding relating to insolvency, receivership,
              liquidation or assignment for the benefit of creditors or
              cancellation or readjustment of substantially all of its
              obligations.
            </Paragraph>
            <Paragraph>
              6.4 &nbsp; Payment on Termination.&nbsp; If you terminate this
              Agreement in accordance with Section 6.3(i) above, Trestle will
              refund you any prepaid fees covering the remainder of the term of
              your Order(s) after the effective date of termination.&nbsp; If
              this Agreement is terminated by us in accordance with the same
              section, you will pay any unpaid fees covering the remainder of
              the term of all Orders to the extent permitted by applicable
              law.&nbsp; In no event will termination relieve Customer of its
              obligation to pay any fees payable to Trestle for the period prior
              to the effective date of termination.
            </Paragraph>
            <Paragraph>
              6.5 &nbsp; Surviving Provisions.&nbsp; The sections titled “Fees
              and Payment,” “Proprietary Rights,” “Payment on Termination,”
              “Surviving Provisions,” “Confidentiality,” “Disclaimers,”
              “Limitation of Liability,” “Indemnification,” and “Miscellaneous”
              will survive any termination or expiration of this Agreement, and
              the section titled “Privacy and Data Protection” will survive any
              termination or expiration of this Agreement for so long as Trestle
              retains possession of Customer Data.
            </Paragraph>
            <ol id="7">
              <li>Confidentiality.</li>
            </ol>
            <Paragraph>
              7.1 &nbsp; Definition of Confidential Information. “Confidential
              Information” means all information disclosed by a party
              (“Disclosing Party”) to the other party (“Receiving Party”),
              whether orally or in writing, that is designated as confidential
              or that reasonably should be understood to be confidential given
              the nature of the information and the circumstances of disclosure.
              Confidential Information of Customer includes Customer Data;
              Confidential Information of Trestle includes the Services, Trestle
              Data, Metadata, and the terms and conditions of this Agreement and
              all Orders.
            </Paragraph>
            <Paragraph>
              Confidential Information does not include any information that (i)
              is or becomes generally known to the public without the Receiving
              Party’s breach of any obligation hereunder, (ii) was known to the
              Receiving Party prior to its disclosure by the Disclosing Party
              without breach of any obligation owed to the Disclosing Party,
              (iii) is received from a third party without breach of any
              obligation owed to the Disclosing Party, or (iv) was independently
              developed by the Receiving Party.
            </Paragraph>
            <Paragraph>
              7.2 &nbsp; Protection of Confidential Information.&nbsp; The
              Receiving Party will use the same degree of care that it uses to
              protect the confidentiality of its own confidential information of
              similar kind, but not less than reasonable care, and will not use
              any Confidential Information of the Disclosing Party for any
              purpose outside the scope of this Agreement.&nbsp; Except as
              otherwise authorized by the Disclosing Party in writing, the
              Receiving Party will limit access to Confidential Information of
              the Disclosing Party to those employees, contractors and agents
              who need access for purposes consistent with this Agreement.&nbsp;
              Neither party will disclose the terms of this Agreement or any
              Order to any third party other than its Affiliates (who are bound
              by all obligations hereunder), and legal counsel and accountants
              who are bound by professional obligations of confidentiality.
            </Paragraph>
            <Paragraph>
              7.3 &nbsp; Compelled Disclosure. The Receiving Party may disclose
              Confidential Information of the Disclosing Party if compelled by
              law or regulation, provided the Receiving Party gives the
              Disclosing Party prior notice of the compelled disclosure (to the
              extent legally permitted) and reasonable assistance, at the
              Disclosing Party’s cost, if the Disclosing Party wishes to contest
              the disclosure. If the Receiving Party is compelled by law to
              disclose the Disclosing Party’s Confidential Information as part
              of a civil proceeding to which the Disclosing Party is a party,
              and the Disclosing Party is not contesting the disclosure, the
              Disclosing Party will reimburse the Receiving Party for its
              reasonable cost of compiling and providing secure access to that
              Confidential Information.
            </Paragraph>
            <ol id="8">
              <li>Representations and Warranties.</li>
            </ol>
            <Paragraph>
              8.1 &nbsp; Representations.&nbsp; Each party represents that it
              has validly entered into this Agreement and has the legal power to
              do so.
            </Paragraph>
            <Paragraph>
              8.2 &nbsp; Trestle Warranties.&nbsp; During the term of this
              Agreement, we will not materially decrease the overall security of
              the Services or the overall functionality of the Services
              purchased by you in any Order.&nbsp; For any breach of the
              warranties contained in this section 8.2, your exclusive remedies
              are those described in the “Termination for Cause” and “Payment on
              Termination” sections.
            </Paragraph>
            <Paragraph>
              8.3 &nbsp; Disclaimers.&nbsp; Trestle does not represent or
              endorse the accuracy, completeness, or reliability of any Trestle
              Data or other information displayed, uploaded, or distributed
              through the Services.&nbsp; Except as expressly provided herein,
              the Trestle Data and Services are distributed “as is” and “as
              available” without warranties of any kind.
            </Paragraph>
            <Paragraph>
              EXCEPT AS EXPRESSLY PROVIDED HEREIN, TO THE FULLEST EXTENT
              PERMISSIBLE UNDER APPLICABLE LAW, TRESTLE AND ITS AFFILIATES AND
              LICENSORS DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS,
              IMPLIED, STATUTORY OR OTHERWISE, AND SPECIFICALLY DISCLAIM ALL
              IMPLIED WARRANTIES, INCLUDING IMPLIED WARRANTIES OF
              NON-INFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE, OR THOSE ARISING FROM A PARTICULAR COURSE OF DEALING OR
              USAGE OR TRADE.&nbsp; NO INFORMATION OR ADVICE PROVIDED BY
              TRESTLE, ITS AFFILIATES, OR BY ITS EMPLOYEES, CONTRACTORS OR
              AGENTS, SHALL CREATE ANY WARRANTY NOT EXPRESSLY PROVIDED
              HEREUNDER.
            </Paragraph>
            <ol id="9">
              <li>Indemnification and Defense.</li>
            </ol>
            <Paragraph>
              9.1 &nbsp; Indemnification by Us.&nbsp; We will defend you and
              your Affiliates against any claim, demand, or proceeding made or
              brought against you by a third party alleging that the Services
              infringe such third party’s patent, copyright, mask work,
              trademark or trade secret (a “Claim”), and will indemnify you from
              any damages, attorney fees and costs finally awarded as a result
              of a Claim, provided that you (a) promptly give us written notice
              of the Claim, (b) give us sole control of the defense and
              settlement of the Claim (except that we may not settle any Claim
              unless it unconditionally releases you from all liability), and
              (c) give us reasonable assistance, at our expense.
            </Paragraph>
            <Paragraph>
              If we receive information about an infringement claim related to
              any of the Services, we may, in our discretion (i) modify the
              Services so that they are no longer claimed to infringe, (ii)
              obtain a license for your continued use of your Purchased Service,
              or (iii) terminate your Order(s) for the allegedly infringing
              Service with 30 days’ prior written notice.&nbsp; We will refund
              you any prepaid fees covering the remainder of the term of all
              affected Orders.
            </Paragraph>
            <Paragraph>
              These defense and indemnification obligations do not apply if (1)
              a Claim arises from the use or combination of the Services with
              software, hardware, data, or processes not provided by Trestle, if
              the Services or use thereof would not infringe without such
              combination; (2) a Claim arises from Services under an Order for
              which there is no charge; or (3) a Claim arises from your or your
              Affiliates’ breach of this Agreement.
            </Paragraph>
            <Paragraph>
              9.2 &nbsp; Indemnification by You.&nbsp; You will defend Trestle,
              its Affiliates, and data providers against any claim, demand, or
              proceeding made or brought against us by a third party (a)
              alleging that any Customer Data or your use of Customer Data with
              the Services infringes or misappropriates such third party’s
              intellectual property rights, or (b) arising from Customer’s use
              of the Services or Trestle Data in an unlawful manner or in
              violation of the Agreement or Order (a “Claim Against Trestle”),
              and will indemnify us from any damages, costs and attorney fees
              finally awarded as a result of a Claim Against Trestle, provided
              that Trestle (c) promptly gives you written notice of the Claim
              Against Trestle, (d) gives you sole control of the defense and
              settlement of the Claim Against Trestle (except that you may not
              settle any Claim Against Trestle unless it unconditionally
              releases us from all liability), and (e) gives you reasonable
              assistance, at your expense.&nbsp; Customer’s defense and
              indemnification obligations do not apply if a Claim Against
              Trestle arises from Trestle’s breach of this Agreement or any
              Orders.
            </Paragraph>
            <Paragraph>
              9.3 &nbsp; Exclusive Remedy.&nbsp; This Section 9 states the
              indemnifying party’s sole liability to, and the indemnified
              party’s exclusive remedy against, the other party for any
              third-party claim described in this section.
            </Paragraph>
            <ol id="10">
              <li>Limitation of Liability.</li>
            </ol>
            <Paragraph>
              10.1 Limitation of Liability.&nbsp; IN NO EVENT SHALL THE
              AGGREGATE LIABILITY OF EITHER PARTY ARISING OUT OF OR RELATED TO
              THIS AGREEMENT EXCEED THE TOTAL AMOUNT PAID BY CUSTOMER AND ITS
              AFFILIATES FOR THE SERVICES GIVING RISE TO THE LIABILITY IN THE
              TWELVE MONTHS PRECEDING THE FIRST INCIDENT OUT OF WHICH THE
              LIABILITY AROSE.&nbsp; THE FOREGOING LIMITATION WILL APPLY WHETHER
              AN ACTION IS IN CONTRACT OR TORT AND REGARDLESS OF THE THEORY OF
              LIABILITY, BUT WILL NOT LIMIT CUSTOMER’S AND ITS AFFILIATES’
              PAYMENT OBLIGATIONS UNDER THE “FEES AND PAYMENT” SECTION.
            </Paragraph>
            <Paragraph>
              10.2 No Consequential Damages.&nbsp; TO THE MAXIMUM EXTENT
              PERMITTED BY APPLICABLE LAW, NEITHER PARTY SHALL BE LIABLE TO THE
              OTHER FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL,
              BUSINESS INTERRUPTION, OR PUNITIVE DAMAGES, OR ANY LOSS OF PROFITS
              OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS
              OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, EVEN IF A
              PARTY OR ITS AFFILIATES HAVE BEEN ADVISED OF THE POSSIBILITY OF
              SUCH DAMAGES, OR IF A PARTY’S OR ITS AFFILIATES’ REMEDY OTHERWISE
              FAILS OF ITS ESSENTIAL PURPOSE.
            </Paragraph>
            <ol id="11">
              <li>Modifications.</li>
            </ol>
            <Paragraph>
              11.1 Trestle Services.&nbsp; We may change, suspend or discontinue
              any aspect of our products and services at any time, including the
              availability of any feature, database, or content. If a change
              would have a material adverse effect on your use of the Services,
              we will provide you at least 30 days’ notice, and if you disagree
              with the change, your exclusive remedy is to terminate the
              Agreement by providing ten business days’ written notice to us,
              and to stop using Trestle’s products and services.
            </Paragraph>
            <Paragraph>
              11.2 Terms of Service.&nbsp; We may modify these terms from time
              to time, and the modified version will replace the prior
              versions.&nbsp; We will use commercially reasonable efforts to
              provide you with advance notice of any material changes via email,
              by posting a notice on www.trestle.devel, or through messaging
              provided to logged-in Users.&nbsp; We will post the most current
              version on the Trestle web site, and the changes are effective on
              the date we post the new Agreement.&nbsp; It is your
              responsibility to review these terms prior to each use.&nbsp; If
              the modifications of these terms negatively impact you, and you
              disagree with the modifications, your exclusive remedy is to
              terminate your Agreement, including all Orders, by providing at
              least five business days’ written notice to us.&nbsp; You must
              provide this notice within 60 days of the time Trestle posts the
              modified Terms of Service.&nbsp; Your continued use of our
              products and services will be subject to the modified terms.
            </Paragraph>
            <ol id="12">
              <li>Miscellaneous.</li>
            </ol>
            <Paragraph>
              12.1. Notices.&nbsp; We may provide you notice of changes or any
              other matter by displaying notices to you generally through the
              Trestle web site or customer portal or, at our option, by using
              any contact information you have provided to us.&nbsp; You agree
              that notice by the foregoing means shall be deemed complete when
              posted by Trestle or transmitted to you.&nbsp; All legal notices
              to Trestle shall be delivered in writing and must be sent by
              either overnight courier or certified mail, return receipt
              requested to:
            </Paragraph>
            <Paragraph>Trestle, Inc.</Paragraph>
            <Paragraph>Attn: Legal Department</Paragraph>
            <Paragraph>12819 SE 38th St #263</Paragraph>
            <Paragraph>Bellevue, WA 98006</Paragraph>
            <Paragraph>
              with a copy (which shall not constitute notice) to:&nbsp;
              legal@trestleiq.com.
            </Paragraph>
            <Paragraph>
              12.2 Time Limitation on Claims and Causes of Action. You agree
              that regardless of any statute or law to the contrary, any claim
              or cause of action arising out of or related to this Agreement or
              your use of the Services and Trestle Data must be filed within one
              year after such claim or cause of action arose. Any claim not
              filed within the foregoing one-year period shall be forever
              barred.
            </Paragraph>
            <Paragraph>
              12.3 Governing Law, Venue. This Agreement and your use of the
              Services and Trestle Data shall be governed by and construed in
              accordance with, the laws of the state of Washington as they apply
              to agreements made and solely performed therein.&nbsp; You
              irrevocably consent and waive all objections to personal
              jurisdiction and venue in the state and federal courts located in
              King County, Washington, USA for any action arising out of or
              relating to this Agreement, Trestle, the Services, and the Trestle
              Data.
            </Paragraph>
            <Paragraph>
              12.4 Third-Party Beneficiaries.&nbsp; Except when Trestle’s data
              providers are specifically referenced, there are no third-party
              beneficiaries under this Agreement.
            </Paragraph>
            <Paragraph>
              12.5&nbsp; Confirmation of Compliance.&nbsp; If Trestle has
              knowledge or a reasonable suspicion of your or your Affiliates’
              non-compliance with this Agreement, we (or our authorized
              representative) may request information from you to confirm your
              compliance.&nbsp; This information may include, but not be limited
              to, architecture and data flow diagrams, copies of processing
              logs, and interviews with relevant engineers and
              stakeholders.&nbsp; You will promptly, but in no less than ten
              business days, provide us with all information we reasonably
              request, and will make available via telephone or video
              conferencing appropriate personnel in furtherance of these
              requests.&nbsp; We will take reasonable measures to ensure that
              our requests do not unreasonably disrupt your business
              operations.&nbsp; We will each bear our own costs with respect to
              any informational requests conducted hereunder.
            </Paragraph>
            <Paragraph>
              12.6&nbsp; Waiver, Severability and Assignment. Trestle’s failure
              to enforce a provision is not a waiver of its right to do so
              later. If any provision of this Agreement is held to be
              unenforceable for any reason, such provision will be reformed only
              to the extent necessary to make it enforceable, and such decision
              will not affect the enforceability of such provision under other
              circumstances, or of the remaining provisions hereof under all
              circumstances. You may not assign any of your rights under these
              Terms of Service without our written consent.&nbsp; We may assign
              our rights to our Affiliates, or in connection with a merger,
              acquisition, corporate reorganization, or sale of all or
              substantially all of our assets.
            </Paragraph>
            <Paragraph>
              12.7 No Agency. The parties are independent contractors.&nbsp;
              Nothing herein will be construed to create a partnership, joint
              venture or any type of agency relationship between Trestle and
              you.
            </Paragraph>
            <Paragraph>
              12.8 Entire Agreement and Order of Precedence. This Agreement is
              the entire agreement between Trestle and you regarding your use of
              the Services and Trestle Data and supersedes all prior and
              contemporaneous agreements, proposals or representations, written
              or oral, concerning its subject matter. The parties agree that any
              term or condition stated in any order documentation, excluding an
              Order, is void. In the event of any conflict or inconsistency
              between this Agreement and an Order, the Order shall
              control.&nbsp; Titles and headings of sections of this Agreement
              are for convenience only and do not affect the construction of any
              provision of this Agreement.
            </Paragraph>
            <Paragraph>
              THESE TERMS OF SERVICE ARE EFFECTIVE FOR ALL ORDERS ENTERED INTO
              ON OR AFTER THE DATE FIRST REFERENCED ABOVE.
            </Paragraph>
          </div>
        </div>
      </div>
    </div>
  );
}

const Paragraph = styled.p`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export default Terms;
