import { useEffect, useState } from "react";
import ComponentDrawer from "../../Shared/ComponentDrawer/componentDrawer";
import { useAppSelector } from "../../../app/hooks";
import WaringPopup from "../../Shared/WarningPopup";

interface UserDrawerProps {
  userName: string;
  userData: any;
}

function UserDrawer({ userName, userData }: UserDrawerProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const { isUpdated } = useAppSelector(
    (state: any) => state.userDetailsDrawerReducer
  );

  const handleDrawerClose = () => {
    if (isUpdated) {
      setOpenPopup(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <div>
      <div
        className="w-fit cursor-pointer text-[#0494B4] text-base font-medium"
        style={{
          maxWidth: "160px",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        {userName}
      </div>
      <ComponentDrawer
        open={open}
        close={handleDrawerClose}
        anchor="right"
        children={null}
        userName={userName}
        userData={userData}
        width={"75%"}
        closeOnBackdropClick={true}
      />
      <WaringPopup
        open={openPopup}
        close={() => {
          setOpenPopup(false);
        }}
        title={"Changes"}
        text={"Are you sure you want to close without saving changes?"}
        buttonLable={"Yes, close without saving"}
        onAction={() => {
          setOpenPopup(false);
          setOpen(false);
        }}
      />
    </div>
  );
}

export default UserDrawer;
