import React, { useEffect, useState } from "react";
import TrestleButton from "../Shared/TrestleButton";
import DialogPopup from "../Shared/DialogPopup";
import Select from "react-select";
import { HTTP_STATUS } from "../../http/constants/http.status";
import "../Teams/AddMoreUserToTeam/react-select.css";
import { setToast } from "../../app/toastReducer";
import { AppConstants } from "../../constants/AppConstants";
import { getAllMaxioSubscriptionsForCustomer } from "../../http/admin-service";
import { useAppDispatch } from "../../app/hooks";

interface FindMaxioSubscriptionIDsProps {
  open: boolean;
  setPopup: any;
  setIsLoading?: any;
  maxioSubscriptionData?: any;
  userData: any;
  setMaxioSubscriptionsData: any;
  currentProduct: any;
  setCurrentProduct: any;
  updateSubscription: (product: any, subscription: any) => void; // Add this prop
}

function FindMaxioSubscriptionIDs({
  open,
  setPopup,
  setIsLoading = () => {},
  maxioSubscriptionData = {},
  userData,
  currentProduct,
  setMaxioSubscriptionsData,
  setCurrentProduct,
  updateSubscription, // Destructure this prop
}: FindMaxioSubscriptionIDsProps) {
  const dispatch = useAppDispatch();

  const [selectedSubscriptions, setSelectedSubscriptions] = useState<any>(null);
  const [subscriptionList, setSubscriptionList] = useState<any>([]);

  useEffect(() => {
    setSelectedSubscriptions(null);
    getAllMaxioSubscriptionsApiCall();
  }, []);

  const getAllMaxioSubscriptionsApiCall = async () => {
    setIsLoading(true);
    const response = await getAllMaxioSubscriptionsForCustomer(
      userData.maxioCustomerId
    );
    setIsLoading(false);

    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      setSubscriptionList(formatSubscriptionsForReactSelect(data.data));
    } else {
      const { details } = data;
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: details,
        })
      );
    }
  };

  const selectMaxioSubscription = () => {
    if (!selectedSubscriptions) return;

    const { id, componentName, value } = selectedSubscriptions;
    const [maxioSubscriptionId, componentId, pricePointId] = value.split("|");

    const subscription = {
      productName: currentProduct.productName,
      maxioSubscriptionId,
      componentId,
      pricePointId,
    };

    // Use the updateSubscription function to update the parent component's state
    updateSubscription(currentProduct, subscription);
    setPopup(false);
  };

  const formatSubscriptionsForReactSelect = (arr: any) => {
    return arr.map((subscription: any) => ({
      label: `${subscription.componentName} (${subscription.componentId})`,
      value: `${subscription.maxioSubscriptionId}|${subscription.componentId}|${subscription.pricePointId}`,
      id: currentProduct.productName,
    }));
  };

  return (
    <DialogPopup
      open={!!open}
      onClose={(val: any) => {
        setPopup(true);
      }}
      closeButton={true}
      dialogStyleClass="dialogFindMaxioCustomer"
      title={""}
      className="!p-0 w-[700px]"
      component={
        <div>
          <div className="w-full py-8 px-10 bg-[#F3F5F6] text-[#163545] mb-6">
            <div className="text-xl font-bold flex">
              Find/Refresh Maxio Components
            </div>
          </div>
          <div className="p-10 pt-0 w-full text-lg">
            <div>
              {open && (
                <div className="mb-10">
                  Please choose the appropriate Maxio component for the selected
                  product
                </div>
              )}
            </div>

            <div>
              <div className="w-full mr-2">
                <Select
                  options={subscriptionList}
                  placeholder="Select Subscription"
                  value={selectedSubscriptions}
                  onChange={(e: any) => setSelectedSubscriptions(e)}
                  className="select-dropdown"
                />
              </div>
            </div>

            <div className={"mt-40 mb-8 text-base"}></div>

            <div className="flex">
              <TrestleButton
                onClick={selectMaxioSubscription}
                label="Select"
                type="primary"
                className="max-w-[180px] !h-[40px] !py-0 !pt-0 ml-auto mr-auto"
                disabled={!selectedSubscriptions}
              />
              <TrestleButton
                onClick={() => {
                  setPopup(false);
                }}
                label="Cancel"
                type="secondry"
                className="max-w-[180px] !h-[40px] !py-0 !pt-0 mr-auto"
              />
            </div>
          </div>
        </div>
      }
      closeOnOut={true}
    />
  );
}

export default FindMaxioSubscriptionIDs;
