import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Box, Stack } from "@mui/material";
import { Text14pxMedium, Text16pxBold } from "../CommonComponents";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const VerticalBarChart = ({ data, title, width, height }: any) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
      },
      title: {
        display: false,
        text: title,
        font: {
          size: 18,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false, // Hide x-axis grid lines
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          drawBorder: false,
          display: true,
        },
        ticks: {
          stepSize: 20,
        },
      },
    },
  };

  return (
    <Box
      width={width ? width : "500px"}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Text16pxBold style={{ fontWeight: 700, marginBottom: "20px" }}>
        {title}
      </Text16pxBold>
      <Bar data={data} options={options} />
      <Stack direction={"row"} spacing={3} mt={"20px"}>
        {data?.datasets?.map((data: any, index: any) => {
          return (
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  background: `${data?.backgroundColor}`,
                }}
                className={`w-[16px] h-[16px]`}
              />
              <Text14pxMedium style={{ lineHeight: "14px" }}>
                {data?.label}
              </Text14pxMedium>
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
};

export default VerticalBarChart;
