import BaseAPIClient from "./api";
import { AppConstants } from "../constants/AppConstants";
import axios from "axios";

const globalHeaders: any = {
  "Content-Type": "application/json",
  "Access-Control-Allow-Origin": "*",
};

const API_BASE_URL: string =
  process.env.REACT_APP_API_URL || "http://localhost";
const API = BaseAPIClient?.axiosInstance;

export const getTeamsUsers = async (teamId: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.GET_TEAM_USERS.replace(
    "{{id}}",
    teamId
  )}`;
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const createTeamInvitation = async (data: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.CREATE_TEAM_INVITATION}`;
  const response = await API.post(uri, data, {
    headers,
  });
  return response;
};

export const getTeamInvitation = async (userId: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.GET_TEAM_INVITE.replace(
    "{{id}}",
    userId
  )}`;
  const response = await API.get(uri, {
    headers,
  });
  return response;
};

export const updateTeamInvitation = async (data: any) => {
  const headers = { ...globalHeaders };
  let uri: string = `${API_BASE_URL}${AppConstants.UPDATE_TEAM_INVITATION}`;
  const response = await API.put(uri, data, {
    headers,
  });
  return response;
};
