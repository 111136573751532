export const getTimeDifference = (endTime: any) => {
    const diffMs = Date.now() - endTime;


    const diffSec = Math.floor(diffMs / 1000);

    if (diffSec < 60) {
      return `${diffSec} second${diffSec === 1 ? '' : 's'}`;
    } else if (diffSec < 3600) {
      const diffMin = Math.floor(diffSec / 60);
      return `${diffMin} minute${diffMin === 1 ? '' : 's'}`;
    } else if (diffSec < 86400) {
      const diffHour = Math.floor(diffSec / 3600);
      return `${diffHour} hour${diffHour === 1 ? '' : 's'}`;
    } else {
      const diffDay = Math.floor(diffSec / 86400);
      return `${diffDay} day${diffDay === 1 ? '' : 's'}`;
    }
  }