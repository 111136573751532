import TrestleLogo from "../../assets/Trestle_Logo_New.svg";
import BestBuyLogo from "../../assets/best_buy_logo1.svg";
import TwilloLogo from "../../assets/twillio_logo.svg";
import SmartAssetLogo from "../../assets/smart_asset_logo.svg";
import ActiveProspectLogo from "../../assets/Active_Prospect_Logo1.svg";
import HiyaLogo from "../../assets/hiya_logo.svg";
import "./shared.css";
import { ConstantTexts } from "../../constants/ConstantTexts";
import LoaderComponent from "./LoaderComponent";
import { CircularTickIcon } from "../../assets/svgs";
import { Divider } from "@mui/material";

export const BrandingPage = (props: any) => {
  return (
    <>
      <LoaderComponent loader={props.loader} />
      <div className="flex-col-reverse flex sm:flex sm:flex-row">
        <div className="w-[100%] min-w-[380px] max-w-[765px] sm:w-[40%]">
          <div className="brandingPatternBg flex sm: min-h-[fit-content]">
            <div className="m-auto flex flex-col gap-[50px] sm:gap-[100px]">
              <img
                src={TrestleLogo}
                alt="trestle logo"
                className="hidden sm:flex w-[266px] h-[48px] mx-auto"
              />
              <div className="flex flex-col flex-wrap content-center">
                {ConstantTexts.BRANDING_PAGE_BULLET_POINTS.map((ele) => (
                  <div className="mt-5 flex gap-[15px] items-center brandingPageSubText">
                    <div className="w-[20px] h-[20px]">{CircularTickIcon}</div>
                    {ele}
                  </div>
                ))}
              </div>
              <div className="flex flex-col gap-[20px] items-center pb-[100px] sm:pb-[0]">
                <div className="brandingPageText">
                  Trestle is trusted by leading enterprise companies{" "}
                  <span className="text-[#0494B4]">serving 300M+ queries</span>{" "}
                  in the US and Canada
                </div>
                <div className="flex gap-[20px] items-center flex-wrap">
                  <img
                    src={BestBuyLogo}
                    alt="trestle logo"
                    className="w-[60px] h-[60px] mx-auto"
                  />
                  <img
                    src={ActiveProspectLogo}
                    alt="trestle logo"
                    className="w-[112px] h-[20px] mx-auto"
                  />
                  <img
                    src={TwilloLogo}
                    alt="trestle logo"
                    className="w-[104px] h-[38px] mx-auto"
                  />
                  <img
                    src={SmartAssetLogo}
                    alt="trestle logo"
                    className="w-[104px] h-[21px] mx-auto"
                  />
                  <img
                    src={HiyaLogo}
                    alt="trestle logo"
                    className="w-[60px] h-[34px] mx-auto"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Divider
          orientation="horizontal"
          color="#E5F4F7"
          sx={{ height: "2px" }}
          className="flex sm:hidden"
        />
        <div className="w-[100%]  flex sm:w-[60%]">{props.children}</div>
        <div className="m-auto py-[30px] flex sm:hidden">
          <img
            src={TrestleLogo}
            alt="trestle logo"
            className="w-[266px] h-[48px] mx-auto"
          />
        </div>
      </div>
    </>
  );
};
