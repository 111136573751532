import { Box, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ComponentLabelHeader, Text16px } from "../Shared/CommonComponents";
import CustomTable from "../Shared/TrestleTableNew";
import VerticalBarChart from "../Shared/VerticalBarChart";
import DonutChart from "../Shared/DonutChart";
import HorizontalBarChart from "../Shared/HorizontalBarChart";
import * as _ from "lodash";

interface ITableAndGraphViewProps {
  title?: string;
  verticalGraphData?: [];
  horizontalGraphData?: [];
  donutChartData?: [];
  tableData?: [];
  showTable?: boolean;
  toggleTable?: () => void;
  toggleGraph?: () => void;
  verticalGraphLabel?: string;
  horizontalGraphLabel?: string;
  donutChartLabel?: string;
}

const TableAndGraphView = ({
  title = "",
  verticalGraphData = [],
  horizontalGraphData = [],
  donutChartData = [],
  tableData = [],
  showTable = false,
  toggleGraph = () => {},
  toggleTable = () => {},
  verticalGraphLabel = "",
  horizontalGraphLabel = "",
  donutChartLabel = "",
}: ITableAndGraphViewProps) => {
  return (
    <Stack spacing={2}>
      <Stack
        spacing={2}
        direction={"row"}
        sx={{ justifyContent: "space-between" }}
      >
        <ComponentLabelHeader>{title}</ComponentLabelHeader>
        <Stack spacing={1} direction={"row"} sx={{ cursor: "pointer" }}>
          <Text16px
            c={showTable ? "#555555" : "#0494B4"}
            style={{
              textDecoration: showTable ? "none" : "underline",
              fontWeight: showTable ? "400" : "600",
            }}
            onClick={toggleTable}
          >
            Data View
          </Text16px>
          <Box className="text-[#555555]">/</Box>
          <Text16px
            c={showTable ? "#0494B4" : "#555555"}
            style={{
              textDecoration: showTable ? "underline" : "none",
              fontWeight: showTable ? "600" : "400",
            }}
            onClick={toggleGraph}
          >
            Graph View
          </Text16px>
        </Stack>
      </Stack>
      {showTable ? (
        <>{tableData && <CustomTable rows={tableData} />}</>
      ) : (
        <Grid container spacing={4}>
          {!_.isEmpty(verticalGraphData) && (
            <Grid
              size={{ md: 12, lg: 6 }}
              alignItems={"baseline"}
              justifyContent={"center"}
              display={"flex"}
              pt={"35px"}
            >
              <VerticalBarChart
                width={"700px"}
                height={"700px"}
                data={verticalGraphData}
                title={verticalGraphLabel}
              />
            </Grid>
          )}
          {!_.isEmpty(donutChartData) && (
            <Grid
              size={{ md: 12, lg: 6 }}
              alignItems={"center"}
              display={"flex"}
              justifyContent={"center"}
            >
              <DonutChart data={donutChartData} title={donutChartLabel} />
            </Grid>
          )}
          {!_.isEmpty(horizontalGraphData) && (
            <Grid
              size={{ md: 12 }}
              alignItems={"baseline"}
              justifyContent={"center"}
              display={"flex"}
              pt={"35px"}
            >
              <HorizontalBarChart
                width={"700px"}
                height={"700px"}
                data={horizontalGraphData}
                title={horizontalGraphLabel}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Stack>
  );
};

export default TableAndGraphView;
