import React from "react";
import * as _ from "lodash";
import { Box } from "@mui/material";
import { Text14pxMedium, Text16px } from "../Shared/CommonComponents";

export const getSummaryData = (leadAssessmentData: any) => {
  const aggregates = leadAssessmentData.reportAnalysisData.aggregates;
  const metrics = leadAssessmentData.reportAnalysisData.metrics;
  const benchmark = leadAssessmentData.benchmarks;
  const productName = leadAssessmentData.productName;

  return [
    {
      details: "Total records",
      absolute: `${aggregates?.TOTAL_RECORDS ? aggregates?.TOTAL_RECORDS : 0}`,
      relative: `${100}%`,
      benchmark: "N/A",
      difference: "N/A",
    },
    {
      details: (
        <Box className="flex flex-col gap-1">
          <Text16px c={"#163545"}>Duplicate records</Text16px>{" "}
          <Text14pxMedium>
            Where phone number is the same as seen on another record
          </Text14pxMedium>
        </Box>
      ),
      title: "duplicate_records",
      absolute: `${
        aggregates?.DUPLICATE_RECORDS ? aggregates?.DUPLICATE_RECORDS : 0
      }`,
      relative: `${
        metrics?.DUPLICATE_RECORDS_PERCENTAGE
          ? metrics?.DUPLICATE_RECORDS_PERCENTAGE.toFixed(1)
          : 0
      }%`,
      benchmark: `${
        benchmark?.DUPLICATE_RECORDS_PERCENTAGE
          ? benchmark?.DUPLICATE_RECORDS_PERCENTAGE.toFixed(1)
          : 0
      }%`,
      difference: `${(
        (metrics?.DUPLICATE_RECORDS_PERCENTAGE
          ? metrics?.DUPLICATE_RECORDS_PERCENTAGE
          : 0) - benchmark?.DUPLICATE_RECORDS_PERCENTAGE
      ).toFixed(1)}%`,
    },
    {
      details: (
        <Box className="flex flex-col gap-1">
          <Text16px c={"#163545"}>Records with missing input</Text16px>{" "}
          <Text14pxMedium>Missing name and/or phone number</Text14pxMedium>
        </Box>
      ),
      title: "missing_input",
      absolute: `${
        aggregates?.MISSING_PHONES ? aggregates?.MISSING_PHONES : 0
      }`,
      relative: `${
        metrics?.MISSING_PHONE_PERCENTAGE
          ? metrics?.MISSING_PHONE_PERCENTAGE.toFixed(1)
          : 0
      }%`,
      benchmark: `${
        benchmark?.MISSING_PHONE_PERCENTAGE
          ? benchmark?.MISSING_PHONE_PERCENTAGE.toFixed(1)
          : 0
      }%`,
      difference: `${(
        (metrics?.MISSING_PHONE_PERCENTAGE
          ? metrics?.MISSING_PHONE_PERCENTAGE
          : 0) - benchmark?.MISSING_PHONE_PERCENTAGE
      ).toFixed(1)}%`,
    },
  ];
};
export const getPhoneData = (leadAssessmentData: any) => {
  const aggregates = leadAssessmentData.reportAnalysisData.aggregates;
  const metrics = leadAssessmentData.reportAnalysisData.metrics;
  const benchmark = leadAssessmentData.benchmarks;
  const productName = leadAssessmentData.productName;

  if (!productName.includes("Phone Intel")) {
    return [
      {
        details: "Records with phone.is_valid = false",
        title: "phone_is_valid",
        absolute: `${
          _.toInteger(aggregates?.INVALID_PHONES)
        }`,
        relative: `${
          metrics?.INVALID_PHONE_PERCENTAGE
            ? metrics?.INVALID_PHONE_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        benchmark: `${
          benchmark?.INVALID_PHONE_PERCENTAGE
            ? benchmark?.INVALID_PHONE_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        difference: `${(
          (metrics?.INVALID_PHONE_PERCENTAGE
            ? metrics?.INVALID_PHONE_PERCENTAGE
            : 0) - benchmark?.INVALID_PHONE_PERCENTAGE
        ).toFixed(1)}%`,
      },
      {
        details: "Records with phone grade A",
        title: "phone_contact_grade_a",
        absolute: `${
          aggregates?.PHONE_CONTACT_GRADE_COUNTS?.A
            ? aggregates?.PHONE_CONTACT_GRADE_COUNTS?.A
            : "0"
        }`,
        relative: `${
          metrics?.PHONE_CONTACT_GRADE_A_PERCENTAGE
            ? metrics?.PHONE_CONTACT_GRADE_A_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        benchmark: `${
          benchmark?.PHONE_CONTACT_GRADE_A_PERCENTAGE
            ? benchmark?.PHONE_CONTACT_GRADE_A_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        difference: `${(
          (metrics?.PHONE_CONTACT_GRADE_A_PERCENTAGE
            ? metrics?.PHONE_CONTACT_GRADE_A_PERCENTAGE
            : 0) - benchmark?.PHONE_CONTACT_GRADE_A_PERCENTAGE
        ).toFixed(1)}%`,
      },
      {
        details: "Records with phone grade B",
        title: "phone_contact_grade_b",
        absolute: `${
          aggregates?.PHONE_CONTACT_GRADE_COUNTS?.B
            ? aggregates?.PHONE_CONTACT_GRADE_COUNTS?.B
            : "0"
        }`,
        relative: `${
          metrics?.PHONE_CONTACT_GRADE_B_PERCENTAGE
            ? metrics?.PHONE_CONTACT_GRADE_B_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        benchmark: `${
          benchmark?.PHONE_CONTACT_GRADE_B_PERCENTAGE
            ? benchmark?.PHONE_CONTACT_GRADE_B_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        difference: `${(
          (metrics?.PHONE_CONTACT_GRADE_B_PERCENTAGE
            ? metrics?.PHONE_CONTACT_GRADE_B_PERCENTAGE
            : 0) - benchmark?.PHONE_CONTACT_GRADE_B_PERCENTAGE
        ).toFixed(1)}%`,
      },
      {
        details: "Records with phone grade C",
        title: "phone_contact_grade_c",
        absolute: `${
          aggregates?.PHONE_CONTACT_GRADE_COUNTS?.C
            ? aggregates?.PHONE_CONTACT_GRADE_COUNTS?.C
            : "0"
        }`,
        relative: `${
          metrics?.PHONE_CONTACT_GRADE_C_PERCENTAGE
            ? metrics?.PHONE_CONTACT_GRADE_C_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        benchmark: `${
          benchmark?.PHONE_CONTACT_GRADE_C_PERCENTAGE
            ? benchmark?.PHONE_CONTACT_GRADE_C_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        difference: `${(
          (metrics?.PHONE_CONTACT_GRADE_C_PERCENTAGE
            ? metrics?.PHONE_CONTACT_GRADE_C_PERCENTAGE
            : 0) - benchmark?.PHONE_CONTACT_GRADE_C_PERCENTAGE
        ).toFixed(1)}%`,
      },
      {
        details: "Records with phone grade D",
        title: "phone_contact_grade_d",
        absolute: `${
          aggregates?.PHONE_CONTACT_GRADE_COUNTS?.D
            ? aggregates.PHONE_CONTACT_GRADE_COUNTS?.D
            : "0"
        }`,
        relative: `${
          metrics?.PHONE_CONTACT_GRADE_D_PERCENTAGE
            ? metrics?.PHONE_CONTACT_GRADE_D_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        benchmark: `${
          benchmark?.PHONE_CONTACT_GRADE_D_PERCENTAGE
            ? benchmark?.PHONE_CONTACT_GRADE_D_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        difference: `${(
          (metrics?.PHONE_CONTACT_GRADE_D_PERCENTAGE
            ? metrics?.PHONE_CONTACT_GRADE_D_PERCENTAGE
            : 0) - benchmark?.PHONE_CONTACT_GRADE_D_PERCENTAGE
        ).toFixed(1)}%`,
      },
      {
        details: "Records with phone grade F",
        title: "phone_contact_grade_f",
        absolute: `${
          aggregates?.PHONE_CONTACT_GRADE_COUNTS?.F
            ? aggregates?.PHONE_CONTACT_GRADE_COUNTS?.F
            : "0"
        }`,
        relative: `${
          metrics?.PHONE_CONTACT_GRADE_F_PERCENTAGE
            ? metrics?.PHONE_CONTACT_GRADE_F_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        benchmark: `${
          benchmark?.PHONE_CONTACT_GRADE_F_PERCENTAGE
            ? benchmark?.PHONE_CONTACT_GRADE_F_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        difference: `${(
          (metrics?.PHONE_CONTACT_GRADE_F_PERCENTAGE
            ? metrics?.PHONE_CONTACT_GRADE_F_PERCENTAGE
            : 0) - benchmark?.PHONE_CONTACT_GRADE_F_PERCENTAGE
        ).toFixed(1)}%`,
      },

      {
        details: "Records with non-fixed VoIPs",
        title: "phone_line_type_non_fixed_voIP",
        absolute: `${
          aggregates?.NONFIXEDVOIP_LINE_TYPES
            ? aggregates?.NONFIXEDVOIP_LINE_TYPES
            : "0"
        }`,
        relative: `${
          metrics?.NONFIXED_VOIP_PERCENTAGE
            ? metrics?.NONFIXED_VOIP_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        benchmark: `${
          benchmark?.NONFIXED_VOIP_PERCENTAGE
            ? benchmark?.NONFIXED_VOIP_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        difference: `${(
          (metrics?.NONFIXED_VOIP_PERCENTAGE
            ? metrics?.NONFIXED_VOIP_PERCENTAGE
            : 0) - benchmark?.NONFIXED_VOIP_PERCENTAGE
        ).toFixed(1)}%`,
      },
      {
        details: (
          <Box className="flex flex-col gap-1">
            <Text16px
              c={"#163545"}
            >{`Records with phone Activity Score of <= 30 or phone.is_valid = false`}</Text16px>{" "}
            <Text14pxMedium>Low or no phone activity</Text14pxMedium>
          </Box>
        ),
        title: "phone_activity_score_less_than_30",
        absolute: `${
          aggregates?.LOW_ACTIVITY_RECORDS
            ? aggregates?.LOW_ACTIVITY_RECORDS
            : "0"
        }`,
        relative: `${
          metrics?.LOW_ACTIVITY_PERCENTAGE
            ? metrics?.LOW_ACTIVITY_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        benchmark: `${
          benchmark?.LOW_ACTIVITY_PERCENTAGE
            ? benchmark?.LOW_ACTIVITY_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        difference: `${(
          (metrics?.LOW_ACTIVITY_PERCENTAGE
            ? metrics?.LOW_ACTIVITY_PERCENTAGE
            : 0) - benchmark?.LOW_ACTIVITY_PERCENTAGE
        ).toFixed(1)}%`,
      },
      {
        details: (
          <Box className="flex flex-col gap-1">
            <Text16px
              c={"#163545"}
            >{`Records with phone Activity Score of >= 70`}</Text16px>{" "}
            <Text14pxMedium>Consistent phone activity</Text14pxMedium>
          </Box>
        ),
        title: "phone_activity_score_less_than_70",
        absolute: `${
          aggregates?.HIGH_ACTIVITY_RECORDS
            ? aggregates?.HIGH_ACTIVITY_RECORDS
            : "0"
        }`,
        relative: `${
          metrics?.HIGH_ACTIVITY_PERCENTAGE
            ? metrics?.HIGH_ACTIVITY_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        benchmark: `${
          benchmark?.HIGH_ACTIVITY_PERCENTAGE
            ? benchmark?.HIGH_ACTIVITY_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        difference: `${(
          (metrics?.HIGH_ACTIVITY_PERCENTAGE
            ? metrics?.HIGH_ACTIVITY_PERCENTAGE
            : 0) - benchmark?.HIGH_ACTIVITY_PERCENTAGE
        ).toFixed(1)}%`,
      },
    ];
  } else {
    return [
      {
        details: "Records with phone.is_valid = false",
        title: "phone_is_valid",
        absolute: `${
          _.toInteger(aggregates?.INVALID_PHONES)
        }`,
        relative: `${
          metrics?.INVALID_PHONE_PERCENTAGE
            ? metrics?.INVALID_PHONE_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        benchmark: `${
          benchmark?.INVALID_PHONE_PERCENTAGE
            ? benchmark?.INVALID_PHONE_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        difference: `${(
          (metrics?.INVALID_PHONE_PERCENTAGE
            ? metrics?.INVALID_PHONE_PERCENTAGE
            : 0) - benchmark?.INVALID_PHONE_PERCENTAGE
        ).toFixed(1)}%`,
      },
      {
        details: "Records with non-fixed VoIPs",
        title: "phone_line_type_non_fixed_voIP",
        absolute: `${
          aggregates?.NONFIXEDVOIP_LINE_TYPES
            ? aggregates?.NONFIXEDVOIP_LINE_TYPES
            : "0"
        }`,
        relative: `${
          metrics?.NONFIXED_VOIP_PERCENTAGE
            ? metrics?.NONFIXED_VOIP_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        benchmark: `${
          benchmark?.NONFIXED_VOIP_PERCENTAGE
            ? benchmark?.NONFIXED_VOIP_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        difference: `${(
          (metrics?.NONFIXED_VOIP_PERCENTAGE
            ? metrics?.NONFIXED_VOIP_PERCENTAGE
            : 0) - benchmark?.NONFIXED_VOIP_PERCENTAGE
        ).toFixed(1)}%`,
      },
      {
        details: (
          <Box className="flex flex-col gap-1">
            <Text16px
              c={"#163545"}
            >{`Records with phone Activity Score of <= 30 or phone.is_valid = false`}</Text16px>{" "}
            <Text14pxMedium>Low or no phone activity</Text14pxMedium>
          </Box>
        ),
        title: "phone_activity_score_less_than_30",
        absolute: `${
          aggregates?.LOW_ACTIVITY_RECORDS
            ? aggregates?.LOW_ACTIVITY_RECORDS
            : "0"
        }`,
        relative: `${
          metrics?.LOW_ACTIVITY_PERCENTAGE
            ? metrics?.LOW_ACTIVITY_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        benchmark: `${
          benchmark?.LOW_ACTIVITY_PERCENTAGE
            ? benchmark?.LOW_ACTIVITY_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        difference: `${(
          (metrics?.LOW_ACTIVITY_PERCENTAGE
            ? metrics?.LOW_ACTIVITY_PERCENTAGE
            : 0) - benchmark?.LOW_ACTIVITY_PERCENTAGE
        ).toFixed(1)}%`,
      },
      {
        details: (
          <Box className="flex flex-col gap-1">
            <Text16px
              c={"#163545"}
            >{`Records with phone Activity Score of >= 70`}</Text16px>{" "}
            <Text14pxMedium>Consistent phone activity</Text14pxMedium>
          </Box>
        ),
        title: "phone_activity_score_less_than_70",
        absolute: `${
          aggregates?.HIGH_ACTIVITY_RECORDS
            ? aggregates?.HIGH_ACTIVITY_RECORDS
            : "0"
        }`,
        relative: `${
          metrics?.HIGH_ACTIVITY_PERCENTAGE
            ? metrics?.HIGH_ACTIVITY_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        benchmark: `${
          benchmark?.HIGH_ACTIVITY_PERCENTAGE
            ? benchmark?.HIGH_ACTIVITY_PERCENTAGE.toFixed(1)
            : "0"
        }%`,
        difference: `${(
          (metrics?.HIGH_ACTIVITY_PERCENTAGE
            ? metrics?.HIGH_ACTIVITY_PERCENTAGE
            : 0) - benchmark?.HIGH_ACTIVITY_PERCENTAGE
        ).toFixed(1)}%`,
      },
    ];
  }
};

export const getEmailData = (leadAssessmentData: any) => {
  const aggregates = leadAssessmentData.reportAnalysisData.aggregates;
  const metrics = leadAssessmentData.reportAnalysisData.metrics;
  const benchmark = leadAssessmentData.benchmarks;
  const productName = leadAssessmentData.productName;

  return [
    {
      details: "Records with email.is_valid = false",
      title: "email_is_valid_false",
      absolute: `${aggregates?.INVALID_EMAILS}`,
      relative: `${
        metrics?.EMAIL_INVALID_PERCENTAGE
          ? metrics?.EMAIL_INVALID_PERCENTAGE.toFixed(1)
          : "0"
      }%`,
      benchmark: `${
        benchmark?.EMAIL_INVALID_PERCENTAGE
          ? benchmark?.EMAIL_INVALID_PERCENTAGE.toFixed(1)
          : "0"
      }%`,
      difference: `${(
        (metrics?.EMAIL_INVALID_PERCENTAGE
          ? metrics?.EMAIL_INVALID_PERCENTAGE
          : 0) - benchmark?.EMAIL_INVALID_PERCENTAGE
      ).toFixed(1)}%`,
    },
    {
      details: "Records with email grade A",
      title: "email_contact_grade_a",
      absolute: `${
        aggregates?.EMAIL_GRADE_COUNTS?.A
          ? aggregates?.EMAIL_GRADE_COUNTS?.A
          : "0"
      }`,
      relative: `${
        metrics?.EMAIL_GRADE_A_PERCENTAGE
          ? metrics?.EMAIL_GRADE_A_PERCENTAGE.toFixed(1)
          : "0"
      }%`,
      benchmark: `${
        benchmark?.EMAIL_GRADE_A_PERCENTAGE
          ? benchmark?.EMAIL_GRADE_A_PERCENTAGE.toFixed(1)
          : "0"
      }%`,
      difference: `${(
        (metrics?.EMAIL_GRADE_A_PERCENTAGE
          ? metrics?.EMAIL_GRADE_A_PERCENTAGE
          : 0) - benchmark?.EMAIL_GRADE_A_PERCENTAGE
      ).toFixed(1)}%`,
    },
    {
      details: "Records with email grade B",
      title: "email_contact_grade_b",
      absolute: `${
        aggregates?.EMAIL_GRADE_COUNTS?.B
          ? aggregates?.EMAIL_GRADE_COUNTS?.B
          : "0"
      }`,
      relative: `${
        metrics?.EMAIL_GRADE_B_PERCENTAGE
          ? metrics?.EMAIL_GRADE_B_PERCENTAGE.toFixed(1)
          : "0"
      }%`,
      benchmark: `${
        benchmark?.EMAIL_GRADE_B_PERCENTAGE
          ? benchmark?.EMAIL_GRADE_B_PERCENTAGE.toFixed(1)
          : "0"
      }%`,
      difference: `${(
        (metrics?.EMAIL_GRADE_B_PERCENTAGE
          ? metrics?.EMAIL_GRADE_B_PERCENTAGE
          : 0) - benchmark?.EMAIL_GRADE_B_PERCENTAGE
      ).toFixed(1)}%`,
    },
    {
      details: "Records with email grade C",
      title: "email_contact_grade_c",
      absolute: `${
        aggregates?.EMAIL_GRADE_COUNTS?.C
          ? aggregates?.EMAIL_GRADE_COUNTS?.C
          : "0"
      }`,
      relative: `${
        metrics?.EMAIL_GRADE_C_PERCENTAGE
          ? metrics?.EMAIL_GRADE_C_PERCENTAGE.toFixed(1)
          : "0"
      }%`,
      benchmark: `${
        benchmark?.EMAIL_GRADE_C_PERCENTAGE
          ? benchmark?.EMAIL_GRADE_C_PERCENTAGE.toFixed(1)
          : "0"
      }%`,
      difference: `${(
        (metrics?.EMAIL_GRADE_C_PERCENTAGE
          ? metrics?.EMAIL_GRADE_C_PERCENTAGE
          : 0) - benchmark?.EMAIL_GRADE_C_PERCENTAGE
      ).toFixed(1)}%`,
    },
    {
      details: "Records with email grade D",
      title: "email_contact_grade_d",
      absolute: `${
        aggregates?.EMAIL_GRADE_COUNTS?.D
          ? aggregates?.EMAIL_GRADE_COUNTS?.D
          : "0"
      }`,
      relative: `${
        metrics?.EMAIL_GRADE_D_PERCENTAGE
          ? metrics?.EMAIL_GRADE_D_PERCENTAGE.toFixed(1)
          : "0"
      }%`,
      benchmark: `${
        benchmark?.EMAIL_GRADE_D_PERCENTAGE
          ? benchmark?.EMAIL_GRADE_D_PERCENTAGE.toFixed(1)
          : "0"
      }%`,
      difference: `${(
        (metrics?.EMAIL_GRADE_D_PERCENTAGE
          ? metrics?.EMAIL_GRADE_D_PERCENTAGE
          : 0) - benchmark?.EMAIL_GRADE_D_PERCENTAGE
      ).toFixed(1)}%`,
    },
    {
      details: "Records with email grade F",
      title: "email_contact_grade_f",
      absolute: `${
        aggregates?.EMAIL_GRADE_COUNTS?.F
          ? aggregates?.EMAIL_GRADE_COUNTS?.F
          : "0"
      }`,
      relative: `${
        metrics?.EMAIL_GRADE_F_PERCENTAGE
          ? metrics?.EMAIL_GRADE_F_PERCENTAGE.toFixed(1)
          : "0"
      }%`,
      benchmark: `${
        benchmark?.EMAIL_GRADE_F_PERCENTAGE
          ? benchmark?.EMAIL_GRADE_F_PERCENTAGE.toFixed(1)
          : "0"
      }%`,
      difference: `${(
        (metrics?.EMAIL_GRADE_F_PERCENTAGE
          ? metrics?.EMAIL_GRADE_F_PERCENTAGE
          : 0) - benchmark?.EMAIL_GRADE_F_PERCENTAGE
      ).toFixed(1)}%`,
    },
  ];
};

export const getAddressData = (leadAssessmentData: any) => {
  const aggregates = leadAssessmentData.reportAnalysisData.aggregates;
  const metrics = leadAssessmentData.reportAnalysisData.metrics;
  const benchmark = leadAssessmentData.benchmarks;
  const productName = leadAssessmentData.productName;
  return [
    {
      details: "Records with address.is_valid = false",
      title: "address_is_valid_false",
      absolute: `${
        aggregates?.INVALID_ADDRESSES ? aggregates?.INVALID_ADDRESSES : 0
      }`,
      relative: `${
        metrics?.INVALID_ADDRESS_PERCENTAGE
          ? metrics?.INVALID_ADDRESS_PERCENTAGE.toFixed(1)
          : 0
      }%`,
      benchmark: `${
        benchmark?.INVALID_ADDRESS_PERCENTAGE
          ? benchmark?.INVALID_ADDRESS_PERCENTAGE.toFixed(1)
          : 0
      }%`,
      difference: `${(
        (metrics?.INVALID_ADDRESS_PERCENTAGE
          ? metrics?.INVALID_ADDRESS_PERCENTAGE
          : 0) - benchmark?.INVALID_ADDRESS_PERCENTAGE
      ).toFixed(1)}%`,
    },
    {
      details: "Records with address_name_match = true",
      title: "address_name_match_true",
      absolute: `${
        aggregates?.ADDRESS_NAME_MATCH_TRUE_RECORDS
          ? aggregates?.ADDRESS_NAME_MATCH_TRUE_RECORDS
          : 0
      }`,
      relative: `${
        metrics?.ADDRESS_NAME_MATCH_TRUE_PERCENTAGE
          ? metrics?.ADDRESS_NAME_MATCH_TRUE_PERCENTAGE.toFixed(1)
          : 0
      }%`,
      benchmark: `${
        benchmark?.ADDRESS_NAME_MATCH_TRUE_PERCENTAGE
          ? benchmark?.ADDRESS_NAME_MATCH_TRUE_PERCENTAGE.toFixed(1)
          : 0
      }%`,
      difference: `${(
        (metrics?.ADDRESS_NAME_MATCH_TRUE_PERCENTAGE
          ? metrics?.ADDRESS_NAME_MATCH_TRUE_PERCENTAGE
          : 0) - benchmark?.ADDRESS_NAME_MATCH_TRUE_PERCENTAGE
      ).toFixed(1)}%`,
    },
    {
      details: "Records with address_name_match = false",
      title: "address_name_match_false",
      absolute: `${
        aggregates?.ADDRESS_NAME_MATCH_FALSE_RECORDS
          ? aggregates?.ADDRESS_NAME_MATCH_FALSE_RECORDS
          : 0
      }`,
      relative: `${
        metrics?.ADDRESS_NAME_MATCH_FALSE_PERCENTAGE
          ? metrics?.ADDRESS_NAME_MATCH_FALSE_PERCENTAGE.toFixed(1)
          : 0
      }%`,
      benchmark: `${
        benchmark?.ADDRESS_NAME_MATCH_FALSE_PERCENTAGE
          ? benchmark?.ADDRESS_NAME_MATCH_FALSE_PERCENTAGE.toFixed(1)
          : 0
      }%`,
      difference: `${(
        (metrics?.ADDRESS_NAME_MATCH_FALSE_PERCENTAGE
          ? metrics?.ADDRESS_NAME_MATCH_FALSE_PERCENTAGE
          : 0) - benchmark?.ADDRESS_NAME_MATCH_FALSE_PERCENTAGE
      ).toFixed(1)}%`,
    },
  ];
};
