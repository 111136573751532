// base http client axios instance

import { AxiosInstance } from "axios";
import Auth from "@aws-amplify/auth";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setToast } from "../app/toastReducer";
import { AppConstants } from "../constants/AppConstants";
import { RouteConstants } from "../constants/RouteConstants";
import { setTokens } from "../app/authReducer";
let store: any, dispatch: any;

export const createInstanceAndInjectStore = (_store: any, _dispatch: any) => {
  dispatch = _dispatch;
  store = _store;
};
class BaseAPIClient {
  isRefreshingtoken = false;
  axiosInstance: AxiosInstance;
  failedQueue: Array<any> = [];
  isFetchingToken = false;
  constructor() {
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}`,
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Cache-Control":
          "no-store, no-cache, max-age=0, must-revalidate, proxy-revalidate",
        Accept: "application/json",
        Pragma: "no-cache",
        "X-Content-Type-Options": "nosniff",
        "Strict-Transport-Security": "max-age=31536000; includeSubDomains",
        "X-Frame-Options": "SAMEORIGIN",
      },
      maxRedirects: 0,
    });

    this.axiosInstance.interceptors.request.use(
      function (config: any) {
        if (localStorage.getItem("idToken") !== "") {
          config.headers["Authorization"] = `Bearer ${localStorage.getItem(
            "idToken"
          )}`;
        }
        // console.log("interceptor Request Config: ", config);
        return config;
      },
      (error: any) => {
        // console.log("interception request error: ", error);
        return error;
      }
    );

    const processQueue = (access_token: any) => {
      this.failedQueue.forEach((errorRequestInstance) => {
        errorRequestInstance.resolve(access_token);
      });
      this.failedQueue = [];
    };

    this.axiosInstance.interceptors.response.use(
      async (response: any) => {
        // console.log("interceptors.response: ", response);
        if (response && response?.status !== 401) return response;
      },
      async (error: any) => {
        const that = this;
        const originalRequest = error.config;
        // console.log(
        //   "interceptors.response error: ",
        //   error,
        //   this.isRefreshingtoken
        // );

        // if (this.isRefreshingtoken && error.response.status === 401) {
        //   return new Promise(function (resolve, reject) {
        //     that.failedQueue.push({ resolve, reject });
        //   })
        //     .then((token) => {})
        //     .catch((err) => {
        //       return Promise.reject(error); // Ignore refresh token request's "err" and return actual "error" for the original request
        //     });
        // }

        // console.log(
        //   "auth error: ",
        //   error,
        //   error.response.status,
        //   error.response.status === 401
        // );

        if (error?.response?.status === 401) {
          this.isFetchingToken = true;
          this.isRefreshingtoken = true;

          // console.log("refreshing token happening");

          try {
            const refreshResponse: any = await Auth.currentSession();

            let access_token = refreshResponse.idToken.jwtToken;
            localStorage.setItem("idToken", refreshResponse.idToken.jwtToken);

            if (refreshResponse) {
              processQueue(access_token);
              return this.axiosInstance
                .request({
                  ...originalRequest,
                  headers: {
                    ...originalRequest.headers,
                    Authorization: `Bearer ${access_token}`,
                  },
                })
                .catch(() => {
                  console.error("refresh failed");
                });
            }
            return error.response;
          } catch (err: any) {
            try {
              const response = await Auth.signOut();
              window.location.pathname = RouteConstants.ROUTE_LOGIN;
              dispatch(
                setToast({
                  open: true,
                  severity: AppConstants.TOAST_ERROR,
                  msg: "Your session has expired. Please login again.",
                })
              );
              dispatch(setTokens({ idToken: "", refreshToken: "" }));
              localStorage.clear();
              sessionStorage.clear();
            } catch (err: any) {
              console.log(err);
            }
          }
        } else {
          // console.log("No Refresh happened: ", error);
          return error.response;
        }
      }
    );
  }
}
export default new BaseAPIClient();
