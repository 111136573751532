import React, { useEffect, useState } from "react";
import TrestleButton from "../Shared/TrestleButton";
import DialogPopup from "../Shared/DialogPopup";
import Select from "react-select";
import { HTTP_STATUS } from "../../http/constants/http.status";
import "../Teams/AddMoreUserToTeam/react-select.css";
import { setToast } from "../../app/toastReducer";
import { AppConstants } from "../../constants/AppConstants";
import {
  getAllMaxioCustomerIDs,
  setMaxioCustomerID,
} from "../../http/admin-service";
import { useAppDispatch } from "../../app/hooks";

interface FindMaxioCustomerIDProps {
  open: boolean;
  setPopup: any;
  setIsLoading?: any;
  maxioCustomerData?: any;
  getUserData: (email: string) => void;
  isAdmin: boolean;
}

function FindMaxioCustomerID({
  open,
  setPopup,
  setIsLoading = () => {},
  maxioCustomerData = {},
  getUserData,
  isAdmin,
}: FindMaxioCustomerIDProps) {
  const dispatch = useAppDispatch();

  const [selectedUsers, setSelectedUsers] = useState<any>([]);
  const [userList, setUserList] = useState<any>([
    {
      label: "",
      value: "",
      id: "",
    },
  ]);

  useEffect(() => {
    setSelectedUsers([]);
    if (isAdmin) {
      getAllMaxioUsersApiCall();
    }
  }, []);

  const getAllMaxioUsersApiCall = async () => {
    setIsLoading(true);
    const response = await getAllMaxioCustomerIDs({});
    setIsLoading(false);

    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      setUserList((prevSate: any) => [
        ...prevSate,
        ...formateUserListForReactSelect(data.data),
      ]);
    } else {
      const { details } = data;
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: details,
        })
      );
    }
  };

  const formateUserListForReactSelect = (arr: any) => {
    let temp: any = [];
    arr.map((user: any) => {
      temp.push({
        label: user.organization + " (" + user.id + ")",
        value: user.id,
        id: user.id,
      });
    });

    return temp;
  };

  const SetMaxioCustomerID = async () => {
    setIsLoading(true);

    const response = await setMaxioCustomerID(
      selectedUsers.id,
      maxioCustomerData.id
    );
    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      const { data: responseData = null } = data || {};
      setIsLoading(false);

      if (responseData === "customer mapped successfully") {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_SUCCESS,
            msg: AppConstants.MAXIO_CUSTOMER_ID_FETCH_SUCESS,
          })
        );
        getUserData(maxioCustomerData.email);
        setPopup(false);
      } else {
        dispatch(
          setToast({
            open: true,
            severity: AppConstants.TOAST_ERROR,
            msg: AppConstants.GENERIC_ERROR_MESSAGE,
          })
        );
      }
      // setPopup(false);
    } else {
      const { message } = data;
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: message,
        })
      );
    }
  };

  return (
    <DialogPopup
      open={!!open}
      onClose={(val: any) => {
        setPopup(true);
      }}
      closeButton={true}
      dialogStyleClass="dialogFindMaxioCustomer"
      title={""}
      className="!p-0 w-[700px]"
      component={
        <div>
          <div className="w-full py-8 px-10 bg-[#F3F5F6] text-[#163545] mb-6">
            <div className="text-xl font-bold flex">
              Find/Refresh Maxio Customer ID
            </div>
          </div>
          <div className="p-10 pt-0 w-full text-lg">
            <div>
              {open && (
                <div className="mb-10">
                  Please select customer from dropdown to link to this user.
                </div>
              )}
            </div>

            <div>
              <div className="w-full mr-2">
                <Select
                  options={userList}
                  placeholder="Select User"
                  value={selectedUsers}
                  onChange={(e: any) => {
                    setSelectedUsers(e);
                  }}
                  className="select-dropdown"
                />
              </div>
            </div>

            <div className={"mt-40 mb-8 text-base"}></div>

            <div className="flex">
              <TrestleButton
                onClick={SetMaxioCustomerID}
                label="Select"
                type="primary"
                className="max-w-[180px] !h-[40px] !py-0 !pt-0 ml-auto mr-auto"
                disabled={selectedUsers.length === 0}
              />
              <TrestleButton
                onClick={() => {
                  setPopup(false);
                }}
                label="Cancel"
                type="secondry"
                className="max-w-[180px] !h-[40px] !py-0 !pt-0 mr-auto"
              />
            </div>
          </div>
        </div>
      }
      closeOnOut={true}
    />
  );
}

export default FindMaxioCustomerID;
