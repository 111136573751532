import { Action, createSlice } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";

interface RehydrateAction extends Action<typeof REHYDRATE> {
  payload?: {
    authenticationData?: {
      idToken: string;
      refreshToken: string;
      userData: object;
      userMode: string;
      isAdmin: boolean;
      forceUserSession?: any;
    };
  };
}

const initialState = {
  idToken: localStorage.getItem("idToken") || "",
  refreshToken: localStorage.getItem("refreshToken") || "",
  userData: JSON.parse(localStorage.getItem("userData") || "{}"),
  userMode: localStorage.getItem("isAdmin") === "true" ? "ADMIN" : "USER",
  isAdmin: false,
  forceUserSession: undefined,
};

export const authSlice = createSlice({
  name: "authenticationData",
  initialState,
  reducers: {
    setTokens: (state, action) => {
      localStorage.setItem("idToken", action.payload.idToken);
      localStorage.setItem("refreshToken", action.payload.refreshToken);

      state.idToken = action.payload.idToken;
      state.refreshToken = action.payload.refreshToken;
    },
    setUserData: (state, action) => {
      localStorage.setItem("userData", JSON.stringify(action.payload));
      state.userData = action.payload;
    },
    setUserMode: (state, action) => {
      sessionStorage.setItem("userMode", action.payload);
      state.userMode = action.payload;
    },
    setForceUserSession: (state, action) => {
      state.forceUserSession = action.payload;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
      localStorage.setItem("isAdmin", action.payload.toString());
    },
  },
});

export const {
  setTokens,
  setUserData,
  setUserMode,
  setForceUserSession,
  setIsAdmin,
} = authSlice.actions;

export default authSlice.reducer;
