export const BatchUploadHeaderText = () => {
  return (
    <div className="cursor-default">
      <p>Validate or Enrich multiple records using Trestle’s Batch Upload.</p>
      <p>Steps:</p>
      <ol type="1" className="list-decimal ml-[30px] text-start mt-[5px]">
        <li>Select the Product</li>
        <li>
          Upload the CSV file following the templates
          {""}
          <span
            className="!underline !text-[#0494B4] !cursor-pointer ml-[5px] font-bold"
            onClick={() =>
              window.open(
                "https://trestleiq.com/knowledge-base/batch-upload-instructions/"
              )
            }
          >
            provided.
          </span>
        </li>
        <li>
          The results will be emailed to this account email address within an
          hour.
        </li>
      </ol>
    </div>
  );
};

export const LeadAssessmentHeaderText = () => {
  return (
    <div className="cursor-default">
      <p>
        Powered by our advanced Real Contact API, this assessment summarizes
        your contact data into aggregate statistics to understand the health of
        your contact or lead data and compares how your data is doing compared
        to your peers.
      </p>
      <p className="mt-[12px]">Steps:</p>
      <ol type="1" className="list-decimal ml-[30px] text-start mt-[5px]">
        <li>
          Provide your email where you would like to receive the assessment
          results.
        </li>
        <li>
          Upload your CSV file following the template
          <span
            className="!underline !text-[#0494B4] !cursor-pointer ml-[5px] font-bold"
            onClick={() =>
              window.open(
                "https://trestle-batch-templates.s3.us-west-2.amazonaws.com/Real_Contact.csv"
              )
            }
          >
            here
          </span>
          . Phone numbers and names are mandatory.
        </li>
        <li>
          Receive your free Real Contact Data Assessment in your email within a
          few minutes. A sample assessment similar to what you’ll receive can be
          viewed
          <span
            className="!underline !text-[#0494B4] !cursor-pointer ml-[5px] font-bold"
            onClick={() =>
              window.open(
                "https://portal.trestleiq.com/data-assessment/report/2af57ad4-e738-401b-894f-67814ae130af",
                "_blank"
              )
            }
          >
            here
          </span>
          .
        </li>
      </ol>
    </div>
  );
};
