import { createSlice } from "@reduxjs/toolkit";

export const loaderSlice = createSlice({
  name: "loaderData",
  initialState: {
    open: false,
    msg: "",
  },
  reducers: {
    setLoader: (state, action) => {
      state.open = action.payload.open;
      state.msg = action.payload.msg ? action.payload.msg : "";
    },
  },
});

export const { setLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
