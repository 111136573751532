export class RegExConstants {
  static readonly EMAIL_VALIDATE =
    /^(([^<>()[\]\\.,;:\s@“]+(\.[^<>()[\]\\.,;:\s@“]+)*)|(“.+“))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  static readonly PHONE_VALIDATE = /^[+]?[0-9-()]*$/;
  // static readonly PHONE_VALIDATE = /^[+]?\d+$/;
  // /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;

  static readonly NO_SPACE_IN_TEXT = /[^a-z0-9\-\_\.]/gi;
  static readonly ACCEPT_SPACE_IN_TEXT = /[^a-z0-9\s\-\_\.]/gi;
  static readonly FORMAT_PHONE_NUMBER = /(\d{3})\-?(\d{3})\-?(\d{4})/;
  static readonly ONLY_NUMBERS = /^[0-9\-+]*$/;
  static readonly PASSWORD_POLICY =
    /(?=.{8,})(?=.*?[^\w\s])(?=.*?[0-9])(?=.*?[A-Z]).*?[a-z].*/;
}
