export class ConstantTexts {
  static readonly BRANDING_LOGO_TEXT =
    "Your partner for quality identity data APIs";
  static readonly ACCOUNT_EXIST_TEXT =
    "Account already exists for this email. Please Sign-in";
  static readonly PASSWORD_POLICY_INFO =
    "Note: Password should 8-100 characters long and contain atleast 1 uppercase, lowercase, number, and special character";
  static readonly SIGNUP_HEADER = "Sign Up";
  static readonly SIGNIN_HEADER = "Sign In";
  static readonly VERIFY_OTP_HEADER = "Verify your Email";
  static readonly VERIFY_OTP_INFO_TEXT =
    "An email verification code has been sent to <email>. Enter your 6 digit code here:";
  static readonly RESET_PASSWORD_HEADER = "Reset your password";
  static readonly FORCE_CHANGE_PASSWORD_HEADER = "Change your password";
  static readonly RESET_PASSWORD_INFO_TEXT =
    "Enter your email address to receive instructions for resetting your password.";
  static readonly CONFIRM_RESET_PASSWORD_INFO_TEXT =
    "We have sent a verification code to ";
  static readonly ALREADY_ACCOUNT_TEXT =
    "Account already exists for this email. Please Sign-in";
  static readonly LONG_TIME_LOADING_TEXT =
    "Request is being processed, might take up to 30 seconds";

  static readonly USAGE_TABLE_HEADER_INFO =
    "The usage update might take up to 60 minutes";

  static readonly TEAM_INVITATION_SUCCESS = "Team Invitation Sent Successfully";

  static readonly USER_INVITATION_SUCCESS = "User Invitation Sent Successfully";

  static readonly USER_ADDED_TO_TEAM_SUCCESS =
    "You have invited {email} to your team.";

  static readonly TEAM_INVITE_MASSAGE =
    "Note: Once user accepted the invitation that user will become a part of team, User will be able to access the Team Account and existing API Keys";

  static readonly PRODUCT_ACCESS_LiMIT =
    "For one API Key you can not assign more than 10 products.";

  static readonly BRANDING_PAGE_BULLET_POINTS = [
    "Comprehensive coverage and best-in-class accuracy",
    "Easy integration into your existing applications or third party tools",
    "Enterprise-ready with low latency APIs and 99.99% uptime SLA",
    "24/7 customer-obsessed technical support",
    "Flexible plans that cater to businesses of all sizes",
  ];

  static readonly BRANDING_PAGE_TEXT =
    "Trestle is trusted by leading enterprise companies serving 300M+ queries in the US and Canada";

  static readonly TEST_REQUEST_TEXT =
    "You do not have access to this product yet, but try it out with a sample request below. ";

  static readonly TEST_REQUEST_EXPIRED_TEXT =
    "You do not have access to this product because your API key is expired, but try it out with a sample request below.";

  static readonly TEST_REQUEST_ACCESS_REQUESTED =
    "You do not have access to this product yet, but try it out with a sample request below";

  static readonly VALIDATION_VERIFICATION_API_TOOLTIP =
    "Validate and verify contact details, including phone numbers, names, and addresses, with comprehensive metadata and activity scoring.";

  static readonly ENRICHMENT_API_TOOLTIP =
    "Enhance contact data with detailed personal information, demographics, and historical records across various identifiers.";

  static readonly TELEPHONY_API_TOOLTIP =
    "Identify callers and provide rich phone metadata, including ownership details, carrier information, and associated contact data.";

  static readonly SPEND_LIMIT_UPDATED = "Spend limit updated successfully";
  static readonly SPEND_LIMIT_UPDATE_ERROR = "Spend limit update failed";

  static readonly MAXIO_BILLING_PORTAL_URL_FETCH_ERROR =
    "Error while redirecting to billing page, contact support@trestleiq.com ";

  static readonly FILE_UPLOAD_LIMIT =
    "Uploading more than 1 File is not allowed";
  static readonly FILE_TYPE_ERROR = "Wrong file type. please upload .csv file";

  static readonly INSUFFICIENT_RECORDS =
    "Please upload a file with at least 50 records";
  static readonly MAXIO_GET_CUSTOMER_ID_SUCCESS =
    "found maxio customer ID and active Enterprise subscription";
}
