import { useState, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import { UserNameText } from "../CommonComponents";
import { Avatar } from "@mui/material";
import { ClosePopup, PencilIcon } from "../../../assets/svgs";
import styled from "@emotion/styled";
import APIKeyList from "./APIKeyList/APIKeyList";
import UserDetails from "../../UserDetails/UserDetails";
import LoaderComponent from "../LoaderComponent";
import YourUsage from "../../UserMode/YourUsage/YourUsage";
import Teams from "../../UserDetails/Teams";
import BillingDetailsTab from "../../UserDetails/BillingDetailsAdminMode";
import AddMoreUserToTeam from "../../Teams/AddMoreUserToTeam/addMoreUserToTeam";
import InputTextField from "../InputTextField";
import { AppConstants } from "../../../constants/AppConstants";
import TrestleButton from "../TrestleButton";
import { getUser, updateCompanyName } from "../../../http/authentication-service";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/toastReducer";
import isEqual from "lodash/isEqual";
import { isEmpty } from "lodash";
import YourUsageV2 from "../../UserMode/YourUsage/YourUsageV2";

interface ComponentDrawerProps {
  open: boolean;
  anchor: "top" | "right";
  userName?: string;
  close?: any;
  children: any;
  width?: string;
  userData?: any;
  closeOnBackdropClick?: boolean;
}

interface TeamFormData {
  team: string;
  company: string;
  teamId: string;
  companyId: string;
  userId: string;
}

export default function ComponentDrawer({
  open,
  anchor,
  userName = "",
  close = () => { },
  children,
  width = "1130px",
  userData = null,
  closeOnBackdropClick = false,
}: ComponentDrawerProps) {
  const dispatch = useDispatch();

  const [selectedTab, setSelectedTab] = useState<string>("");

  const TabOptions = ["Subscriptions", "Details", "Usage", "Team Members", "Billing Details"];
  const initialFormSate = { team: "", company: "", teamId: "", companyId: "", userId: "" };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [addMoreUser, setAddMoreUser] = useState<boolean>(false);
  const [formData, setFormData] = useState<TeamFormData>(initialFormSate);
  const [fullName, setFullName] = useState<string>("");
  const [currentUser, setCurrentUser] = useState<any>(null);

  useEffect(() => {
    setSelectedTab("Subscriptions");
  }, []);

  useEffect(() => {
    const compoent_div = document.getElementById("scroll-comp");
    compoent_div?.scrollTo(0, 0);
  }, [selectedTab]);


  const setInitialFormdate = async () => {
    const response = await getUser(userData.email);
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      const { id = "", company = null, name: teamName = "" } = data.data?.team || {};
      const { id: companyId = "", name: companyName = "" } = company || {};
      setFullName(`${data.data?.firstName || "-"} ${data.data?.lastName || ""}`)
      const formData = {
        company: companyName,
        team: teamName,
        companyId: companyId,
        teamId: id,
        userId: userData?.id || "",
      }
      setFormData(formData);
      setCurrentUser(data.data)
    } else {
      const { details } = data;
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: details,
        })
      );
    }
  };

  return (
    <>
      <LoaderComponent loader={isLoading} />

      <Drawer
        anchor={anchor}
        open={open}
        className="w-fit"
        PaperProps={{
          sx: {
            width: "80%",
            maxWidth: width,
            marginLeft: anchor === "top" ? "auto" : "",
            height: "100%",
            background: "#F5F4F4",
            overflow: "hidden",
          },
        }}
        onClose={() => {
          if (closeOnBackdropClick) {
            close();
          }
        }}
      >
        <div
          id="scroll-comp"
          className={`${fullName ? "pt-[0px]" : "pt-[90px]"
            } h-full bg-[#EAEBEC] overflow-y-auto`}
        >
          {fullName && (
            <div className="px-[45px] pt-[45px] bg-[#EAEBEC] sticky top-[90px] z-10">
              <div className="pb-5 flex">
                <div className="flex flex-1">
                  <Avatar
                    alt={fullName || ""}
                    src="/static/images/avatar/2.jpg"
                    className="!h-8 !w-8"
                  />
                  <UserNameText w={"700px"} className="!h-[30px]">
                    {fullName}
                  </UserNameText>
                </div>
                <div
                  onClick={() => {
                    close();
                    setSelectedTab("Subscriptions");
                  }}
                  className="cursor-pointer w-fit ml-auto"
                >
                  {ClosePopup}
                </div>
              </div>

              <div className="flex flex-1 pb-5">
                <div className="flex">
                  <UserNameText
                    w={"fit-content"}
                    className="!h-[30px] !text-lg !ml-0"
                  >
                    <b className="mr-1">Company:</b>
                    {formData.company || (
                      <span className="opacity-50">Not Available</span>
                    )}
                  </UserNameText>
                  <UserNameText
                    w={"fit-content"}
                    className="!h-[30px] !text-lg !ml-3 !flex"
                  >
                    <b className="mr-1">Team:</b> {formData.team}{" "}
                  </UserNameText>
                </div>
              </div>

              <div className="flex">
                {TabOptions.map((tab: string) => {
                  return (
                    <TabTextComponent
                      selected={selectedTab === tab}
                      key={tab}
                      onClick={() => {
                        setSelectedTab(tab);
                      }}
                    >
                      {tab}
                    </TabTextComponent>
                  );
                })}
              </div>
            </div>
          )}
          {children || (
            <div
              className={`p-10 ${[TabOptions[0], TabOptions[3]].includes(selectedTab)
                ? "bg-[#F5F4F4]"
                : "bg-white"
                } min-h-full`}
            >
              {selectedTab === TabOptions[0] && (
                <div className="mt-[80px] pb-[100px]">
                  <APIKeyList
                    userData={userData}
                    usersDetails={currentUser}
                    setInitialFormdate={setInitialFormdate}
                    isAdmin={true}
                  />
                </div>
              )}
              {selectedTab === TabOptions[1] && (
                <div className="mt-[90px]">
                  <UserDetails
                    setLoading={setIsLoading}
                    userData={userData.email}
                    setInitialFormdate={setInitialFormdate}
                    isAdmin={true}
                  />
                </div>
              )}
              {/* {selectedTab === TabOptions[2] && (
                <div className="mt-[90px]">
                  <YourUsage
                    userId={userData?.id || 0}
                    userEmail={userData.email}
                  />
                </div>
              )} */}
              {/* version 2 */}
              {selectedTab === TabOptions[2] && (
                <div className="mt-[80px]">
                  <YourUsageV2
                    usersDetails={currentUser}
                    userId={userData?.id || 0}
                    userEmail={userData.email}
                    isAdmin={true}
                    setInitialFormdate={setInitialFormdate}
                  />
                </div>
              )}
              {selectedTab === TabOptions[3] && (
                <div className="mt-[80px]">
                  <Teams
                    usersDetails={currentUser}
                    teamId={formData?.teamId || null}
                    setLoading={setIsLoading}
                    closeDrawer={close}
                    showFields={false}
                    setAddMoreUser={setAddMoreUser}
                    isAdmin={true}
                    setInitialFormdate={setInitialFormdate}
                  />
                </div>
              )}
             {selectedTab === TabOptions[4] && (
                <div className="mt-[80px]">
                  <BillingDetailsTab
                    setLoading={setIsLoading}
                    setDialogOpen={close}
                    userEmail={userData.email}
                 />
                </div>
              )}
            </div>
          )}
        </div>

        {addMoreUser && (
          <AddMoreUserToTeam
            open={addMoreUser}
            setPopup={setAddMoreUser}
            setIsLoading={setIsLoading}
            teamData={formData}
          />
        )}

      </Drawer>
    </>
  );
}

export const TabTextComponent = styled.div<{ selected: boolean }>`
  font-size: 16px;
  font-weight: ${(props: any) => (props.selected ? "500" : "400")};
  border-bottom: ${(props: any) =>
    props.selected ? "4px solid #0494B4" : "none"};
  height: 47px;
  cursor: pointer;
  margin-right: 30px;
  color: ${(props: any) => (props.selected ? "#163545" : "#566D76")};
`;
