import React, { useRef, useEffect, useState, ReactElement } from "react";
import { Box, Tooltip } from "@mui/material";

interface OverflowTooltipProps {
  text: string;
  sx?: Object | null;
  tooltipPlacement?:
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | undefined;
  children: ReactElement;
  arrow?: boolean;
}

const OverflowTooltip = ({
  text = "",
  sx = null,
  tooltipPlacement = "top",
  children,
  arrow = false,
}: OverflowTooltipProps) => {
  const textElementRef = useRef<HTMLDivElement>();

  // Define state and function to update the value
  const [hoverStatus, setHover] = useState(false);

  const compareSize = () => {
    const compare =
      !!textElementRef.current &&
      textElementRef.current.scrollWidth > textElementRef.current.clientWidth;
    setHover(compare);
  };

  // compare once and add resize listener on "componentDidMount"
  useEffect(() => {
    compareSize();
    window.addEventListener("resize", compareSize);
    return () => {
      window.removeEventListener("resize", compareSize);
    };
  });

  return (
    <Tooltip
      title={text}
      placement={tooltipPlacement}
      disableHoverListener={!hoverStatus}
      arrow={arrow}
      followCursor
      leaveTouchDelay={0}
    >
      <Box
        ref={textElementRef}
        sx={sx}
        className="w-full overflow-hidden truncate"
      >
        {children}
      </Box>
    </Tooltip>
  );
};

export default OverflowTooltip;
