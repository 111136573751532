import { useEffect } from "react";
import { useAppDispatch } from "../../../app/hooks";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import { addNewUser } from "../../../http/authentication-service";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import DialogPopup from "../../Shared/DialogPopup";
import SingUpUserForm from "../../Shared/SignupForm";
import { ConstantTexts } from "../../../constants/ConstantTexts";

interface AddNewUserPopUpProps {
  open: boolean;
  setPopup: any;
  setIsLoading?: any;
}

const AddNewUserPopUp = ({
  open,
  setPopup,
  setIsLoading = () => {},
}: AddNewUserPopUpProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    handleMixPanel(MixPanelEvents.INVITE_NEW_USER, {});
  }, []);

  const inviteNewUser = async (
    payload: any,
    formError: any,
    setFormError: any
  ) => {
    const response = await addNewUser(payload);
    const { status = 401, data = {} } = response || {};
    if (status === HTTP_STATUS.HTTP_OK) {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_SUCCESS,
          msg: ConstantTexts.USER_INVITATION_SUCCESS,
        })
      );
      handleMixPanel(MixPanelEvents.INVITE_SENT_SUCCESSFULLY, {
        email: payload.email,
      });
      setPopup(false);
    } else {
      const { error = "", details = "" } = data || {};
      if (details === "Email is Invalid") {
        setFormError({ ...formError, email: AppConstants.EMAIL_ERROR_MSG });
      } else {
        dispatch(
          setToast({
            open: true,
            severity: "error",
            msg: details,
          })
        );
        if (details === "User with same email already exists") {
          dispatch(
            setToast({
              open: true,
              severity: "error",
              msg: details,
            })
          );
        }
      }

      if (error === "Email is Invalid") {
        handleMixPanel(MixPanelEvents.ADMIN_ENTERED_INVALID_EMAIL, {
          email: payload.email,
          error: error,
        });
      }
      setIsLoading(false);
    }
  };

  return (
    <DialogPopup
      open={!!open}
      onClose={(val: any) => {
        setPopup(false);
      }}
      closeButton={true}
      dialogStyleClass="dialogInvitation"
      title={""}
      className="!p-0 w-[700px]"
      component={
        <div>
          <div className="w-full py-8 px-10 bg-[#F3F5F6] text-[#163545] mb-6">
            <div className="text-xl font-bold flex">
              {/* <span className="mr-3">{TeamsIcon}</span> */}
              Add New User
            </div>
          </div>
          <div className="px-[20px]">
            <SingUpUserForm
              type={"ADD_NEW_USER"}
              SignUpFinalCall={inviteNewUser}
              setIsLoading={setIsLoading}
              setPopup={setPopup}
            />
          </div>
        </div>
      }
    />
  );
};

export default AddNewUserPopUp;
