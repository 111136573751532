import { useEffect, useState } from "react";
import { APIKEY_LABEL } from "../Shared/CommonComponents";
import Accordions from "../Shared/Accordions/Accordions";
import TrestleTable, {
  TableHeaderProps,
} from "../Shared/TrestleTable/TrestleTable";
import NoDataFound from "../Shared/NoDataFound";
import moment from "moment";
import NewAccordions from "../Shared/NewAccordion/NewAccordion";

interface RevokeAPIKeyProps {
  listData: any;
}

function RevokeAPIKey({ listData }: RevokeAPIKeyProps) {
  const [expanded, setExpanded] = useState<string | false>("");

  useEffect(() => {
    setExpanded(listData.length ? "panel1" : "");
  }, []);

  const headCells: readonly TableHeaderProps[] = [
    {
      id: "apiKey",
      numeric: false,
      disablePadding: false,
      label: "API Key",
      width: "70%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "issuedDate",
      numeric: true,
      disablePadding: false,
      label: "Access",
      isCustomElements: true,
      width: "15%",
      customElements: (ele: any) => {
        return <span>{moment(ele.issuedDate).format("DD/MM/YYYY")}</span>;
      },
      sortable: false,
    },
    {
      id: "revokedDate",
      numeric: true,
      disablePadding: false,
      label: "Date Revoked",
      isCustomElements: true,
      width: "15%",
      customElements: (ele: any) => {
        return <span>{moment(ele.revokedDate).format("DD/MM/YYYY")}</span>;
      },
      sortable: true,
    },
  ];

  return (
    <>
      <NewAccordions
        key={`key-accor-revoke-keys`}
        expanded={expanded}
        setExpanded={setExpanded}
        index={1}
        disabled={null}
        summary={
          <div className="flex w-full">
            <APIKEY_LABEL className="flex-1">Revoked API Keys</APIKEY_LABEL>
          </div>
        }
        details={
          <div className="w-full pb-[70px]">
            {listData.length ? (
              <TrestleTable
                isPaginated={false}
                headCells={headCells}
                rows={listData || []}
              />
            ) : (
              <NoDataFound
                text="There are no revoked APIKEY"
                className="mt-15"
              />
            )}
          </div>
        }
      />
    </>
  );
}

export default RevokeAPIKey;
