import { useState } from "react";
import Checked from "../../assets/component/Property 1=Checked.svg";
import UnChecked from "../../assets/component/Property 1=Unchecked.svg";
import ToggleOn from "../../assets/component/Toggle State=On.svg";
import ToggleOff from "../../assets/component/Toggle State=Off.svg";

interface CheckBoxProps {
  value: boolean;
  toggleCheck: any;
  label?: string;
  disabled?: boolean;
  className?: string;
}

export const CheckBoxComponent = ({
  value,
  toggleCheck,
  label = "",
  disabled = false,
  className = "",
}: CheckBoxProps) => {
  return (
    <div className="flex">
      <img
        className={`cursor-pointer ${
          disabled ? "pointer-events-none opacity-50" : ""
        } ${className}`}
        src={value ? Checked : UnChecked}
        alt="checkbox"
        onClick={toggleCheck}
      />
      {label && <div>{label}</div>}
    </div>
  );
};

interface ToggleSwitchProps {
  value: boolean;
  toggleCheck: any;
  disabled?: boolean;
}

export const ToggleSwitchComponent = ({
  value,
  toggleCheck,
  disabled = false,
}: ToggleSwitchProps) => {
  return (
    <img
      className={`cursor-pointer ${
        disabled ? "pointer-events-none opacity-50 !cursor-not-allowed" : ""
      }`}
      src={value ? ToggleOn : ToggleOff}
      alt="toggleSwitch"
      onClick={toggleCheck}
    />
  );
};
