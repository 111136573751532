import { Box } from "@mui/material";
import { Text14pxBold, Text16pxMedium, Text20pxBold } from "./CommonComponents";
import { ClockIcon, ForwardArrow, LockIcon } from "../../assets/svgs";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import {
  setCurrentApiKey,
  setCurrentApiProduct,
} from "../../app/apiKeyReducer";

interface ApiKeyProductsListCardProps {
  heading: string;
  subHeading: string;
  products: any[];
  apiKey: object;
}

const ApiKeyProductsListCard = ({
  heading = "",
  subHeading = "",
  products = [],
  apiKey = {},
}: ApiKeyProductsListCardProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const showIcon = (status: string) => {
    if (status === "REQUESTED") {
      return <Box className="text-[#163545]">{ClockIcon}</Box>;
    } else if (status !== "REQUESTED" && status != "AVAILABLE") {
      return <Box className="!text-[#B5B5B5]">{LockIcon}</Box>;
    }
  };

  return (
    <Box
      sx={{
        border: "1px solid #555555",
        padding: "48px",
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      <Box>
        <Text20pxBold c="#163545">{heading}</Text20pxBold>
        <Text16pxMedium c="#163545">{subHeading}</Text16pxMedium>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        {products.map((product, index) => (
          <Box
            onClick={() => {
              navigate(`/api-keys/products-details`);
              dispatch(setCurrentApiProduct(product.name));
              dispatch(setCurrentApiKey(apiKey));
            }}
            sx={{
              width: "100%",
              height: "100%",
              background: "#F5F5F5",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "14px 10px",
              cursor: "pointer",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Box
                sx={{
                  height: "14px",
                  width: "14px",
                  background: `${product.iconBoxColor}`,
                }}
              />
              <Text14pxBold c="#163545">{product.name}</Text14pxBold>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {showIcon(product?.status)}
              <Box className="text-[#163545]">{ForwardArrow}</Box>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default ApiKeyProductsListCard;
