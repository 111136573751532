import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Text14pxMedium, Text16pxBold } from "../CommonComponents";
import { Box, Stack } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

const DonutChart = ({ data, title, width }: any) => {
  function isFloat(n: any) {
    return Number(n) === n && n % 1 !== 0;
  }

  const donutLabelLines = {
    id: "dataLabelLine",
    afterDraw: (chart: any, args: any, options: any) => {
      const {
        ctx,
        chartArea: { top, bottom, left, right, width, height },
      } = chart;
      chart.data.datasets.forEach((dataset: any, index: number) => {
        chart
          .getDatasetMeta(index)
          .data.forEach((dataPoint: any, j: number) => {
            const { x, y } = dataPoint.tooltipPosition();

            // ctx.fillStyle = dataset.backgroundColor[j];
            // ctx.fill();

            const halfHeight = (height + 50) / 2;
            const halfWidth = (width + 50) / 2;

            const xLine = x >= halfWidth ? x + 30 : x - 30;
            let previousLabelPosition: any = null;

            const yOffset = j % 2 === 0 ? 75 : 55;

            const adjustForOverlap = (yPos: any, index: any) => {
              if (
                previousLabelPosition &&
                Math.abs(yPos - previousLabelPosition) < 20
              ) {
                return yPos + 20 * (index % 2 === 0 ? 1 : -1); // Adjust by 20px if overlap detected
              }
              previousLabelPosition = yPos;
              return yPos;
            };

            // Then apply it when setting yLine
            const yLine = adjustForOverlap(
              y >= halfHeight ? y + yOffset : y - yOffset,
              j
            );

            const extraLineX = x >= halfWidth ? 30 : -30;

            ctx.beginPath();
            ctx.moveTo(x, y);
            ctx.lineTo(xLine, yLine);
            ctx.lineTo(xLine + extraLineX, yLine);
            ctx.strokeStyle = dataset.backgroundColor[j];
            ctx.lineWidth = 2;
            ctx.stroke();

            ctx.font = "bold 12px Dm Sans";

            const textXPosition = x >= halfWidth ? "left" : "right";
            const plusFivePx = x >= halfWidth ? 5 : -5;
            ctx.textAlign = textXPosition;
            ctx.textBaseline = "middle";
            ctx.fillStyle = dataset.backgroundColor[j];
            ctx.fillText(
              chart.data.labels[j],
              xLine + extraLineX + plusFivePx,
              yLine
            );

            const dataValue = isFloat(dataset.data[j])
              ? parseFloat(dataset.data[j]).toFixed(2)
              : dataset.data[j];
            ctx.font = "bold 16px Dm Sans";

            ctx.textAlign = textXPosition;
            ctx.textBaseline = "middle";
            ctx.fillStyle = "white";
            ctx.fillText(dataValue, x, y);
          });
      });
    },
  };
  const options = {
    // responsive: true,
    cutout: "50%",
    hover: { mode: null },
    layout: {
      aspectRatio: false,
      padding: 65,
    },
    plugins: {
      legend: {
        display: false,
        position: "bottom" as const,
        labels: {
          usePointStyle: true,
          padding: 20,
        },
      },
      tooltip: {
        enabled: false,
      },
      title: {
        display: false,
        text: title,
        font: {
          size: 18,
        },
      },
    },
  };

  return (
    <Box
      width={width ? width : "520px"}
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Text16pxBold style={{ fontWeight: 700 }}>{title}</Text16pxBold>
      <Doughnut
        data={data}
        options={options as any}
        plugins={[donutLabelLines]}
      />
      <Stack direction={"row"} spacing={3}>
        {data?.labels?.map((label: any, index: any) => {
          return (
            <Stack
              direction={"row"}
              alignItems={"center"}
              spacing={1}
              justifyContent={"center"}
            >
              <Box
                sx={{
                  background: `${data?.datasets?.[0]?.backgroundColor[index]}`,
                }}
                className={`w-[16px] h-[16px]`}
              />
              <Text14pxMedium style={{ lineHeight: "14px" }}>
                {label}
              </Text14pxMedium>
            </Stack>
          );
        })}
      </Stack>
    </Box>
  );
};

export default DonutChart;
