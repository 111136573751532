import { useEffect, useState } from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import InputTextField from "./InputTextField";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";

interface DateRangeTrestleProps {
  value: any;
  setValue: any;
  minDate?: any;
}

function CalenderComp({
  value,
  setValue,
  minDate = "",
}: DateRangeTrestleProps) {
  const [selectedData, setSelectedDate] = useState<string>("");
  const [dateSelect, setDate] = useState<any>(new Date());

  useEffect(() => {
    let temp = moment(value).format("MM/DD/YYYY");
    if (temp !== selectedData) {
      setDate(value);
      setSelectedDate(value ? temp : "");
    }
  }, [value]);

  const handleSelect = (date: any) => {
    let temp = moment(date).format("MM/DD/YYYY");

    setSelectedDate(temp);
    setDate(date);
    setValue(date);
    handleClose();
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className="!cursor-pointer"
      >
        <InputTextField
          name={"date"}
          label={""}
          placeholder="MM/DD/YYYY"
          value={{ date: selectedData }}
          error={{}}
          type={"text"}
          onChange={() => {}}
          disabled={false}
          height="40px"
          isCalendar={true}
        />
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        className="mt-2"
      >
        <Calendar
          onChange={handleSelect}
          months={1}
          showDateDisplay={true}
          date={dateSelect}
          minDate={new Date(minDate)}
        />
      </Menu>
    </>
  );
}

export default CalenderComp;
