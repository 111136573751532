import React, { useEffect } from "react";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import { Routes } from "./routes/Routes";
import WebFont from "webfontloader";
import ToastMsg from "./components/Shared/ToastMsg";
import LoaderComponent from "./components/Shared/LoaderComponent";
import { Amplify } from "aws-amplify";
// import mixpanel from "mixpanel-browser";
import { createInstanceAndInjectStore } from "./http/api";
import { useDispatch, useStore } from "react-redux";
import GlobalLoaderComponent from "./components/Shared/GlobalLoader";

const AWS_CONFIG = {
  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
};

// const MIX_PANEL_TOKEN: string =
//   process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || "";

export const App = (): JSX.Element => {
  const st = useStore();
  const dispatch = useDispatch();
  createInstanceAndInjectStore(st, dispatch);
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["DM Sans"],
      },
    });

    console.log(process.env.REACT_APP_COGNITO_REGION);

    Amplify.configure(AWS_CONFIG);

    // mixpanel.init(MIX_PANEL_TOKEN, {
    //   //debug: true, // only for Dev
    //   ignore_dnt: true,
    // });
  }, []);

  return (
    <>
      <BrowserRouter>
        {/* <GlobalLoaderComponent /> */}
        <LoaderComponent />
        <ToastMsg />
        <Routes />
      </BrowserRouter>
    </>
  );
};
