import { SyntheticEvent } from "react";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { ExpandIcon, SideArrow } from "../../assets/svgs";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(() => ({
  // border: "none",
  boxShadow: "none",
  padding: "15px 0px 0px 0px",
  background: "#F5F5F5",
  borderRadius: "0px",
  "&:last-child": {
    borderRadius: "0px",
    paddingBottom: "15px",
  },
  "&:first-child": {
    borderRadius: "0px",
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} />
))(() => ({
  backgroundColor: "#F5F5F5",
  border: "none",
  margin: "0px",
  height: "fit-content",
  minHeight: "fit-content",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(-90deg)",
  },
  "& .MuiAccordionSummary-expandIconWrapper": {
    color: "#00485A",
  },
  "& .MuiAccordionSummary-content": {
    margin: "0px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(() => ({
  padding: "5px 16px",
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  border: "none",
  backgroundColor: "#F5F5F5",
  "& .MuiAccordionDetails-root": {
    padding: "0px",
    margin: "0px",
  },
}));

export default function ApiProductResponseKeyListComponent({
  summary,
  details,
  index,
  expanded,
  setExpanded,
  disabled,
  className = "",
  accordionStyles = {},
  accordionSummaryStyles = {},
  accordionDetailsStyles = {},
}: any) {
  const handleChange =
    (panel: string) => (event: SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const panel = `panel${index}`;

  return (
    <Accordion
      key={`key-accord`}
      expanded={expanded === panel}
      onChange={handleChange(panel)}
      disableGutters
      sx={{
        cursor: expanded === panel ? "default" : "pointer",
        ...accordionStyles,
      }}
      className="before:!bg-inherit"
    >
      <AccordionSummary
        aria-controls={`${panel}d-content`}
        id={`${panel}d-header`}
        sx={{
          cursor: expanded === panel ? "default" : "pointer",
          ...accordionSummaryStyles,
        }}
        expandIcon={SideArrow}
      >
        {summary}
      </AccordionSummary>

      <AccordionDetails
        sx={{
          ...accordionDetailsStyles,
        }}
      >
        {details}
      </AccordionDetails>
    </Accordion>
  );
}
