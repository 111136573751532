import axios from "axios";
import React from "react";

const API_BASE_URL: string =
  process.env.REACT_APP_API_URL || "http://localhost";

export const uploadLeadAssessmentFile = (payload: any) => {
  const url = `${API_BASE_URL}/report-analysis/submit`;
  return axios.post(url, payload);
};

export const getLeadAssessmentFileReport = (uuid: any) => {
  const url = `${API_BASE_URL}/report-analysis/view/${uuid}`;
  return axios.get(url);
};
