import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// import { DeleteIcon } from "../../assets/svgs";
import InputTextField from "../Shared/InputTextField";
import { AppConstants } from "../../constants/AppConstants";
import TrestleButton from "../Shared/TrestleButton";
import { getTeamsUsers } from "../../http/teams-service";
import { HTTP_STATUS } from "../../http/constants/http.status";
import { setToast } from "../../app/toastReducer";
import TrestleTable, {
  TableHeaderProps,
} from "../Shared/TrestleTable/TrestleTable";
import { useAppSelector } from "../../app/hooks";
import moment from "moment";
import { getUTCDate } from "../../utils/helpers";
import { formatName } from "../../utils/validations";

interface UserDetailsProps {
  setLoading: Function;
  closeDrawer?: any;
  showFields?: boolean;
  setAddMoreUser?: any;
  teamId?: any;
  usersDetails?: any;
  isAdmin?: boolean;
  setInitialFormdate?: any;
}

function Teams({
  setLoading,
  closeDrawer = () => {},
  showFields = true,
  setAddMoreUser = null,
  teamId = null,
  usersDetails = {},
  isAdmin = false,
  setInitialFormdate = () => {},
}: UserDetailsProps) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formData, setFormData] = useState<any>({
    company: "Sample Company",
    team: "Sample Team",
  });

  const [teamUserList, setTeamUserList] = useState<any>([]);

  const [formErrorData, setFormErrorData] = useState<any>({
    company: "",
    team: "",
  });

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  useEffect(() => {
    setInitialFormdate();
    const { team = {} } = userData || {};
    const { company = null, name = "", id = "" } = team || {};
    if (showFields) {
      setFormData({
        company: company?.name || "",
        team: name || "",
      });
    }
    FetchTeamUsers(showFields ? id : teamId || id);
  }, []);

  const FetchTeamUsers = async (id: any) => {
    setLoading(true);
    const response: any = await getTeamsUsers(id);
    if (response?.status === HTTP_STATUS.HTTP_OK) {
      const { data = [] } = response.data || {};
      // console.log("Teams Users: ", data);
      setTeamUserList(data);
      setLoading(false);
    } else {
      const { details = "" } = response.data || {};
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: details,
        })
      );
      setLoading(false);
    }
  };

  const headCells: readonly TableHeaderProps[] = [
    {
      id: "id",
      numeric: true,
      disablePadding: false,
      label: "User Id",
      width: "15%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "firstName",
      numeric: false,
      disablePadding: false,
      label: "First Name",
      width: "25%",
      isCustomElements: false,
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "lastName",
      numeric: false,
      disablePadding: false,
      label: "Last Name",
      isCustomElements: false,
      width: "25%",
      customElements: (ele: any) => {},
      sortable: true,
    },
    {
      id: "email",
      numeric: false,
      disablePadding: false,
      label: "Email",
      isCustomElements: false,
      width: "35%",
      customElements: (ele: any) => {},
      sortable: true,
    },
    // {
    //   id: "joinDate",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Joining Date",
    //   isCustomElements: true,
    //   width: "20%",
    //   customElements: (ele: any) => {
    //     return (
    //       <>{moment(getUTCDate(ele.createdAt || "")).format("MM/DD/YYYY")}</>
    //     );
    //   },
    //   sortable: true,
    // },
    // {
    //   id: "userId",
    //   numeric: false,
    //   disablePadding: false,
    //   label: " ",
    //   isCustomElements: true,
    //   width: "5%",
    //   customElements: (ele: any) => {
    //     return (
    //       <span className="cursor-pointer hover:text-[#B23352]">
    //         {DeleteIcon}
    //       </span>
    //     );
    //   },
    //   sortable: true,
    // },
  ];

  const [tableHeader, setTableHeader] = useState<any>(headCells);

  return (
    <>
      {showFields && (
        <div>
          <div className="w-[70%]">
            <InputTextField
              name={"company"}
              label={"Company"}
              value={formData}
              error={formErrorData}
              type={"text"}
              disabled={true}
              onChange={(e: any) => {
                setFormData({
                  ...formData,
                  company: formatName(e.target.value),
                });
                setFormErrorData({ ...formErrorData, company: "" });
              }}
              required={true}
              maxLength={AppConstants.MAX_NAME_LENGTH}
              height="50px"
            />
          </div>

          <div className="w-[70%] mt-5">
            <InputTextField
              name={"team"}
              label={"Team"}
              value={formData}
              error={formErrorData}
              type={"text"}
              disabled={true}
              onChange={(e: any) => {
                setFormData({ ...formData, team: formatName(e.target.value) });
                setFormErrorData({ ...formErrorData, team: "" });
              }}
              required={true}
              maxLength={AppConstants.MAX_NAME_LENGTH}
              height="50px"
            />
          </div>
        </div>
      )}

      {!showFields && setAddMoreUser && (
        <div className="w-full flex py-[10px] gap-[20px]">
          {usersDetails?.entityStatus == "DEACTIVATED" && isAdmin ? (
            <div className="w-[100%] p-[12px] bg-red-600 h-[50px]">
              This User Is Deactivated and won't be able to log in.
            </div>
          ) : (
            <></>
          )}
          <TrestleButton
            onClick={() => {
              setAddMoreUser(true);
            }}
            label="Add Team Member"
            type="primary"
            className="!h-[50px] !leading-[40px] !py-[20px] w-[180px] mb-5 !w-fit ml-auto"
            disabled={false}
          />
        </div>
      )}

      <div
        className={`w-full mt-5 bg-white ${!showFields && "p-6"} min-h-[60vh]`}
      >
        <TrestleTable
          isPaginated={false}
          headCells={tableHeader}
          rows={[...teamUserList]}
          clientSort={true}
        />
      </div>
    </>
  );
}

export default Teams;
