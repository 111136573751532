import { createSlice } from "@reduxjs/toolkit";

export const toastSlice = createSlice({
  name: "toastData",
  initialState: {
    open: false,
    severity: "info",
    msg: "",
    autoCloseTime: "",
    heading:"",
    toastIcon:"",
  },
  reducers: {
    setToast: (state, action) => {
      if (action.payload.msg) {
        state.open = action.payload.open;
        state.severity = action.payload.severity;
        state.msg = action.payload.msg;
        state.autoCloseTime = action.payload.autoCloseTime;
        state.heading = action.payload.heading;
        state.toastIcon = action.payload.toastIcon;
      }
    },
  },
});

export const { setToast } = toastSlice.actions;

export default toastSlice.reducer;
