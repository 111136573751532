import * as React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { Dialog } from "@mui/material";
import { useAppSelector } from "../../app/hooks";

export default function LoaderComponent({ loader, msg = "" }: any) {
  const loaderData = useAppSelector((state) => state.loaderReducer);
  const [loaderSate, setLoaderState] = React.useState<any>({
    open: false,
    msg: "",
  });
  React.useEffect(() => {
    setLoaderState(loaderData);
  }, [loaderData]);
  return (
    <>
      <Dialog
        open={loader}
        className="!z-[99999999999999999999]"
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            background: "transparent",
            overflow: "hidden",
            boxShadow: "none",
            color: "#006d88",
          },
        }}
      >
        <CircularProgress
          color="inherit"
          sx={{ height: "75px", width: "75px", margin: "auto" }}
        />
        {msg && (
          <div className="w-full text-center !text-lg !font-semibold mt-4">
            {msg}
          </div>
        )}
      </Dialog>
      {/* {loader && (
        <Box
          id={"component-loader"}
          sx={{
            width: "100%",
            color: "#006D88",
            height: "100%",
            position: "fixed",
            zIndex: "999999999",
            backgroundColor: "#ffffff9c",
            display: "flex",
          }}
        >
          <CircularProgress
            color="inherit"
            style={{ height: "75px", width: "75px", margin: "auto" }}
          />
        </Box>
      )} */}
    </>
  );
}
