import { createSlice } from "@reduxjs/toolkit";

export const walletSlice = createSlice({
  name: "walletData",
  initialState: {
    walletData: {
      walletDisplayType: "",
      walletId: "",
      walletStatus: "",
      balance: "",
      monthlyTransactionLimit: "",
      topUpEligible: true,
      balanceThreshold: "",
      targetBalance: "",
      autoRechargeSuccess: false,
      autoRechargeEnabled: false,
      lastUpdatedDate: 0
    },
    walletUpdateSuccess: true,
    walletFormData: {
      amount: "",
      autoRechargeEnabled: false,
      balanceThreshold: "",
      targetBalance: "",
    },
  },
  reducers: {
    setWallet: (state: any, action) => {
      state.walletData.walletDisplayType = action.payload.walletDisplayType;
      state.walletData.walletId = action.payload.walletId;
      state.walletData.walletStatus = action.payload.walletStatus;
      state.walletData.balance = action.payload.balance;
      state.walletData.topUpEligible = action.payload.topUpEligible;
      state.walletData.monthlyTransactionLimit = action.payload.monthlyTransactionLimit;
      state.walletData.balanceThreshold = action.payload.balanceThreshold;
      state.walletData.targetBalance = action.payload.targetBalance;
      state.walletData.autoRechargeSuccess = action.payload.autoRechargeSuccess;
      state.walletData.autoRechargeEnabled = action.payload.autoRechargeEnabled;
      state.walletData.lastUpdatedDate=action.payload.lastUpdatedDate;
    },
    setWalletUpdateSuccess: (state, action) => {
      state.walletUpdateSuccess = action.payload;
    },
    setWalletFormData: (state, action) => {
      state.walletFormData.amount = action.payload.amount;
      state.walletFormData.autoRechargeEnabled =
        action.payload.autoRechargeEnabled;
      state.walletFormData.balanceThreshold = action.payload.balanceThreshold;
      state.walletFormData.targetBalance = action.payload.targetBalance;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addCase(PURGE, (state) => {
  //     return initialState;
  //   });
  // },
});

export const { setWallet, setWalletUpdateSuccess, setWalletFormData } =
  walletSlice.actions;

export default walletSlice.reducer;
