import { Box, LinearProgress, LinearProgressProps, Typography } from '@mui/material'
import React from 'react'

const ProgressBar = (props: LinearProgressProps & { value: number }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center',gap:'8px' }}>
    <Box sx={{ width: '100%' }}>
      <LinearProgress 
        sx={{
          height: '10px',
          backgroundColor: '#E0F7FA',  
          '& .MuiLinearProgress-bar': {
            backgroundColor: '#0494B4',
          },
        }} 
        variant="determinate" 
        {...props} 
      />
    </Box>
    <Box sx={{ minWidth: 35 }}>
      <Typography
        variant="body2"
        sx={{ color: '#0494B4' }}
      >{`${Math.round(props.value)}%`}</Typography>
    </Box>
  </Box>
  
  )
}

export default ProgressBar
