import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useNavigate } from "react-router-dom";
import { BrandingPage } from "../../Shared/BrandingPage";
import {
  MainHeaderText,
  BrandingPageChildContainer,
} from "../../Shared/CommonComponents";
import InputTextField from "../../Shared/InputTextField";
import Grid from "@mui/material/Grid2";
import { CheckBoxComponent } from "../../Shared/ToggleComponents";
import TrestleButton from "../../Shared/TrestleButton";
import { ForwardArrow } from "../../../assets/svgs";
import { RouteConstants } from "../../../constants/RouteConstants";
import {
  validationEmail,
  validatePhone,
  formatName,
  formatPhone,
  validatePassword,
} from "../../../utils/validations";
import { AppConstants } from "../../../constants/AppConstants";
import { ConstantTexts } from "../../../constants/ConstantTexts";
import DropDown from "../../Shared/DropDown";
import { signUpAPICall } from "../../../http/authentication-service";
import DialogPopup from "../../Shared/DialogPopup";
import Terms from "../Terms/terms";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { setToast } from "../../../app/toastReducer";
import { EncryptPasswordAES } from "../../../utils/passwordEncryption";
import Auth from "@aws-amplify/auth";
import LoaderComponent from "../../Shared/LoaderComponent";

const Optionaloptions = [
  "Search Engines (Google, Bing, etc.)",
  "Internet Ads",
  "LinkedIn",
  "Trestle Blog",
  "Word of Mouth",
  "Referral",
  "Third-Party Review Site (G2, CB Insights)",
  "Other",
];

export const ForceChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const forceUserSession: any = useAppSelector(
    (state: any) => state.authReducer.forceUserSession
  );

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [formData, setFormData] = useState<any>({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [formError, setFormError] = useState<{}>({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (!forceUserSession) {
      navigate(RouteConstants.ROUTE_LOGIN);
    }
  }, []);

  const onPasswordChange = (event: any) => {
    const { name, value } = event.target;

    setFormData({ ...formData, [name]: value });
  };

  const checkEqualPassword = () => {
    const { newPassword = "", confirmPassword = "" } = formData || {};
    if (newPassword === confirmPassword) {
      return "";
    } else {
      return AppConstants.CONFIRM_PASSWORD_NOT_MATCHED;
    }
  };

  const handleValidation = () => {
    const {
      oldPassword = "",
      newPassword = "",
      confirmPassword = "",
    } = formData || {};

    let tempError = {
      oldPassword: validatePassword(oldPassword),
      newPassword: validatePassword(newPassword),
      confirmPassword:
        validatePassword(confirmPassword) || checkEqualPassword(),
    };
    let isError: boolean = true;

    if (
      tempError.oldPassword ||
      tempError.newPassword ||
      tempError.confirmPassword
    ) {
      isError = false;
      setIsLoading(false);
    }

    setFormError({ ...tempError });
    return isError;
  };

  const ChangeYourPassword = async () => {
    const { confirmPassword } = formData;

    setIsLoading(true);
    try {
      if (handleValidation()) {
        Auth.completeNewPassword(forceUserSession, confirmPassword)
          .then((data) => {
            dispatch(
              setToast({
                open: true,
                severity: "success",
                msg: AppConstants.FORCE_PASSWORD_CHANGE_SUCCESS,
              })
            );
            navigate(RouteConstants.ROUTE_LOGIN);
            setIsLoading(false);
          })
          .catch((error: any) => {
            setIsLoading(false);
            dispatch(
              setToast({
                open: true,
                severity: AppConstants.TOAST_ERROR,
                msg: error.message,
              })
            );
            console.log("error: ", error.message);
          });
      }
    } catch (error: any) {
      setIsLoading(false);
      dispatch(
        setToast({
          open: true,
          severity: AppConstants.TOAST_ERROR,
          msg: error.message,
        })
      );
      console.log("error: ", error.message);
    }
  };

  return (
    <>
      {/* <LoaderComponent loader={isLoading} /> */}
      <BrandingPage loader={isLoading}>
        <BrandingPageChildContainer>
          <MainHeaderText>
            {ConstantTexts.FORCE_CHANGE_PASSWORD_HEADER}
          </MainHeaderText>
          <div className="mt-[20px] text-base font-normal text-[#163545]">
            {/* {ConstantTexts.CONFIRM_RESET_PASSWORD_INFO_TEXT} [{userEmail}] */}
          </div>

          <div className="mt-[60px]">
            <Grid container spacing={4}>
              <Grid size={{ sm: 12 }} className="!pt-[25px]">
                <InputTextField
                  name={"oldPassword"}
                  label={"Temporary Password"}
                  value={formData}
                  error={formError}
                  type={"password"}
                  onChange={onPasswordChange}
                  required={true}
                  maxLength={AppConstants.MAX_PASSWORD_LENGTH}
                />
              </Grid>
              <Grid size={{ sm: 12 }} className="!pt-[25px]">
                <InputTextField
                  name={"newPassword"}
                  label={"New Password"}
                  value={formData}
                  error={formError}
                  type={"password"}
                  onChange={onPasswordChange}
                  required={true}
                  maxLength={AppConstants.MAX_PASSWORD_LENGTH}
                  //   info={ConstantTexts.PASSWORD_POLICY_INFO}
                />
              </Grid>
              <Grid size={{ sm: 12 }} className="!pt-[25px]">
                <InputTextField
                  name={"confirmPassword"}
                  label={"Confirm Password"}
                  value={formData}
                  error={formError}
                  type={"password"}
                  onChange={onPasswordChange}
                  required={true}
                  maxLength={AppConstants.MAX_PASSWORD_LENGTH}
                  info={ConstantTexts.PASSWORD_POLICY_INFO}
                />
              </Grid>
            </Grid>
          </div>

          <div className="ml-auto w-[200px] mt-[60px]">
            <TrestleButton
              onClick={() => {
                ChangeYourPassword();
              }}
              label="Reset Password"
              disabled={false}
              type="primary"
              icon={ForwardArrow}
            />
          </div>
        </BrandingPageChildContainer>
      </BrandingPage>
    </>
  );
};
