import React from "react";
import { Box } from "@mui/material";
import TrestleButton from "../../TrestleButton";
import { Text16pxBold } from "../../CommonComponents";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../../constants/RouteConstants";
import { MixPanelEvents } from "../../../../constants/MixPanelEvents";
import { handleMixPanel } from "../../../../utils/mixpanelUtils";
import { useAppSelector } from "../../../../app/hooks";

interface TrialPlanProps {
  walletData: any;
  setAnchorEl: any;
}

const TrialPlanComponent = ({
  walletData = [],
  setAnchorEl,
}: TrialPlanProps) => {
  const navigate = useNavigate();
  
  // wallet exists & user is from a different team but same company, block top-up
  const topUpBlocked = walletData.walletId!=null && parseFloat(walletData.balance) != 0.0;
  
  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: "500px",
        minHeight: "104px",
        padding: "20px",
        display: "flex",
        gap: "10px",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        px: "30px",
      }}
    >
      <TrestleButton
        onClick={() => {
          navigate(`${RouteConstants.ROUTE_PRICING_PAGE}`);
          setAnchorEl(null);
          handleMixPanel(MixPanelEvents.WALLET_TRIAL_UPGRADE_PLAN, {userEmail: userData?.email});
        }}
        label="Upgrade Plan"
        type="primary"
        className="max-w-[180px] !h-[40px]"
        disabled={topUpBlocked}
      />
      {topUpBlocked && (
        <Text16pxBold style={{ color: '#b23352', wordBreak: "break-word", textAlign: "center", maxWidth: "300px" }}>
          Self-Serve plan for your company already exists, please contact support@trestleiq.com for assistance
        </Text16pxBold>
      )}
    </Box>
  );
};

export default TrialPlanComponent;
