import { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import LoaderComponent from "../../Shared/LoaderComponent";
import { Text16pxMedium } from "../../Shared/CommonComponents";
import { getUsageDataV2 } from "../../../http/subscription-service";
import { HTTP_STATUS } from "../../../http/constants/http.status";
import { useDispatch } from "react-redux";
import { setToast } from "../../../app/toastReducer";
import { AppConstants } from "../../../constants/AppConstants";
import moment from "moment";
import { useAppSelector } from "../../../app/hooks";
import { ConstantTexts } from "../../../constants/ConstantTexts";
import { handleMixPanel } from "../../../utils/mixpanelUtils";
import { MixPanelEvents } from "../../../constants/MixPanelEvents";
import EmptyUsage from "../../../assets/empty_usage.svg";
import * as _ from "lodash";
import { Box } from "@mui/material";
import { getTimeDifference } from "../../../utils/timeDifference";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const datasetsColors = [
  {
    borderColor: "#254b87",
    backgroundColor: "#254b87",
  },
  {
    borderColor: "#addc91",
    backgroundColor: "#addc91",
  },
  {
    borderColor: "#9daecc",
    backgroundColor: "#9daecc",
  },
  {
    borderColor: "#257675",
    backgroundColor: "#257675",
  },
  {
    borderColor: "#89b6b5",
    backgroundColor: "#89b6b5",
  },
  {
    borderColor: "#0072ce",
    backgroundColor: "#0072ce",
  },
  {
    borderColor: "#7eb7e8",
    backgroundColor: "#7eb7e8",
  },
  {
    borderColor: "#1fa040",
    backgroundColor: "#1fa040",
  },
];

interface YourUsageProps {
  apiKeyId?: string | number;
  productId?: string;
}

function YourUsage({ apiKeyId = "", productId = "" }: YourUsageProps) {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [plotFilterData, setPlotFilterData] = useState<any>({
    products: [],
    apikeys: [],
    timeline: "Daily",
    timeframe: "Last 30 days",
  });

  const [chartData, setChartData] = useState<any>({ labels: [], datasets: [] });

  const [dateRage, setDateRage] = useState<any>({
    startTime: new Date(),
    endTime: new Date(),
  });

  const [lastUpdatedDate, setLastUpdatedDate] = useState<any>();

  const numberDays: any = {
    "Last 7 days": 7,
    "Last 30 days": 30,
    "Custom range": null,
  };

  const userData: any = useAppSelector(
    (state: any) => state.authReducer.userData
  );

  const userMode: any = useAppSelector(
    (state: any) => state.authReducer.userMode
  );

  useEffect(() => {
    // let ret = formatLineChartData(data);
    // setChartData(ret);
    if (productId) {
      FetchUsageChartData();
    }
    handleMixPanel(MixPanelEvents.VISIT_USER_USAGE_PAGE, {
      userId: userData?.id,
      userEmail: userData?.email,
    });
  }, [productId]);

  const FetchUsageChartData = async () => {
    setIsLoading(true);
    const payload = getPayload();
    const response = await getUsageDataV2(payload);
    const { status = 401, data = {} } = response || {};

    if (status === HTTP_STATUS.HTTP_OK) {
      setIsLoading(false);
      setLastUpdatedDate(data.data.lastUpdatedAt);
      setChartData(formatChartData(data.data));
    } else {
      setIsLoading(false);

      dispatch(
        setToast({
          type: "error",
          message: data.message,
        })
      );
    }
  };

  const formatChartData = (data: any) => {
    const { labels, productUsageData } = data;

    let datasets: any = [];

    Object.keys(productUsageData).forEach((key: string, index: number) => {
      let temp = {
        label: key,
        data: productUsageData[key],
        ...datasetsColors[index],
      };
      datasets.push(temp);
    });

    // return formatLineChartData({ labels, datasets });
    return { labels, datasets };
  };

  const getPayload = () => {
    const { timeframe, timeline } = plotFilterData;
    const { id = "" } = userData;

    let startEndData: any = LastNthDays(timeframe);

    let payload = {
      userIds: [id],
      productIds: [productId],
      apiKeyIds: [apiKeyId],
      ...startEndData,
      usageType: timeline,
    };

    return payload;
  };

  const differenceInMonths = (date1: any, date2: any) => {
    const monthDiff = date1.getMonth() - date2.getMonth();
    const yearDiff = date1.getYear() - date2.getYear();

    return monthDiff + yearDiff * 12;
  };

  const LastNthDays = (timeframeO: string) => {
    let lastDays: number = numberDays[timeframeO];

    if (lastDays !== null) {
      let result = [];
      for (let i = 0; i < lastDays; i++) {
        let d = new Date();
        d.setDate(d.getDate() - i);
        result.push(d);
      }

      return {
        startTime: dateFormat(result[result.length - 1]),
        endTime: dateFormat(result[0]),
      };
    } else {
      const { startTime, endTime } = dateRage;

      return {
        startTime: dateFormat(startTime),
        endTime: dateFormat(endTime),
      };
    }
  };

  const dateFormat = (date: any) => {
    return moment(date).format("YYYY-MM-DD");
  };

  return (
    <>
      <LoaderComponent loader={isLoading} />
      <div className={`bg-[#FFFFFF] w-full`}>
        <Box
          sx={{
            width: "100%",
            minHeight: "355px",
            border: "1px solid #555555",
            marginTop: "30px",
            maxHeight: "700px",
          }}
        >
          {!_.isEmpty(chartData.datasets) ? (
            <>
              <Line
                id="LineUsage"
                data={chartData}
                options={AppConstants.CHART_OPTIONS}
                // className="!w-full"
              />
            </>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: "20px",
              }}
            >
              <Box component="img" src={EmptyUsage} />
            </Box>
          )}
        </Box>
        {lastUpdatedDate && (
          <Text16pxMedium className="text-end mt-[30px]">
            Last updated {getTimeDifference(lastUpdatedDate)} ago
          </Text16pxMedium>
        )}
      </div>
    </>
  );
}

export default YourUsage;
