export class AppConstants {
  static readonly MAX_NAME_LENGTH = 255;
  static readonly MAX_PHONE_NUMBER_LENGTH = 18;
  static readonly MAX_EMAIL_LENGTH = 255;
  static readonly MAX_PASSWORD_LENGTH = 100;
  static readonly MAX_APIKEY_NAME_LENGTH = 32;

  static readonly MAX_LENGTH_25_CHAR = 25;

  static readonly DEFAULT_PAGE_NUMBER = 0;
  static readonly MAX_PAGE_SIZE = 10000;

  // Success messages
  static readonly EMAIL_VERIFICATION_MSG =
    "Your account has been verified successfully, you can login now";
  static readonly RESEND_VERIFICATION_SUCCESS =
    "Verification code resent successfully";
  static readonly FORGOT_PASSWORD_MAIL_SEND =
    "Thanks! If there's an account associated with this email, we'll send the verification code";
  static readonly FORGOT_PASSWORD_MAIL_SENT =
    "Thanks! If there's an account associated with this email, we'll send the verification code";
  static readonly PASSWORD_RESET_SUCCESS =
    "Your password has been change successfully, you can login now";

  static readonly PROFILE_UPDATED = "User profile updated successfully";
  static readonly CONFIRM_PASSWORD_NOT_MATCHED =
    "Confirm Password is not matched with New Password";

  static readonly PRODUCT_ACCESS_GRANTED =
    "Product access granted successfully";
  static readonly PRODUCT_ACCESS_DENIED = "Product access denied successfully";
  static readonly APIKEY_REVOKE_SUCCESS = "API key revoked successfully";

  static readonly PASSWORD_CHANGE_SUCCESS =
    "Your password has been change successfully";
  static readonly FORCE_PASSWORD_CHANGE_SUCCESS =
    "Your password has been change successfully. Now you can login with your new password";

  static readonly MAXIO_CUSTOMER_ID_FETCH_SUCESS =
    "Maxio Customer ID set successfully";

  //   Error messages
  static readonly REQUIRED_FILED_ERROR = "Required";
  static readonly FIRSTNAME_ERROR_MSG = "Enter a valid first name";
  static readonly LASTNAME_ERROR_MSG = "Enter a valid last name";
  static readonly EMAIL_ERROR_MSG = "Enter a valid email address";
  static readonly PHONE_ERROR_MSG = "Please Enter a valid phone number";
  static readonly PASSWORD_ERROR_MSG = "Enter a valid password";
  static readonly TERMS_ACCEPT_FIRST_MSG = "Please accept terms of service";
  static readonly INVALID_VERIFICATION_CODE = "Invalid Verification Code";
  static readonly APIKEY_CREATE_SUCCESS = "New API key created successfully";
  static readonly SUBSCRIPTION_UPDATE_SUCCESS =
    "Subscription updated successfully";
  static readonly GENERIC_ERROR_MESSAGE = "Something went wrong";

  // Toast msg types
  static readonly TOAST_SUCCESS = "success";
  static readonly TOAST_WARNING = "warning";
  static readonly TOAST_ERROR = "error";
  static readonly TOAST_INFO = "info";

  static readonly TOAST_AUTOHIDE_TIME = 6000;

  static readonly TOAST_BACKGROUND_COLOR: any = {
    success: "#00B47E",
    error: "#B23352",
    info: "#0494B4",
    warning: "#F78331",
  };

  // API URL's

  // Authentication service
  static readonly SIGNUP_URL = "/auth/signup";
  static readonly SIGNUP_CONFIRMATION_AWS_COGNITO = "/?Action=ConfirmSignUp";
  static readonly VERIFY_EMAIL_CHECK = "/auth/verify-email-check?email=<email>";
  static readonly GET_USER = "/auth/get-user";
  static readonly UPDATE_USER = "/auth/update-user";

  // batch service
  static readonly GET_BATCH = "/batch-execution/get-files";
  static readonly DOWNLOAD_BATCH = "/batch-execution/download/";
  static readonly SUBMIT_BATCH = "/batch-execution/submit";

  static readonly GET_BATCHES_ADMIN = "/batch-execution/get-all-batches";

  static readonly GET_API_KEY_AND_PRODUCTS =
    "/subscription/get-api-keys-and-product";

  // User migration force change password status
  static readonly FORCE_CHANGE_PASSWORD_MIGRATION = "NEW_PASSWORD_REQUIRED";

  // Product access status
  static readonly STATUS_AVALIABLE = "AVAILABLE";
  static readonly STATUS_CAN_REQUEST = "CAN_REQUEST";
  static readonly STATUS_DENIED = "DENIED";
  static readonly STATUS_UNAVALIABLE = "UNAVAILABLE";
  static readonly STATUS_REMOVE = "REMOVE";
  static readonly STATUS_REQUESTED = "REQUESTED";
  static readonly STATUS_NOT_IN_USE = "NOT_IN_USE";

  static readonly SUBSCRIPTION_EXPIRED = "EXPIRED";

  //admin service
  static readonly GET_MANAGE_PRODUCT_ACCESS =
    "/subscription/pending-product-access";
  static readonly CHANGE_PRODUCT_ACCESS = "/subscription/change-product-access";
  static readonly GET_ALL_USERS = "/auth/get-all-user";
  static readonly GET_ALL_MAXIO_CUSTOMERS = "/maxio/get-customers";
  static readonly SET_MAXIO_CUSTOMER_ID = "/maxio/set-customer-id";
  static readonly GET_MAXIO_SUBSCRIPTIONS_FOR_CUSTOMER =
    "/maxio/get-subscription-and-component-info";

  static readonly PRICING_PLAN_URL = "https://trestleiq.com/pricing";
  static readonly TRESTLE_DOCUMENT_URL =
    "https://trestleiq.com/docs#section/Overview";
  static readonly TRESTLE_CONTACT_US_URL =
    "https://trestleiq.com/docs#section/Overview";

  // Subscription service
  static readonly GET_API_AND_PRODUCT_LIST =
    "/subscription/user-api-and-product";
  static readonly REVOKE_API_KEY = "/subscription/revoke-key";
  static readonly CREATE_API_KEY =
    "/subscription/create-api-key?email={{email}}";

  static readonly GET_USAGE_DATA = "/usage/user";

  // Version 2
  static readonly GET_USAGE_DATA_V2 = "/usage/v2/user";

  static readonly GET_ADMIN_USAGE_DATA = "/usage/chart-data-total-usage";
  static readonly GET_USER_USAGE_DATA_AS_PER_CUSTOMR =
    "/usage/chart-data-usage-per-customer";
  static readonly GET_USAGE_DATA_AS_PER_APIKEY =
    "/usage/chart-data-usage-per-key";
  static readonly GET_ALL_PRODUCTS = "/subscription/get-all-products";
  static readonly GET_API_PRODUCTS_OF_USER =
    "/subscription/get-user-keys-and-product?userId={{userId}}";

  static readonly EXPORT_CSV = "/usage/export-csv-usage-per-key";

  // version 2
  static readonly EXPORT_CSV_V2 = "/usage/v2/export-csv-usage-per-key";

  static readonly EXPORT_MASTER_REPORT_CSV = "/usage/export-csv-total-usage";

  // version 2
  static readonly EXPORT_MASTER_REPORT_CSV_V2 =
    "/usage/v2/export-csv-total-usage";

  static readonly EXPORT_CSV_ADMIN_USAGE = "/usage/export-csv-total-usage";
  static readonly EXPORT_CSV_USER_USAGE_DATA_AS_PER_CUSTOMR =
    "/usage/export-csv-usage-per-customer";

  static readonly GET_SUBSCRIPTION_FOR_USER =
    "/subscription/user-subscriptions?email={{email}}";
  static readonly UPDATE_SUBSCRIPTIOIN = "/subscription/update";
  static readonly UPDATE_SUBSCRIPTIOIN_NAME =
    "/subscription/change-api-key-name?subscription_id={{id}}&new_key_name={{name}}";

  static readonly GET_CUSTOM_ATTRIBUTES_DROPDOWN_VALUE =
    "/subscription/get-default-config";

  static readonly CUSTOMER_USAGE_REPORT = "/usage/get-customer-usage-data";

  //Version 2
  static readonly CUSTOMER_USAGE_REPORT_V2 =
    "/usage/v2/get-customer-usage-data";

  static readonly GET_SELF_SERVE_USAGE = "/usage/v2/get-self-serve-usage";

  static readonly UPLOAD_CSV = "/subscription/upload-csv";

  // Teams service
  static readonly GET_TEAM_USERS = "/team/get-team-users?teamId={{id}}";
  static readonly CREATE_TEAM_INVITATION = "/team-invite/create";
  static readonly GET_TEAM_INVITE = "/team-invite/get?user_id={{id}}";
  static readonly UPDATE_TEAM_INVITATION = "/team-invite/update";
  static readonly SIGN_OUT_USER = "/auth/log-out";

  static readonly CHART_OPTIONS = {
    responsive: true,
    plugins: {
      legend: {
        position: "right" as const,
      },
    },
    scales: {
      y: {
        suggestedMin: 0,
        suggestedMax: 50,
      },
    },
  };

  // Company service
  static readonly UPDATE_COMPANY_TEAM_NAME =
    "/company/update-company-team-name";

  // Add New User
  static readonly ADD_NEW_USER = "/auth/signup-by-admin";

  static readonly ADD_NEW_USER_TO_TEAM = "/auth/add-user-to-team";

  static readonly UPDATE_USER_STATUS = "/auth/change-user-status";

  static readonly VERIFY_USER = "/auth/verify-user?email={{email}}";

  static readonly RESET_USER_PASSWORD =
    "/auth/reset-user-password?email={{email}}";

  static readonly DUMMY_API_RESPONSE = {
    "Caller Identification 3.1": {
      id: "Phone.3dbb6fef-a2df-4b08-cfe3-bc7128b6f5b4",
      phone_number: "2069735100",
      is_valid: true,
      country_calling_code: "1",
      line_type: "NonFixedVOIP",
      carrier: "Trestle Telco",
      is_prepaid: false,
      is_commercial: true,
      belongs_to: {
        id: "Person.fffdcf06-0929-4b5a-9921-ee49b101ca84",
        name: "Waidong L Syrws",
        firstname: "Waidong",
        middlename: "L",
        lastname: "Syrws",
        alternate_names: ["Sryws W L"],
        age_range: "25-29",
        gender: null,
        type: "Person",
        link_to_phone_start_date: "2019-03-23",
        industry: null,
      },
      current_addresses: [
        {
          id: "Location.d1a40ed5-a70a-46f8-80a9-bb4ac27e3a01",
          location_type: "Address",
          street_line_1: "100 Syrws St",
          street_line_2: "Ste 1",
          city: "Lynden",
          postal_code: "98264",
          zip4: "98264-9999",
          state_code: "WA",
          country_code: "US",
          lat_long: {
            latitude: 0,
            longitude: 0,
            accuracy: "Neighborhood",
          },
          is_active: true,
          delivery_point: "SingleUnit",
          link_to_person_start_date: "2011-10-05",
        },
      ],
      emails: "waidong.syrws@email.com",
    },
    "Caller Identification 3.0": {
      id: "Phone.3dbb6fef-a2df-4b08-cfe3-bc7128b6f5b4",
      phone_number: "2069735100",
      is_valid: true,
      country_calling_code: "1",
      line_type: "NonFixedVOIP",
      carrier: "Trestle Telco",
      is_prepaid: false,
      is_commercial: true,
      belongs_to: {
        id: "Person.fffdcf06-0929-4b5a-9921-ee49b101ca84",
        name: "Waidong L Syrws",
        firstname: "Waidong",
        middlename: "L",
        lastname: "Syrws",
        alternate_names: ["Sryws W L"],
        age_range: "25-29",
        gender: null,
        type: "Person",
        link_to_phone_start_date: "2019-03-23",
        industry: null,
      },
      current_addresses: [
        {
          id: "Location.d1a40ed5-a70a-46f8-80a9-bb4ac27e3a01",
          location_type: "Address",
          street_line_1: "100 Syrws St",
          street_line_2: "Ste 1",
          city: "Lynden",
          postal_code: "98264",
          zip4: "98264-9999",
          state_code: "WA",
          country_code: "US",
          lat_long: {
            latitude: 0,
            longitude: 0,
            accuracy: "Neighborhood",
          },
          is_active: true,
          delivery_point: "SingleUnit",
          link_to_person_start_date: "2011-10-05",
        },
      ],
    },
    "Phone Intel 3.0": {
      id: "Phone.3dbb6fef-a2df-4b08-cfe3-bc7128b6f5b4",
      phone_number: "2069735100",
      is_valid: true,
      activity_score: 57,
      country_calling_code: "1",
      country_code: "US",
      country_name: "United States",
      line_type: "NonFixedVOIP",
      carrier: "Level 3 Communications, LLC",
      is_prepaid: false,
    },
    "Real Contact 1.1": {
      "phone.is_valid": true,
      "phone.activity_score": 57,
      "phone.line_type": "Mobile",
      "phone.name_match": true,
      "phone.contact_grade": "A",
      "email.is_valid": true,
      "email.name_match": true,
      "email.contact_grade": "B",
      "address.is_valid": true,
      "address.name_match": true,
    },
    "Real Contact 1.0": {
      "phone.is_valid": true,
      "phone.activity_score": 57,
      "phone.line_type": "Mobile",
      "phone.name_match": true,
      contact_grade: "A",
    },
    "Reverse Phone 3.2": {
      id: "Phone.3dbb6fef-a2df-4b08-cfe3-bc7128b6f5b4",
      phone_number: "2069735100",
      is_valid: true,
      country_calling_code: "1",
      line_type: "NonFixedVOIP",
      carrier: "Trestle Telco",
      is_prepaid: false,
      is_commercial: true,
      owners: [
        {
          id: "Person.fffdcf06-0929-4b5a-9921-ee49b101ca84",
          name: "Waidong L Syrws",
          firstname: "Waidong",
          middlename: "L",
          lastname: "Syrws",
          alternate_names: ["Sryws W L"],
          age_range: "25-29",
          gender: null,
          type: "Person",
          link_to_phone_start_date: "2019-03-23",
          industry: null,
          current_addresses: [
            {
              id: "Location.d1a40ed5-a70a-46f8-80a9-bb4ac27e3a01",
              location_type: "Address",
              street_line_1: "100 Syrws St",
              street_line_2: "Ste 1",
              city: "Lynden",
              postal_code: "98264",
              zip4: "98264-9999",
              state_code: "WA",
              country_code: "US",
              lat_long: {
                latitude: 0,
                longitude: 0,
                accuracy: "Neighborhood",
              },
              delivery_point: "SingleUnit",
              link_to_person_start_date: "2011-10-05",
            },
          ],
          alternate_phones: [
            {
              phone_number: "2069735100",
              line_type: "NonFixedVOIP",
            },
          ],
          emails: "waidong.syrws@email.com",
        },
      ],
    },
    "Reverse Phone 3.1": {
      id: "Phone.3dbb6fef-a2df-4b08-cfe3-bc7128b6f5b4",
      phone_number: "2069735100",
      is_valid: true,
      country_calling_code: "1",
      line_type: "NonFixedVOIP",
      carrier: "Trestle Telco",
      is_prepaid: false,
      is_commercial: true,
      belongs_to: {
        id: "Person.fffdcf06-0929-4b5a-9921-ee49b101ca84",
        name: "Waidong L Syrws",
        firstname: "Waidong",
        middlename: "L",
        lastname: "Syrws",
        alternate_names: ["Sryws W L"],
        age_range: "25-29",
        gender: null,
        type: "Person",
        link_to_phone_start_date: "2019-03-23",
        industry: null,
      },
      current_addresses: [
        {
          id: "Location.d1a40ed5-a70a-46f8-80a9-bb4ac27e3a01",
          location_type: "Address",
          street_line_1: "100 Syrws St",
          street_line_2: "Ste 1",
          city: "Lynden",
          postal_code: "98264",
          zip4: "98264-9999",
          state_code: "WA",
          country_code: "US",
          lat_long: {
            latitude: 0,
            longitude: 0,
            accuracy: "Neighborhood",
          },
          is_active: true,
          delivery_point: "SingleUnit",
          link_to_person_start_date: "2011-10-05",
        },
      ],
      historical_addresses: [
        {
          id: "Location.d1a40ed5-a70a-46f8-80a9-bb4ac27e3a01",
          location_type: "Address",
          street_line_1: "100 Syrws St",
          street_line_2: "Ste 1",
          city: "Lynden",
          postal_code: "98264",
          zip4: "98264-9999",
          state_code: "WA",
          country_code: "US",
          lat_long: {
            latitude: 0,
            longitude: 0,
            accuracy: "Neighborhood",
          },
          is_active: true,
          delivery_point: "SingleUnit",
          link_to_person_start_date: "2011-10-05",
          link_to_person_end_date: null,
        },
      ],
      associated_people: [
        {
          id: "Person.fffdcf06-0929-4b5a-9921-ee49b101ca84",
          name: "Waidong L Syrws",
          firstname: "Waidong",
          middlename: "L",
          lastname: "Syrws",
          relation: "Household",
        },
      ],
      alternate_phones: [
        {
          id: "Phone.3dbb6fef-a2df-4b08-cfe3-bc7128b6f5b4",
          phone_number: "2069735100",
          line_type: "NonFixedVOIP",
        },
      ],
    },
    "Reverse Phone 3.0": {
      id: "Phone.3dbb6fef-a2df-4b08-cfe3-bc7128b6f5b4",
      phone_number: "2069735100",
      is_valid: true,
      country_calling_code: "1",
      line_type: "NonFixedVOIP",
      carrier: "Trestle Telco",
      is_prepaid: false,
      is_commercial: true,
      belongs_to: {
        id: "Person.fffdcf06-0929-4b5a-9921-ee49b101ca84",
        name: "Waidong L Syrws",
        firstname: "Waidong",
        middlename: "L",
        lastname: "Syrws",
        alternate_names: ["Sryws W L"],
        age_range: "25-29",
        gender: null,
        type: "Person",
        link_to_phone_start_date: "2019-03-23",
        industry: null,
      },
      current_addresses: [
        {
          id: "Location.d1a40ed5-a70a-46f8-80a9-bb4ac27e3a01",
          location_type: "Address",
          street_line_1: "100 Syrws St",
          street_line_2: "Ste 1",
          city: "Lynden",
          postal_code: "98264",
          zip4: "98264-9999",
          state_code: "WA",
          country_code: "US",
          lat_long: {
            latitude: 0,
            longitude: 0,
            accuracy: "Neighborhood",
          },
          is_active: true,
          delivery_point: "SingleUnit",
          link_to_person_start_date: "2011-10-05",
        },
      ],
      historical_addresses: [
        {
          id: "Location.d1a40ed5-a70a-46f8-80a9-bb4ac27e3a01",
          location_type: "Address",
          street_line_1: "100 Syrws St",
          street_line_2: "Ste 1",
          city: "Lynden",
          postal_code: "98264",
          zip4: "98264-9999",
          state_code: "WA",
          country_code: "US",
          lat_long: {
            latitude: 0,
            longitude: 0,
            accuracy: "Neighborhood",
          },
          is_active: true,
          delivery_point: "SingleUnit",
          link_to_person_start_date: "2011-10-05",
          link_to_person_end_date: null,
        },
      ],
      associated_people: [
        {
          id: "Person.fffdcf06-0929-4b5a-9921-ee49b101ca84",
          name: "Waidong L Syrws",
          firstname: "Waidong",
          middlename: "L",
          lastname: "Syrws",
          relation: "Household",
        },
      ],
      alternate_phones: [
        {
          id: "Phone.3dbb6fef-a2df-4b08-cfe3-bc7128b6f5b4",
          phone_number: "2069735100",
          line_type: "NonFixedVOIP",
        },
      ],
    },
    "Smart CNAM 3.1": {
      id: "Phone.3dbb6fef-a2df-4b08-cfe3-bc7128b6f5b4",
      is_valid: true,
      belongs_to: {
        id: "Person.fffdcf06-0929-4b5a-9921-ee49b101ca84",
        name: "Waidong L Syrws",
        firstname: "Waidong",
        middlename: "L",
        lastname: "Syrws",
      },
    },
    "Phone Data 1.0": {
      id: "Phone.3dbb6fef-a2df-4b08-cfe3-bc7128b6f5b4",
      phone_number: "2069735100",
      is_valid: true,
      country_calling_code: "1",
      line_type: "NonFixedVOIP",
      carrier: "Trestle Telco",
      activity_score: 100,
      is_prepaid: false,
      is_commercial: true,
      owners: [
        {
          id: "Person.fffdcf06-0929-4b5a-9921-ee49b101ca84",
          name: "Waidong L Syrws",
          firstname: "Waidong",
          middlename: "L",
          lastname: "Syrws",
          alternate_names: ["Sryws W L"],
          age_range: "25-29",
          gender: null,
          type: "Person",
          link_to_phone_start_date: "2019-03-23",
          industry: null,
          current_addresses: [
            {
              id: "Location.d1a40ed5-a70a-46f8-80a9-bb4ac27e3a01",
              location_type: "Address",
              street_line_1: "100 Syrws St",
              street_line_2: "Ste 1",
              city: "Lynden",
              postal_code: "98264",
              zip4: "98264-9999",
              state_code: "WA",
              country_code: "US",
              lat_long: {
                latitude: 0,
                longitude: 0,
                accuracy: "Neighborhood",
              },
              delivery_point: "SingleUnit",
              link_to_person_start_date: "2011-10-05",
            },
          ],
          alternate_phones: [
            {
              phone_number: "2069735100",
              line_type: "NonFixedVOIP",
            },
          ],
          emails: "waidong.syrws@email.com",
        },
      ],
    },
    "Find Person 3.1": {
      count_person: 1,
      person: [
        {
          id: "Person.2a0ba78f-d2a7-3030-bb07-8a2b78dce965",
          name: "Waidong L Syrws",
          firstname: "Waidong",
          middlename: "L",
          lastname: "Syrws",
          alternate_names: [],
          age_range: "25-29",
          gender: null,
          found_at_address: {
            id: "Location.7d0feca6-e04b-4cee-9bfb-1325f2f7e261",
            location_type: "Address",
            street_line_1: "100 Syrws St Ste 1",
            street_line_2: null,
            city: "Lynden",
            postal_code: "98264",
            zip4: "5950",
            state_code: "WA",
            country_code: null,
            lat_long: {
              latitude: 47.6835,
              longitude: -122.1691,
              accuracy: "RoofTop",
            },
            is_active: null,
            delivery_point: "SingleUnit",
            link_to_person_start_date: "2017-06-14",
          },
          current_addresses: [
            {
              id: "Location.7d0feca6-e04b-4cee-9bfb-1325f2f7e261",
              location_type: "Address",
              street_line_1: "100 Syrws St Ste 1",
              street_line_2: null,
              city: "Lynden",
              postal_code: "98264",
              zip4: "5950",
              state_code: "WA",
              country_code: null,
              lat_long: {
                latitude: 47.6835,
                longitude: -122.1691,
                accuracy: "RoofTop",
              },
              is_active: null,
              delivery_point: "SingleUnit",
              link_to_person_start_date: "2017-06-14",
            },
          ],
          historical_addresses: [],
          phones: [
            {
              id: "Phone.0f0e2097-279f-4315-91f3-61142bb0ce6e",
              phone_number: "+12069735100",
              line_type: "Mobile",
            },
          ],
          emails: ["waidong.syrws@email.com"],
          associated_people: [],
        },
      ],
    },
    "Find Person 3.0": {
      count_person: 1,
      person: [
        {
          id: "Person.2a0ba78f-d2a7-3030-bb07-8a2b78dce965",
          name: "Waidong L Syrws",
          firstname: "Waidong",
          middlename: "L",
          lastname: "Syrws",
          alternate_names: [],
          age_range: "25-29",
          gender: null,
          found_at_address: {
            id: "Location.7d0feca6-e04b-4cee-9bfb-1325f2f7e261",
            location_type: "Address",
            street_line_1: "100 Syrws St Ste 1",
            street_line_2: null,
            city: "Lynden",
            postal_code: "98264",
            zip4: "5950",
            state_code: "WA",
            country_code: null,
            lat_long: {
              latitude: 47.6835,
              longitude: -122.1691,
              accuracy: "RoofTop",
            },
            is_active: null,
            delivery_point: "SingleUnit",
            link_to_person_start_date: "2017-06-14",
          },
          current_addresses: [
            {
              id: "Location.7d0feca6-e04b-4cee-9bfb-1325f2f7e261",
              location_type: "Address",
              street_line_1: "100 Syrws St Ste 1",
              street_line_2: null,
              city: "Lynden",
              postal_code: "98264",
              zip4: "5950",
              state_code: "WA",
              country_code: null,
              lat_long: {
                latitude: 47.6835,
                longitude: -122.1691,
                accuracy: "RoofTop",
              },
              is_active: null,
              delivery_point: "SingleUnit",
              link_to_person_start_date: "2017-06-14",
            },
          ],
          historical_addresses: [],
          phones: [
            {
              id: "Phone.0f0e2097-279f-4315-91f3-61142bb0ce6e",
              phone_number: "+12069735100",
              line_type: "Mobile",
            },
          ],
          associated_people: [],
        },
      ],
    },
    "Reverse Address 3.0": {
      id: "Location.075faec7-bdd5-401a-839c-fd474707dc81",
      is_valid: true,
      street_line_1: "100 Syrws St Ste 1",
      street_line_2: null,
      city: "Lynden",
      postal_code: "98264",
      zip4: "5950",
      state_code: "WA",
      country_code: "US",
      lat_long: {
        latitude: 47.6835,
        longitude: -122.1691,
        accuracy: "RoofTop",
      },
      is_active: null,
      is_commercial: false,
      is_forwarder: null,
      delivery_point: "SingleUnit",
      current_residents: [
        {
          id: "Person.e9e7d1f5-34a8-3506-87f2-670462b6f8f0",
          name: "Waidong L Syrws",
          firstname: "Waidong",
          middlename: "L",
          lastname: "Syrws",
          alternate_names: [],
          age_range: "25-29",
          gender: null,
          type: "Person",
          industry: null,
          link_to_address_start_date: "2023-11-19",
          phones: [
            {
              id: "Phone.d5d28a15-84d8-4728-a6db-f74ff08f4594",
              phone_number: "+12069735100",
              line_type: "Mobile",
            },
          ],
          historical_addresses: [],
          associated_people: [],
        },
      ],
    },
    "Reverse Address 3.1": {
      id: "Location.075faec7-bdd5-401a-839c-fd474707dc81",
      is_valid: true,
      street_line_1: "100 Syrws St Ste 1",
      street_line_2: null,
      city: "Lynden",
      postal_code: "98264",
      zip4: "5950",
      state_code: "WA",
      country_code: "US",
      lat_long: {
        latitude: 47.6835,
        longitude: -122.1691,
        accuracy: "RoofTop",
      },
      is_active: null,
      is_commercial: false,
      delivery_point: "MultiUnit",
      current_residents: [
        {
          id: "Person.e9e7d1f5-34a8-3506-87f2-670462b6f8f0",
          name: "Waidong L Syrws",
          firstname: "Waidong",
          middlename: "L",
          lastname: "Syrws",
          alternate_names: [],
          age_range: "25-29",
          gender: null,
          type: "Person",
          industry: null,
          link_to_address_start_date: "2023-11-19",
          phones: [
            {
              id: "Phone.d5d28a15-84d8-4728-a6db-f74ff08f4594",
              phone_number: "+12069735100",
              line_type: "Mobile",
            },
          ],
          emails: ["waidong.syrws@email.com"],
          historical_addresses: [],
          associated_people: [],
        },
      ],
    },
    "Location Intel 3.0": {
      id: "Location.be99d8fb-3960-4d70-9669-2ab2553cad97",
      is_valid: true,
      street_line_1: "100 Syrws St Ste 1",
      street_line_2: null,
      city: "Lynden",
      postal_code: "98264",
      zip4: "5950",
      state_code: "WA",
      country_code: "US",
      lat_long: {
        latitude: 47.6835,
        longitude: -122.1691,
        accuracy: "RoofTop",
      },
    },
  };

  //Self Serve Wallet Services
  static readonly GET_WALLET_DATA = "/wallet/fetch?email={{email}}";
  static readonly WALLET_CHECKOUT_SESSION = "/wallet/checkout";
  static readonly UPDATE_AUTO_RECHARGE_INFO = "/wallet/update-auto-recharge";
  static readonly MANAGE_SUBSCRIPTION_SESSION = "/wallet/manage-billing";
  static readonly UPDATE_MONTHLY_TXN_LIMIT =
    "/wallet/config/monthly-transaction-limit";

  static readonly GET_MAXIO_CUSTOMER_ID =
    "/maxio/get-customer-id-for-user?email={{email}}";
  static readonly GET_MAXIO_BILLING_URL =
    "/maxio/get-billing-url?email={{email}}";

  static readonly DEFAULT_MONTHLY_SPEND_LIMIT = 750;
  static readonly MAX_MONTHLY_SPEND_LIMIT = 5000;

  // Validation Massages
  static readonly WALLET_AMOUNT =
    "Please input a value between $25 and $5000 to set your wallet top-up amount.";
  static readonly WALLET_THRESHOLD_AMOUNT =
    "Please input a value between $10 and $5000 to set your auto-recharge threshold amount.";
  static readonly WALLET_AUTO_RECHARGE_AMOUNT =
    "Please input a value between $25 and $5000 to set your auto-recharge top-up amount.";

  static readonly API_KEY_PRODUCT_LIST = [
    {
      heading: "Validation & Verification",
      subHeading:
        "Validate and verify phone numbers, emails, and addresses and identify disconnected numbers.",
      products: [
        { name: "Phone Validation API", iconBoxColor: "#44DAD3" },
        { name: "Real Contact API", iconBoxColor: "#44DAD3" },
        { name: "Address Validation API", iconBoxColor: "#44DAD3" },
      ],
    },
    {
      heading: "Enrichment",
      subHeading:
        "Enrich your data with additional information on phone numbers, addresses, and individual information.",
      products: [
        { name: "Reverse Phone API", iconBoxColor: "#0494B4" },
        { name: "Reverse Address API", iconBoxColor: "#0494B4" },
        { name: "Find Person API", iconBoxColor: "#0494B4" },
      ],
    },
    {
      heading: "Telephony",
      subHeading:
        "Retrieve accurate caller identification information for call operations purposes.",
      products: [
        { name: "Caller Identification API", iconBoxColor: "#F78331" },
        { name: "Smart CNAM API", iconBoxColor: "#F78331" },
      ],
    },
  ];

  static readonly DUMMY_INITIAL_API_RESPONSE = {
    "Caller Identification 3.1": {
      id: "",
      phone_number: "",
      is_valid: "",
      country_calling_code: "",
      line_type: "",
      carrier: "",
      is_prepaid: "",
      is_commercial: "",
      belongs_to: {
        name: "",
        age_range: "",
        gender: "",
        type: "",
        industry: "",
        link_to_phone_start_date: "",
      },
      current_address: "",
      emails: "",
      error: "",
      warnings: "",
    },
    "Caller Identification 3.0": {
      id: "",
      phone_number: "",
      is_valid: "",
      country_calling_code: "",
      line_type: "",
      carrier: "",
      is_prepaid: "",
      is_commercial: "",
      belongs_to: {
        name: "",
        age_range: "",
        gender: "",
        type: "",
        industry: "",
        link_to_phone_start_date: "",
      },
      current_address: "",
      error: "",
      warnings: [],
    },
    "Phone Intel 3.0": {
      id: "",
      phone_number: "",
      is_valid: "",
      activity_score: "",
      country_calling_code: "",
      country_code: "",
      country_name: "",
      line_type: "",
      carrier: "",
      is_prepaid: "",
    },
    "Real Contact 1.1": {
      "phone.is_valid": "",
      "phone.activity_score": "",
      "phone.line_type": "",
      "phone.name_match": "",
      "phone.contact_grade": "",
      "email.is_valid": "",
      "email.name_match": "",
      "email.contact_grade": "",
      "address.is_valid": "",
      "address.name_match": "",
    },
    "Real Contact 1.0": {
      "phone.is_valid": "",
      "phone.activity_score": "",
      "phone.line_type": "",
      "phone.name_match": "",
      contact_grade: "",
    },
    "Reverse Phone 3.2": {
      id: "",
      phone_number: "",
      is_valid: "",
      country_calling_code: "",
      line_type: "",
      carrier: "",
      is_prepaid: "",
      is_commercial: "",
      owners: [
        {
          name: "",
          age_range: "",
          gender: "",
          type: "",
          industry: "",
          link_to_phone_start_date: "",
          current_addresses: {},
          alternate_phones: [],
          emails: [],
        },
      ],
      error: "",
      warnings: [],
    },
    "Reverse Phone 3.1": {
      id: "",
      phone_number: "",
      is_valid: "",
      country_calling_code: "",
      line_type: "",
      carrier: "",
      is_prepaid: "",
      is_commercial: "",
      belongs_to: {
        id: "",
        name: "",
        firstname: "",
        middlename: "",
        lastname: "",
        alternate_names: [],
        age_range: "",
        gender: "",
        type: "",
        link_to_phone_start_date: "",
        industry: "",
      },
      current_addresses: [],
      historical_addresses: [],
      associated_people: [
        {
          id: "",
          name: "",
          firstname: "",
          middlename: "",
          lastname: "",
          relation: "",
        },
      ],
      alternate_phones: [],
    },
    "Reverse Phone 3.0": {
      id: "",
      phone_number: "",
      is_valid: "",
      country_calling_code: "",
      line_type: "",
      carrier: "",
      is_prepaid: "",
      is_commercial: "",
      belongs_to: {
        id: "",
        name: "",
        firstname: "",
        middlename: "",
        lastname: "",
        alternate_names: [],
        age_range: "",
        gender: "",
        type: "",
        link_to_phone_start_date: "",
        industry: "",
      },
      current_addresses: [],
      historical_addresses: [],
      associated_people: [
        {
          id: "",
          name: "",
          firstname: "",
          middlename: "",
          lastname: "",
          relation: "",
        },
      ],
      alternate_phones: [],
    },
    "Smart CNAM 3.1": {
      id: "",
      is_valid: "",
      belongs_to: {
        id: "",
        name: "",
        firstname: "",
        middlename: "",
        lastname: "",
      },
    },
    "Phone Data 1.0": {
      id: "",
      phone_number: "",
      is_valid: "",
      country_calling_code: "",
      line_type: "",
      carrier: "",
      activity_score: "",
      is_prepaid: "",
      is_commercial: "",
      owners: [
        {
          id: "",
          name: "",
          firstname: "",
          middlename: "",
          lastname: "",
          alternate_names: [],
          age_range: "",
          gender: "",
          type: "",
          link_to_phone_start_date: "",
          industry: "",
          current_addresses: [],
          alternate_phones: [],
          emails: "",
        },
      ],
    },
    "Find Person 3.1": {
      count_person: "",
      person: [
        {
          id: "",
          name: "",
          age_range: "",
          gender: "",
          found_at_address: {
            location_type: "",
            street_line_1: "",
            city: "",
            postal_code: "",
            zip4: "",
            state_code: "",
            lat_long: "",
          },
          current_addresses: "",
          historical_addresses: "",
          phones: "",
          emails: "",
          associated_people: "",
        },
      ],
      error: "",
      warnings: "",
    },
    "Find Person 3.0": {
      count_person: "",
      person: [
        {
          id: "",
          name: "",
          age_range: "",
          gender: "",
          found_at_address: {
            location_type: "",
            street_line_1: "",
            city: "",
            postal_code: "",
            zip4: "",
            state_code: "",
            lat_long: "",
          },
          current_addresses: "",
          historical_addresses: "",
          phones: "",
          associated_people: "",
        },
      ],
      error: "",
      warnings: [],
    },
    "Reverse Address 3.0": {
      id: "",
      is_valid: "",
      street_line_1: "",
      city: "",
      postal_code: "",
      zip4: "",
      state_code: "",
      lat_long: "",
      is_active: "",
      is_commercial: "",
      delivery_point: "",
      current_residents: {
        name: "",
        age_range: "",
        gender: "",
        link_to_address_start_date: "",
        phones: "",
        historical_addresses: "",
        associated_people: "",
      },
      error: "",
      warnings: "",
    },
    "Reverse Address 3.1": {
      id: "",
      is_valid: "",
      street_line_1: "",
      city: "",
      postal_code: "",
      zip4: "",
      state_code: "",
      lat_long: "",
      is_active: "",
      is_commercial: "",
      delivery_point: "",
      current_residents: {
        name: "",
        age_range: "",
        gender: "",
        link_to_address_start_date: "",
        phones: "",
        emails: "",
        historical_addresses: "",
        associated_people: "",
      },
      error: "",
      warnings: "",
    },
    "Location Intel 3.0": {
      id: "",
      is_valid: "",
      street_line_1: "",
      street_line_2: "",
      city: "",
      postal_code: "",
      zip4: "",
      state_code: "",
      country_code: "",
    },
  };

  static readonly API_PRODUCT_RESPONSE_KEYS_DETAILS = {
    "Address Validation API": [
      {
        heading: "id",
        subheading:
          "Trestle's persistent ID of the phone number/address/person returned.",
      },
      {
        heading: "is_valid",
        subheading:
          "Boolean indicator if the address is valid and exists in the real world.",
      },
      {
        heading: "normalized_address",
        subheading:
          "Normalized and standardized address including street line, unit number (if applicable), city, postal, state, and country code.",
      },
      {
        heading: "lat_long",
        subheading: "The coordinates of the normalized and validated address.",
      },
      {
        heading: "is_commercial",
        subheading:
          "Boolean indicator if the address is associated with a business.",
      },
      {
        heading: "delivery_point",
        subheading:
          "The delivery point of the address. This includes single unit, multi-unit, PO Box, etc.",
      },
      {
        heading: "current_residents",
        subheading:
          "The list of current people and/or businesses at the address.",
      },
      {
        heading: "demographics",
        subheading: "Age range and gender of the resident at the address.",
      },
      {
        heading: "industry",
        subheading:
          "If the address is registered to the business, this is the industry of the business (e.g., retail, automotive, etc.).",
      },
      {
        heading: "link_to_address_start_date",
        subheading:
          "The date of when the resident has been associated with the address.",
      },
      {
        heading: "phones",
        subheading:
          "A list of phone numbers associated with the resident along with their line type.",
      },
      {
        heading: "emails",
        subheading: "The list of emails associated with the current resident.",
      },
      {
        heading: "historical_addresses",
        subheading:
          "A list of historical addresses associated with the resident, including full address, postal code, coordinates, and the dates of when the subscriber was associated with the address.",
      },
      {
        heading: "associated_people",
        subheading:
          "Associated people living in the same household, as well as relatives of the resident.",
      },
    ],
    "Reverse Phone API": [
      {
        heading: "id",
        subheading:
          "Trestle's persistent ID of the phone number/address/person returned.",
      },
      {
        heading: "phone_number",
        subheading:
          "The phone number normalized and formatted with a country code.",
      },
      {
        heading: "is_valid",
        subheading:
          "Boolean indicator if the phone is a valid number and assigned to a carrier.",
      },
      {
        heading: "country_calling_code",
        subheading: "The country code for the phone number.",
      },
      {
        heading: "line_type",
        subheading:
          "Line type indicating if the phone is landline, mobile, fixed or non-fixedVoIP (like Twilio or Google Voice), toll-free, etc.",
      },
      {
        heading: "carrier",
        subheading:
          "The MVNO level carrier provides the service to the subscriber.",
      },
      {
        heading: "is_prepaid",
        subheading:
          "Boolean indicator if the phone number is associated with a prepaid account.",
      },
      {
        heading: "is_commercial",
        subheading:
          "Boolean indicator if the phone number is registered to a business.",
      },
      {
        heading: "owners",
        subheading:
          "The owner(s) associated with the phone. For each owner, we provide details, including first, middle, and last names, alternate names, age and gender, person or business type, and phone link date.",
      },
      {
        heading: "current_addresses",
        subheading:
          "Current address of the subscriber, including full address, postal code, coordinates, and the date when the subscriber was associated with the address.",
      },
      {
        heading: "emails",
        subheading:
          "The list of emails associated with the owner of the phone.",
      },
      {
        heading: "alternate_phones",
        subheading:
          "A list of additional phone numbers associated with the subscriber along with their line type.",
      },
    ],
    "Reverse Address API": [
      {
        heading: "id",
        subheading:
          "Trestle's persistent ID of the phone number/address/person returned.",
      },
      {
        heading: "is_valid",
        subheading:
          "Boolean indicator if the address is valid and exists in the real world.",
      },
      {
        heading: "normalized_address",
        subheading:
          "Normalized and standardized address including street line, unit number (if applicable), city, postal, state, and country code.",
      },
      {
        heading: "lat_long",
        subheading: "The coordinates of the normalized and validated address.",
      },
      {
        heading: "is_commercial",
        subheading:
          "Boolean indicator if the address is associated with a business.",
      },
      {
        heading: "delivery_point",
        subheading:
          "The delivery point of the address. This includes single unit, multi-unit, PO Box, etc.",
      },
      {
        heading: "current_residents",
        subheading:
          "The list of current people and/or businesses at the address.",
      },
      {
        heading: "demographics",
        subheading: "Age range and gender of the resident at the address.",
      },
      {
        heading: "industry",
        subheading:
          "If the address is registered to the business, this is the industry of the business (e.g., retail, automotive, etc.).",
      },
      {
        heading: "link_to_address_start_date",
        subheading:
          "The date of when the resident has been associated with the address.",
      },
      {
        heading: "phones",
        subheading:
          "A list of phone numbers associated with the resident along with their line type.",
      },
      {
        heading: "emails",
        subheading: "The list of emails associated with the current resident.",
      },
      {
        heading: "historical_addresses",
        subheading:
          "A list of historical addresses associated with the resident, including full address, postal code, coordinates, and the dates of when the subscriber was associated with the address.",
      },
      {
        heading: "associated_people",
        subheading:
          "Associated people living in the same household, as well as relatives of the resident.",
      },
    ],
    "Caller Identification API": [
      {
        heading: "id",
        subheading:
          "Trestle’s persistent ID of the phone number/address/person returned.",
      },
      {
        heading: "phone_number",
        subheading:
          "The phone number normalized and formatted with a country code.",
      },
      {
        heading: "is_valid",
        subheading:
          "Boolean indicator if the phone is a valid number and assigned to a carrier.",
      },
      {
        heading: "country_calling_code",
        subheading: "The country code for the phone number.",
      },
      {
        heading: "line_type",
        subheading:
          "Line type indicating if the phone is landline, mobile, fixed or non-fixed VoIP (like Twilio or Google Voice), toll-free, etc.",
      },
      {
        heading: "carrier",
        subheading:
          "The MVNO level carrier provides the service to the subscriber.",
      },
      {
        heading: "is_prepaid",
        subheading:
          "Boolean indicator if the phone number is associated with a prepaid account.",
      },
      {
        heading: "is_commercial",
        subheading:
          "Boolean indicator if the address is associated with a business.",
      },
      {
        heading: "belongs_to",
        subheading: "The primary owner of the phone.",
      },
      {
        heading: "age_range",
        subheading: "Age range of the phone subscriber.",
      },
      {
        heading: "gender",
        subheading: "Gender of the phone subscriber.",
      },
      {
        heading: "industry",
        subheading:
          "If the phone is registered to the business, this is the industry of the business (e.g., retail, automotive, etc.).",
      },
      {
        heading: "link_to_phone_start_date",
        subheading:
          "The first date in which the phone was linked to the subscriber.",
      },
      {
        heading: "current_address",
        subheading:
          "Current address of the subscriber, including full address, postal code, coordinates, and the date of when the subscriber was associated with the address.",
      },
      {
        heading: "emails",
        subheading:
          "The list of emails associated with the owner of the phone.",
      },
    ],
    "Find Person API": [
      {
        heading: "count_person",
        subheading:
          "Count of persons that matched the query. Only up to 100 person details are provided as part of the API response.",
      },
      {
        heading: "id",
        subheading:
          "Trestle's persistent ID of the phone number/address/person returned.",
      },
      {
        heading: "name",
        subheading:
          "Full name of the person found including phonetic matched persons.",
      },
      {
        heading: "demographics",
        subheading: "Age range and gender of the person returned.",
      },
      {
        heading: "found_at_address",
        subheading:
          "The address at which the person was found. This is especially needed if the search is at a city/state or state level.",
      },
      {
        heading: "current_addresses",
        subheading:
          "Current addresses of the subscriber, including full address, postal code, coordinates, and the date of when the subscriber was associated with the address.",
      },
      {
        heading: "phone",
        subheading:
          "A list of phone numbers associated with the person along with their line type.",
      },
      {
        heading: "emails",
        subheading: "The list of emails associated with the person.",
      },
      {
        heading: "historical_addresses",
        subheading:
          "A list of historical addresses associated with the person, including full address, postal code, coordinates, and the dates when the subscriber was associated with the address.",
      },
      {
        heading: "associated_people",
        subheading:
          "Associated people living in the same household, as well as relatives to the person returned.",
      },
    ],
    "Phone Validation API": [
      {
        heading: "id",
        subheading:
          "Trestle's persistent ID of the phone number/address/person returned.",
      },
      {
        heading: "phone_number",
        subheading:
          "The phone number is normalized and formatted with a country code.",
      },
      {
        heading: "is_valid",
        subheading:
          "Boolean indicator if the phone is a valid number and assigned to a carrier.",
      },
      {
        heading: "activity_score",
        subheading:
          "A 0-100 score indicating whether a phone is connected and in active use or whether it is disconnected and has not seen any activity.",
      },
      {
        heading: "country_calling_code",
        subheading: "The country code for the phone number.",
      },
      {
        heading: "country_code",
        subheading: "The ISO-3166 alpha-2 country code of the phone number.",
      },
      {
        heading: "country_name",
        subheading:
          "The country name of the location associated with the phone number.",
      },
      {
        heading: "line_type",
        subheading:
          "Line type indicating if the phone is landline, mobile, fixed or non-fixed VoIP (like a Twilio or Google Voice), toll-free, etc.",
      },
      {
        heading: "carrier",
        subheading:
          "The MVNO level carrier that provides the service to the subscriber.",
      },
      {
        heading: "is_prepaid",
        subheading:
          "Boolean indicator if the phone number is associated with a prepaid account.",
      },
    ],
    "Real Contact API": [
      {
        heading: "phone_is_valid",
        subheading:
          "Boolean indicator if the phone is a valid number and assigned to a carrier.",
      },
      {
        heading: "phone_activity_score",
        subheading:
          "A 0-100 score indicating whether a phone is connected and in active use or whether it is disconnected and has not seen any activity.",
      },
      {
        heading: "phone_line_type",
        subheading:
          "Line type indicating if the phone is landline, mobile, fixed or non-fixed VoIP (like a Twilio or Google Voice), toll-free, etc.",
      },
      {
        heading: "phone_to_name_match",
        subheading:
          "A match/no match indicator whether the name provided on the lead form matches to the input phone number according to our database. A match is a positive indicator that verifies the lead is real and is who they say they are.",
      },
      {
        heading: "phone_contact_grade",
        subheading:
          "An A-F grade determining the quality of the lead and whether it is contactable. A Grade-F lead is a bad lead and should be deprioritized, while a Grade-A lead is real and contactable and should be prioritized.",
      },
      {
        heading: "address_is_valid",
        subheading: "Boolean indicator if the address is valid.",
      },
      {
        heading: "address_to_name_match",
        subheading:
          "A match/no match indicator whether the name provided on the lead form matches to the input address according to our database. A match is a positive indicator that verifies the lead is real and is who they say they are.",
      },
      {
        heading: "email_is_valid",
        subheading: "Boolean indicator if the email is valid.",
      },
      {
        heading: "email_to_name_match",
        subheading:
          "A match/no match indicator whether the name provided on the lead form matches to the input email address according to our database. A match is a positive indicator that verifies the lead is real and is who they say they are.",
      },
      {
        heading: "email_contact_grade",
        subheading:
          "An A-F grade determining the quality of the lead and whether it is contactable. Grade-A is a valid email with name match, while Grade-F is an invalid or temporary email.",
      },
    ],
    "Smart CNAM API": [
      {
        heading: "id",
        subheading:
          "Trestle's persistent ID of the phone number/person returned.",
      },
      {
        heading: "phone_number",
        subheading:
          "The phone number is normalized and formatted with a country code.",
      },
      {
        heading: "is_valid",
        subheading: "Boolean indicator if the phone is a valid number.",
      },
      {
        heading: "belong_to",
        subheading:
          "The primary owner name of the phone. This can be a person name (parsed into first, middle, and last) or a business name.",
      },
    ],
  };

  static readonly API_PRODUCT_REQUEST_URLS: any = {
    "Reverse Phone 3.0":
      "https://api.trestleiq.com/3.0/phone?phone=[insert_phone_number]",
    "Reverse Phone 3.1":
      "https://api.trestleiq.com/3.1/phone?phone=[insert_phone_number]",
    "Reverse Phone 3.2":
      "https://api.trestleiq.com/3.2/phone?phone=[insert_phone_number]",
    "Caller Identification 3.0":
      "https://api.trestleiq.com/3.0/caller_id?phone=[insert_phone_number]",
    "Caller Identification 3.1":
      "https://api.trestleiq.com/3.1/caller_id?phone=[insert_phone_number]",
    "Smart CNAM 3.1":
      "https://api.trestleiq.com/3.1/cnam?phone=[insert_phone_number]",
    "Reverse Address 3.0":
      "https://api.trestleiq.com/3.0/location?street_line_1=[insert_street_line_1]&city=[insert_city]&postal_code=[insert_postal_code]&state_code=[insert_state_code]",
    "Reverse Address 3.1":
      "https://api.trestleiq.com/3.1/location?street_line_1=[insert_street_line_1]&city=[insert_city]&postal_code=[insert_postal_code]&state_code=[insert_state_code]",
    "Find Person 3.0":
      "https://api.trestleiq.com/3.0/person?name=[insert_name]&address.city=[insert_city]&address.state_code=[insert_state_code]&street_line_1=[insert_street_line_1]&address.postal_code=[insert_address_postal_code]",
    "Find Person 3.1":
      "https://api.trestleiq.com/3.1/person?name=[insert_name]&address.city=[insert_city]&address.state_code=[insert_state_code&street_line_1=[insert_street_line_1]&address.postal_code=[insert_address_postal_code]",
    "Real Contact 1.0":
      "https://api.trestleiq.com/1.0/real_contact?name=[insert_name]&phone=[insert_phone]&email=[insert_email]&ip_address=[insert_ip_address]",
    "Real Contact 1.1":
      "https://api.trestleiq.com/1.1/real_contact?name=[insert_name]&phone=[insert_phone]&email=[insert_email]&ip_address=[insert_ip_address]",
    "Phone Intel 3.0":
      "https://api.trestleiq.com/3.0/phone_intel?phone=[insert_phone_number]",
    "Location Intel 3.0":
      "https://api.trestleiq.com/3.0/location_intel?street_line_1=[insert_street_line_1]&city=[insert_city]&postal_code=[insert_postal_code]&state_code=[insert_state_code]",
    "Phone Data 1.0":
      "https://api.trestleiq.com/1.0/phone_data?phone=[insert_phone_number]",
  };
}
